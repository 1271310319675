import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getUserPermissions } from "../functions/AuthQueries";

const Reporting = () => {
	const [userPermissions, setUserPermissions] = useState([])
	useEffect(() => {
		const user_id = JSON.parse(localStorage.getItem("user_id"));
		getUserPermissions(user_id, setUserPermissions)
	}, [])

	return (
		<div className="h-screen py-3 bg-gray-100">
			<div className="p-3 bg-gray-100">
				<div className="flex flex-col px-8">
					<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
								<div className="py-2 text-2xl text-center">Reportes</div>
								<div className="flex flex-row justify-around m-6">
									{/* TODO: Ver como separar permisos de usuario / role */}

									{userPermissions.includes(121) ? (
										<Link to="/ReporteLider">
											<button className="px-3 py-1 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-xl text-light">
												Reporte de Líder
											</button>
										</Link>
									) : null}

									{userPermissions.includes(122) ? (
										<Link to="/ReporteDirectivo">
											<button className="px-3 py-1 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-xl text-light">
												Reporte Directivo
											</button>
										</Link>
									) : null}

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Reporting;
