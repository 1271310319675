import React from "react";

const Home = () => {
	return (
		<div className="p-3 bg-gray-100">
			<h3 className="pb-2 text-sm font-bold text-center sm:text-2xl">WHY</h3>
			<div className="flex flex-row justify-center">
				<img
					src={require("../assets/images/001_Brief.jpg")}
					alt="prisma"
					className="w-11/12 px-4"
				/>
			</div>
		</div>
	);
};

export default Home;
