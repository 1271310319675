import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AllUserClients } from "../functions/ClientsQueries";

const Clients = () => {
	const [clientList, setClientList] = useState([]);
	const user_id = JSON.parse(localStorage.getItem("user_id"));

	const { response, loading, error } = AllUserClients(user_id);

	useEffect(() => {
		response && setClientList(response);
	}, [response, loading, error]);

	return (
		<div className="p-3 bg-gray-100">
			<div className="flex flex-col px-8">
				<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
						<div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
							<div className="pt-2 text-2xl text-center">Clientes</div>
							<div>
								<div className="flex flex-row justify-around py-3">
									<Link to="/client">
										<button className="px-3 py-1 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-xl text-light">
											Nuevo cliente
										</button>
									</Link>
								</div>

								{loading && <div className="flex flex-row justify-around m-3">Cargando, espere por favor</div>}
								{!loading && error && <div className="flex flex-row justify-around m-3">Ha ocurrido un error</div>}
								<table className="min-w-full divide-y divide-gray-200">
									<thead className="bg-gray-50">
										<tr>
											<th
												scope="col"
												className="px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase"
											>
												Nombre
											</th>
											<th
												scope="col"
												className="hidden px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase md:table-cell"
											>
												Teléfono
											</th>
											<th
												scope="col"
												className="hidden px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase md:table-cell"
											>
												Correo electrónico
											</th>
											<th
												scope="col"
												className="px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase"
											>
												Detalles
											</th>
										</tr>
									</thead>
									<tbody>
										{typeof clientList === "undefined" ||
											clientList === null ||
											clientList.length === 0 ? null : (
											<>
												{clientList.map((item) => {
													return (
														<tr
															key={item.id}
															className={
																item.id % 2 === 0
																	? "bg-white px-6 py-3 text-s font-medium"
																	: "bg-gray-50 px-6 py-3 text-s font-medium"
															}
														>
															<td className="text-center text-gray-600">
																{item.nombre} {item.apellidos}
															</td>
															<td className="hidden text-center text-gray-600 md:table-cell">
																{item.celular}
															</td>
															<td className="hidden text-center text-gray-600 md:table-cell">
																{item.email}
															</td>
															<td className="text-center text-gray-600">
																<Link to={`/client/${item.id}`}>
																	<button className="px-4 py-1 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-2xl text-light">
																		Detalles
																	</button>
																</Link>
															</td>
														</tr>
													);
												})}
											</>
										)}
									</tbody>
								</table>
								<div className="flex flex-row justify-around m-6">
									<Link to="/client">
										<button className="px-3 py-1 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-xl text-light">
											Nuevo cliente
										</button>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<br />
			</div>
		</div>
	);
};

export default Clients;
