import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import _ from "lodash";
import CurrencyInput from "react-currency-input-field";

import {
	obtenerDatosCliente,
	actualizarDatosCliente,
	obtenerApoyoPadres,
	actualizarDatosApoyoPadres,
	obtenerSumaApoyosMensuales,
	arregloPadresFindOrCreate,
} from "../../functions/NecesidadesQueries";

const ApoyoPadres = ({ cliente_id }) => {
	const [datosCliente, setDatosCliente] = useState("");
	const [apoyoPadres, setApoyoPadres] = useState([]);
	const [sumaApoyos, setSumaApoyos] = useState(0);
	let i = 0;
	let sumasMensuales = 0;

	const actualizarState = (e) => {
		let arr = e.target.name.split(" || ");
		let val;
		val =
			arr[1] === "apoyo_mensual"
				? e.target.value.replace(/\D+/g, "")
				: e.target.value;
		actualizarDatosApoyoPadres(arr[0], arr[1], val);
		setTimeout(() => {
			obtenerApoyoPadres(cliente_id, setApoyoPadres);
			// obtenerSumaApoyosMensuales(cliente_id, setSumaApoyos);
		}, 500);
	};

	useEffect(() => {
		arregloPadresFindOrCreate(cliente_id, console.log);
		setTimeout(() => {
			obtenerDatosCliente(cliente_id, setDatosCliente);
			obtenerApoyoPadres(cliente_id, setApoyoPadres);
		}, 500);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Anualización y suma de todos los apoyos a padres
	useEffect(() => {
		apoyoPadres.map((item) => {
			sumasMensuales = sumasMensuales + item.apoyo_mensual;
		});
		setSumaApoyos(sumasMensuales);
	}, [apoyoPadres]);

	return (
		<div>
			<div className="flex flex-row justify-around mb-10">
				<div className="space-y-2 sm:space-y-4">
					<div className="text-sm font-bold text-center sm:text-2xl">
						Apoyo a Padres
					</div>

					<div className="flex flex-col">
						<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
								<div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
									<form>
										<table className="min-w-full divide-y divide-gray-200">
											<thead className="bg-gray-50">
												<tr
													key={i}
													className={i++ % 2 === 0 ? "bg-white" : "bg-gray-100"}
												>
													<th
														scope="col"
														className="px-6 py-3 text-sm font-medium tracking-wider text-center uppercase text-slate-800"
													>
														Relación
													</th>
													<th
														scope="col"
														className="px-6 py-3 text-sm font-medium tracking-wider text-center uppercase text-slate-800"
													>
														Nombre
													</th>
													{/* <th
                            scope="col"
                            className="px-6 py-3 text-sm font-medium tracking-wider text-center uppercase text-slate-800"
                          >
                            Apellido
                          </th> */}
													<th
														scope="col"
														className="px-6 py-3 text-sm font-medium tracking-wider text-center uppercase text-slate-800"
													>
														Edad
													</th>
													<th
														scope="col"
														className="px-6 py-3 text-sm font-medium tracking-wider text-center uppercase text-slate-800"
													>
														Apoyo mensual
													</th>
													<th
														scope="col"
														className="px-6 py-3 text-sm font-medium tracking-wider text-center uppercase text-slate-800"
													>
														Apoyo anual
													</th>
													<th
														scope="col"
														className="px-6 py-3 text-sm font-medium tracking-wider text-center uppercase text-slate-800"
													>
														Esperanza
														<br />
														vida
													</th>
													<th
														scope="col"
														className="px-6 py-3 text-sm font-medium tracking-wider text-center uppercase text-slate-800"
													>
														Número de años
													</th>
												</tr>
											</thead>
											<tbody className="bg-white">
												{typeof apoyoPadres === "undefined" ||
												apoyoPadres === null ||
												apoyoPadres.length === 0
													? null
													: apoyoPadres.map((item, index) => {
															return (
																<tr
																	key={i}
																	className={
																		i++ % 2 === 0 ? "bg-white" : "bg-gray-100"
																	}
																>
																	<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																		{item.relacion}
																	</td>
																	<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																		<input
																			type="text"
																			name={`${item.id} || nombre`}
																			id="item.subtipo"
																			defaultValue={item.nombre}
																			onChange={actualizarState}
																			className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																			required
																		/>
																	</td>
																	<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																		<select
																			name={`${item.id} || edad`}
																			className="block w-full mx-1 mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																			onChange={actualizarState}
																			required
																		>
																			{_.range(50, 100).map((arr, index) => {
																				return item.edad === arr ? (
																					<option
																						key={index}
																						value={arr}
																						selected
																					>
																						{arr}
																					</option>
																				) : (
																					<option key={index} value={arr}>
																						{arr}
																					</option>
																				);
																			})}
																		</select>
																	</td>
																	<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																		<CurrencyInput
																			id="item.subtipo"
																			name={`${item.id} || apoyo_mensual`}
																			defaultValue={item.apoyo_mensual}
																			className="block w-full mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																			onChange={actualizarState}
																			decimalsLimit={2}
																			required
																		/>
																	</td>
																	<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																		<NumberFormat
																			thousandSeparator={true}
																			displayType={"text"}
																			prefix={"$ "}
																			value={item.apoyo_mensual * 12}
																		/>
																	</td>
																	<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																		<select
																			name={`${item.id} || esperanza_vida`}
																			className="block w-full mx-1 mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																			onChange={actualizarState}
																			required
																		>
																			{_.range(50, 100).map((arr, index) => {
																				return item.esperanza_vida === arr ? (
																					<option
																						key={index}
																						value={arr}
																						selected
																					>
																						{arr}
																					</option>
																				) : (
																					<option key={index} value={arr}>
																						{arr}
																					</option>
																				);
																			})}
																		</select>
																	</td>
																	<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																		{item.apoyo_mensual > 0
																			? item.esperanza_vida - item.edad
																			: null}
																	</td>
																</tr>
															);
													  })}
												<tr
													key={i}
													className={i++ % 2 === 0 ? "bg-white" : "bg-gray-100"}
												>
													<td className="px-6 py-2 font-medium text-center text-gray-900 whitespace-nowrap">
														TOTAL
													</td>
													<td colSpan={2}></td>
													<td className="px-6 py-2 font-medium text-center text-gray-900 whitespace-nowrap">
														<NumberFormat
															thousandSeparator={true}
															displayType={"text"}
															prefix={"$ "}
															// value={sumaApoyos}
															value={sumaApoyos}
														/>
													</td>
													<td className="px-6 py-2 font-medium text-center text-gray-900 whitespace-nowrap">
														<NumberFormat
															thousandSeparator={true}
															displayType={"text"}
															prefix={"$ "}
															value={sumaApoyos * 12}
														/>
													</td>
													<td
														className="px-6 py-2 font-medium text-center text-gray-900 whitespace-nowrap"
														colSpan={2}
													></td>
												</tr>
											</tbody>
										</table>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<br />
			<br />
			<br />
			<br />
		</div>
	);
};

export default ApoyoPadres;
