import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import _ from "lodash";

import {
	arregloPadresFindOrCreate,
	obtenerDatosDependientes,
	actualizarDatosClienteDependiente,
	obtenerListadoGradosEscolares,
	obtenerGradosEscolaresPrecios,
} from "../../functions/NecesidadesQueries";
import {
	calculate_age,
	obtenerTiposUniversidades,
	maximoMontoUniversidadDependientes,
} from "../../functions/DependantsQueries";

const Educacion = ({ cliente_id }) => {
	const [datosHijos, setDatosHijos] = useState([]);
	const [listaAnosEscolares, setListaAnosEscolares] = useState([]);
	const [listaGradosEscolaresPrecios, setListaGradosEscolaresPrecios] =
		useState([]);
	const [tiposUniversidades, setTiposUniversidades] = useState();
	const [maximoMontoUniversidad, setMaximoMontoUniversidad] = useState();

	const actualizarState = (e) => {
		let arr = e.target.name.split(" || ");
		actualizarDatosClienteDependiente(arr[0], arr[1], e.target.value);
		if (arr[1] === "tipo_universidad") {
			actualizarDatosClienteDependiente(
				arr[0],
				"costo_universidad",
				tiposUniversidades[e.target.value - 1].costo_mensual
			);
		}
		setTimeout(() => {
			obtenerDatosDependientes(cliente_id, "Hijo", setDatosHijos);
			maximoMontoUniversidadDependientes(cliente_id, setMaximoMontoUniversidad);
		}, 500);
	};

	let i = 0;

	useEffect(() => {
		arregloPadresFindOrCreate(cliente_id, console.log);
		obtenerDatosDependientes(cliente_id, "Hijo", setDatosHijos);
		obtenerListadoGradosEscolares(setListaAnosEscolares);
		obtenerGradosEscolaresPrecios(setListaGradosEscolaresPrecios);
		obtenerTiposUniversidades(setTiposUniversidades);
		maximoMontoUniversidadDependientes(cliente_id, setMaximoMontoUniversidad);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let arregloGrados = [];
	listaAnosEscolares.forEach((element) => {
		arregloGrados[element.id] = element.grado;
	});

	let totalAnosPreescolar = 0;
	let totalAnosPrimaria = 0;
	let totalAnosSecundaria = 0;
	let totalAnosPreparatoria = 0;
	let totalAnosUniversidad = 0;

	let anosHijo0 = 0;
	let anosHijo1 = 0;
	let anosHijo2 = 0;
	let anosHijo3 = 0;

	let granTotal = 0;

	let costoTotalPreescolar = 0;
	let costoTotalPrimaria = 0;
	let costoTotalSecundaria = 0;
	let costoTotalPreparatoria = 0;
	let costoTotalUniversidad = 0;

	if (datosHijos[0] !== undefined) {
		for (let i = 0; i < listaAnosEscolares.length; i++) {
			const element = listaAnosEscolares[i];
			if (element.id === datosHijos[0].ano_escolar_actual) {
				totalAnosPreescolar += element.anos_faltantes_preescolar;
				totalAnosPrimaria += element.anos_faltantes_primaria;
				totalAnosSecundaria += element.anos_faltantes_secundaria;
				totalAnosPreparatoria += element.anos_faltantes_preparatoria;
				anosHijo0 =
					element.anos_faltantes_preescolar +
					element.anos_faltantes_primaria +
					element.anos_faltantes_secundaria +
					element.anos_faltantes_preparatoria;
			}
		}
		totalAnosUniversidad += datosHijos[0].anos_faltantes_universidad;
		anosHijo0 += datosHijos[0].anos_faltantes_universidad;
	}
	if (datosHijos[1] !== undefined) {
		for (let i = 0; i < listaAnosEscolares.length; i++) {
			const element = listaAnosEscolares[i];
			if (element.id === datosHijos[1].ano_escolar_actual) {
				totalAnosPreescolar += element.anos_faltantes_preescolar;
				totalAnosPrimaria += element.anos_faltantes_primaria;
				totalAnosSecundaria += element.anos_faltantes_secundaria;
				totalAnosPreparatoria += element.anos_faltantes_preparatoria;
				anosHijo1 =
					element.anos_faltantes_preescolar +
					element.anos_faltantes_primaria +
					element.anos_faltantes_secundaria +
					element.anos_faltantes_preparatoria;
			}
		}
		totalAnosUniversidad += datosHijos[1].anos_faltantes_universidad;
		anosHijo1 += datosHijos[1].anos_faltantes_universidad;
	}
	if (datosHijos[2] !== undefined) {
		for (let i = 0; i < listaAnosEscolares.length; i++) {
			const element = listaAnosEscolares[i];
			if (element.id === datosHijos[2].ano_escolar_actual) {
				totalAnosPreescolar += element.anos_faltantes_preescolar;
				totalAnosPrimaria += element.anos_faltantes_primaria;
				totalAnosSecundaria += element.anos_faltantes_secundaria;
				totalAnosPreparatoria += element.anos_faltantes_preparatoria;
				anosHijo2 =
					element.anos_faltantes_preescolar +
					element.anos_faltantes_primaria +
					element.anos_faltantes_secundaria +
					element.anos_faltantes_preparatoria;
			}
		}
		totalAnosUniversidad += datosHijos[2].anos_faltantes_universidad;
		anosHijo2 += datosHijos[2].anos_faltantes_universidad;
	}
	if (datosHijos[3] !== undefined) {
		for (let i = 0; i < listaAnosEscolares.length; i++) {
			const element = listaAnosEscolares[i];
			if (element.id === datosHijos[3].ano_escolar_actual) {
				totalAnosPreescolar += element.anos_faltantes_preescolar;
				totalAnosPrimaria += element.anos_faltantes_primaria;
				totalAnosSecundaria += element.anos_faltantes_secundaria;
				totalAnosPreparatoria += element.anos_faltantes_preparatoria;
				anosHijo3 =
					element.anos_faltantes_preescolar +
					element.anos_faltantes_primaria +
					element.anos_faltantes_secundaria +
					element.anos_faltantes_preparatoria;
			}
		}
		totalAnosUniversidad += datosHijos[3].anos_faltantes_universidad;
		anosHijo3 += datosHijos[3].anos_faltantes_universidad;
	}

	let totalAnosHijos = anosHijo0 + anosHijo1 + anosHijo2 + anosHijo3;

	costoTotalPreescolar =
		datosHijos[0]?.costo_preescolar * 12 * totalAnosPreescolar;
	costoTotalPrimaria = datosHijos[0]?.costo_primaria * 12 * totalAnosPrimaria;
	costoTotalSecundaria =
		datosHijos[0]?.costo_secundaria * 12 * totalAnosSecundaria;
	costoTotalPreparatoria =
		datosHijos[0]?.costo_preparatoria * 12 * totalAnosPreparatoria;
	costoTotalUniversidad = maximoMontoUniversidad * 12 * totalAnosUniversidad;

	granTotal =
		costoTotalPreescolar +
		costoTotalPrimaria +
		costoTotalSecundaria +
		costoTotalPreparatoria +
		costoTotalUniversidad;

	return (
		<div>
			<div className="flex flex-row justify-around mb-10">
				<div className="space-y-2 sm:space-y-4">
					<div className="text-sm font-bold text-center sm:text-2xl">
						Educación
					</div>

					<div className="flex flex-col">
						<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
								<div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
									<form>
										<table className="divide-y divide-gray-200 min-w-min">
											<thead className="bg-gray-50">
												<tr
													key={i}
													className={i++ % 2 === 0 ? "bg-white" : "bg-gray-100"}
												>
													<th
														scope="col"
														className="px-6 py-3 text-sm font-medium tracking-wider text-center text-gray-700 uppercase"
													>
														Concepto
													</th>
													{datosHijos.length
														? _.range(1, datosHijos.length + 1).map((i) => {
															return (
																<th
																	scope="col"
																	className="px-6 py-3 text-sm font-medium tracking-wider text-center text-gray-700 uppercase"
																>
																	Hijo
																	<br />
																	{i}
																</th>
															);
														})
														: null}
												</tr>
											</thead>
											<tbody>
												<tr
													key={i}
													className={i++ % 2 === 0 ? "bg-white" : "bg-gray-100"}
												>
													<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
														Nombre
													</td>
													{datosHijos[0] ? (
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{datosHijos[0]?.nombre}
														</td>
													) : null}
													{datosHijos[1] ? (
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{datosHijos[1]?.nombre}
														</td>
													) : null}
													{datosHijos[2] ? (
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{datosHijos[2]?.nombre}
														</td>
													) : null}
													{datosHijos[3] ? (
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{datosHijos[3]?.nombre}
														</td>
													) : null}
												</tr>
												<tr
													key={i}
													className={i++ % 2 === 0 ? "bg-white" : "bg-gray-100"}
												>
													<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
														Edad
													</td>
													{datosHijos[0] ? (
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{datosHijos[0] !== undefined
																? calculate_age(datosHijos[0]?.fecha_nacimiento)
																: null}
														</td>
													) : null}
													{datosHijos[1] ? (
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{datosHijos[1] !== undefined
																? calculate_age(datosHijos[1]?.fecha_nacimiento)
																: null}
														</td>
													) : null}
													{datosHijos[2] ? (
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{datosHijos[2] !== undefined
																? calculate_age(datosHijos[2]?.fecha_nacimiento)
																: null}
														</td>
													) : null}
													{datosHijos[3] ? (
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{datosHijos[3] !== undefined
																? calculate_age(datosHijos[3]?.fecha_nacimiento)
																: null}
														</td>
													) : null}
												</tr>
												<tr
													key={i}
													className={i++ % 2 === 0 ? "bg-white" : "bg-gray-100"}
												>
													<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
														Año escolar actual
													</td>

													{datosHijos[0] !== undefined ? (
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															<>
																<select
																	name={`${datosHijos[0].id} || ano_escolar_actual`}
																	className="block w-full mx-1 mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																	onChange={actualizarState}
																	required
																>
																	{arregloGrados.map((item, index) => {
																		return datosHijos[0].ano_escolar_actual ===
																			index ? (
																			<option
																				key={index}
																				value={index}
																				selected
																			>
																				{item}
																			</option>
																		) : (
																			<option key={index} value={index}>
																				{item}
																			</option>
																		);
																	})}
																</select>
															</>
														</td>
													) : null}
													{datosHijos[1] !== undefined ? (
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															<>
																<select
																	name={`${datosHijos[1].id} || ano_escolar_actual`}
																	className="block w-full mx-1 mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																	onChange={actualizarState}
																	required
																>
																	{arregloGrados.map((item, index) => {
																		return datosHijos[1].ano_escolar_actual ===
																			index ? (
																			<option
																				key={index}
																				value={index}
																				selected
																			>
																				{item}
																			</option>
																		) : (
																			<option key={index} value={index}>
																				{item}
																			</option>
																		);
																	})}
																</select>
															</>
														</td>
													) : null}
													{datosHijos[2] !== undefined ? (
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															<>
																<select
																	name={`${datosHijos[2].id} || ano_escolar_actual`}
																	className="block w-full mx-1 mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																	onChange={actualizarState}
																	required
																>
																	{arregloGrados.map((item, index) => {
																		return datosHijos[2].ano_escolar_actual ===
																			index ? (
																			<option
																				key={index}
																				value={index}
																				selected
																			>
																				{item}
																			</option>
																		) : (
																			<option key={index} value={index}>
																				{item}
																			</option>
																		);
																	})}
																</select>
															</>
														</td>
													) : null}
													{datosHijos[3] !== undefined ? (
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															<>
																<select
																	name={`${datosHijos[3].id} || ano_escolar_actual`}
																	className="block w-full mx-1 mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																	onChange={actualizarState}
																	required
																>
																	{arregloGrados.map((item, index) => {
																		return datosHijos[3].ano_escolar_actual ===
																			index ? (
																			<option
																				key={index}
																				value={index}
																				selected
																			>
																				{item}
																			</option>
																		) : (
																			<option key={index} value={index}>
																				{item}
																			</option>
																		);
																	})}
																</select>
															</>
														</td>
													) : null}
												</tr>
												<tr
													key={i}
													className={i++ % 2 === 0 ? "bg-white" : "bg-gray-100"}
												>
													<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
														Tipo de universidad
													</td>

													{datosHijos[0] !== undefined ? (
														<td className="justify-center px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{datosHijos[0] !== undefined ? (
																<>
																	<select
																		name={`${datosHijos[0]?.id} || tipo_universidad`}
																		className="block w-full mx-1 mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																		onChange={actualizarState}
																		required
																	>
																		{datosHijos[0]?.tipo_universidad === 1 ? (
																			<option key="1" value="1" selected>
																				Oficial
																			</option>
																		) : (
																			<option key="1" value="1">
																				Oficial
																			</option>
																		)}
																		{datosHijos[0]?.tipo_universidad === 2 ? (
																			<option key="2" value="2" selected>
																				Privada 1
																			</option>
																		) : (
																			<option key="2" value="2">
																				Privada 1
																			</option>
																		)}
																		{datosHijos[0]?.tipo_universidad === 3 ? (
																			<option key="3" value="3" selected>
																				Privada 2
																			</option>
																		) : (
																			<option key="3" value="3">
																				Privada 2
																			</option>
																		)}
																	</select>
																</>
															) : null}
														</td>
													) : null}
													{datosHijos[1] !== undefined ? (
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{datosHijos[1] !== undefined ? (
																<>
																	<select
																		name={`${datosHijos[1]?.id} || tipo_universidad`}
																		className="block w-full mx-1 mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																		onChange={actualizarState}
																		required
																	>
																		{datosHijos[1]?.tipo_universidad === 1 ? (
																			<option key="1" value="1" selected>
																				Oficial
																			</option>
																		) : (
																			<option key="1" value="1">
																				Oficial
																			</option>
																		)}
																		{datosHijos[1]?.tipo_universidad === 2 ? (
																			<option key="2" value="2" selected>
																				Privada 1
																			</option>
																		) : (
																			<option key="2" value="2">
																				Privada 1
																			</option>
																		)}
																		{datosHijos[1]?.tipo_universidad === 3 ? (
																			<option key="3" value="3" selected>
																				Privada 2
																			</option>
																		) : (
																			<option key="3" value="3">
																				Privada 2
																			</option>
																		)}
																	</select>
																</>
															) : null}
														</td>
													) : null}
													{datosHijos[2] !== undefined ? (
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{datosHijos[2] !== undefined ? (
																<>
																	<select
																		name={`${datosHijos[2]?.id} || tipo_universidad`}
																		className="block w-full mx-1 mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																		onChange={actualizarState}
																		required
																	>
																		{datosHijos[2]?.tipo_universidad === 1 ? (
																			<option key="1" value="1" selected>
																				Oficial
																			</option>
																		) : (
																			<option key="1" value="1">
																				Oficial
																			</option>
																		)}
																		{datosHijos[2]?.tipo_universidad === 2 ? (
																			<option key="2" value="2" selected>
																				Privada 1
																			</option>
																		) : (
																			<option key="2" value="2">
																				Privada 1
																			</option>
																		)}
																		{datosHijos[2]?.tipo_universidad === 3 ? (
																			<option key="3" value="3" selected>
																				Privada 2
																			</option>
																		) : (
																			<option key="3" value="3">
																				Privada 2
																			</option>
																		)}
																	</select>
																</>
															) : null}
														</td>
													) : null}
													{datosHijos[3] !== undefined ? (
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{datosHijos[3] !== undefined ? (
																<>
																	<select
																		name={`${datosHijos[3]?.id} || tipo_universidad`}
																		className="block w-full mx-1 mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																		onChange={actualizarState}
																		required
																	>
																		{datosHijos[3]?.tipo_universidad === 1 ? (
																			<option key="1" value="1" selected>
																				Oficial
																			</option>
																		) : (
																			<option key="1" value="1">
																				Oficial
																			</option>
																		)}
																		{datosHijos[3]?.tipo_universidad === 2 ? (
																			<option key="2" value="2" selected>
																				Privada 1
																			</option>
																		) : (
																			<option key="2" value="2">
																				Privada 1
																			</option>
																		)}
																		{datosHijos[3]?.tipo_universidad === 3 ? (
																			<option key="3" value="3" selected>
																				Privada 2
																			</option>
																		) : (
																			<option key="3" value="3">
																				Privada 2
																			</option>
																		)}
																	</select>
																</>
															) : null}
														</td>
													) : null}
												</tr>
												<tr
													key={i}
													className={i++ % 2 === 0 ? "bg-white" : "bg-gray-100"}
												>
													<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
														Años faltantes de universidad
													</td>
													{datosHijos[0] !== undefined ? (
														<td className="justify-center px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{datosHijos[0] !== undefined ? (
																<>
																	<select
																		name={`${datosHijos[0].id} || anos_faltantes_universidad`}
																		className="block w-full mx-1 mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																		onChange={actualizarState}
																		required
																	>
																		{_.range(0, 5.5, 0.5).map((arr, index) => {
																			return datosHijos[0]
																				.anos_faltantes_universidad === arr ? (
																				<option
																					key={index}
																					value={arr}
																					selected
																				>
																					{arr}
																				</option>
																			) : (
																				<option key={index} value={arr}>
																					{arr}
																				</option>
																			);
																		})}
																	</select>
																</>
															) : null}
														</td>
													) : null}
													{datosHijos[1] !== undefined ? (
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{datosHijos[1] !== undefined ? (
																<>
																	<select
																		name={`${datosHijos[1].id} || anos_faltantes_universidad`}
																		className="block w-full mx-1 mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																		onChange={actualizarState}
																		required
																	>
																		{_.range(0, 5.5, 0.5).map((arr, index) => {
																			return datosHijos[1]
																				.anos_faltantes_universidad === arr ? (
																				<option
																					key={index}
																					value={arr}
																					selected
																				>
																					{arr}
																				</option>
																			) : (
																				<option key={index} value={arr}>
																					{arr}
																				</option>
																			);
																		})}
																	</select>
																</>
															) : null}
														</td>
													) : null}
													{datosHijos[2] !== undefined ? (
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{datosHijos[2] !== undefined ? (
																<>
																	<select
																		name={`${datosHijos[2].id} || anos_faltantes_universidad`}
																		className="block w-full mx-1 mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																		onChange={actualizarState}
																		required
																	>
																		{_.range(0, 5.5, 0.5).map((arr, index) => {
																			return datosHijos[2]
																				.anos_faltantes_universidad === arr ? (
																				<option
																					key={index}
																					value={arr}
																					selected
																				>
																					{arr}
																				</option>
																			) : (
																				<option key={index} value={arr}>
																					{arr}
																				</option>
																			);
																		})}
																	</select>
																</>
															) : null}
														</td>
													) : null}
													{datosHijos[3] !== undefined ? (
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{datosHijos[3] !== undefined ? (
																<>
																	<select
																		name={`${datosHijos[3].id} || anos_faltantes_universidad`}
																		className="block w-full mx-1 mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																		onChange={actualizarState}
																		required
																	>
																		{_.range(0, 5.5, 0.5).map((arr, index) => {
																			return datosHijos[3]
																				.anos_faltantes_universidad === arr ? (
																				<option
																					key={index}
																					value={arr}
																					selected
																				>
																					{arr}
																				</option>
																			) : (
																				<option key={index} value={arr}>
																					{arr}
																				</option>
																			);
																		})}
																	</select>
																</>
															) : null}
														</td>
													) : null}
												</tr>
											</tbody>
										</table>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="flex flex-row justify-around mb-10">
				<div className="space-y-2 sm:space-y-4">
					<div className="flex flex-col">
						<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
								<div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
									<form>
										<div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
											<table className="divide-y divide-gray-200 min-w-min">
												<thead className="bg-gray-50">
													<tr
														key={i}
														className={
															i++ % 2 === 0 ? "bg-white" : "bg-gray-100"
														}
													>
														<th
															scope="col"
															className="px-6 py-3 text-sm font-medium tracking-wider text-center text-gray-700 uppercase"
														>
															Grados escolares
														</th>
														{datosHijos[0] !== undefined ? (
															<th
																scope="col"
																className="px-6 py-3 text-sm font-medium tracking-wider text-center text-gray-700 uppercase"
															>
																Hijo
																<br />1
															</th>
														) : null}
														{datosHijos[1] !== undefined ? (
															<th
																scope="col"
																className="px-6 py-3 text-sm font-medium tracking-wider text-center text-gray-700 uppercase"
															>
																Hijo
																<br />2
															</th>
														) : null}
														{datosHijos[2] !== undefined ? (
															<th
																scope="col"
																className="px-6 py-3 text-sm font-medium tracking-wider text-center text-gray-700 uppercase"
															>
																Hijo
																<br />3
															</th>
														) : null}
														{datosHijos[3] !== undefined ? (
															<th
																scope="col"
																className="px-6 py-3 text-sm font-medium tracking-wider text-center text-gray-700 uppercase"
															>
																Hijo
																<br />4
															</th>
														) : null}
														<th
															scope="col"
															className="px-6 py-3 text-sm font-medium tracking-wider text-center text-gray-700 uppercase"
														>
															Total años
														</th>
														<th
															scope="col"
															className="px-6 py-3 text-sm font-medium tracking-wider text-center text-gray-700 uppercase"
														>
															Costo mensual
														</th>
														<th
															scope="col"
															className="px-6 py-3 text-sm font-medium tracking-wider text-center text-gray-700 uppercase"
														>
															Costo anual
														</th>
														<th
															scope="col"
															className="px-6 py-3 text-sm font-medium tracking-wider text-center text-gray-700 uppercase"
														>
															Costo total
														</th>
													</tr>
												</thead>
												<tbody>
													<tr
														key={i}
														className={
															i++ % 2 === 0 ? "bg-white" : "bg-gray-100"
														}
													>
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															Nombre
														</td>
														{datosHijos[0] !== undefined ? (
															<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																{datosHijos[0]?.nombre}
															</td>
														) : null}
														{datosHijos[1] !== undefined ? (
															<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																{datosHijos[1]?.nombre}
															</td>
														) : null}
														{datosHijos[2] !== undefined ? (
															<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																{datosHijos[2]?.nombre}
															</td>
														) : null}
														{datosHijos[3] !== undefined ? (
															<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																{datosHijos[3]?.nombre}
															</td>
														) : null}
														<td
															className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap"
															colSpan={4}
														></td>
													</tr>
													<tr
														key={i}
														className={
															i++ % 2 === 0 ? "bg-white" : "bg-gray-100"
														}
													>
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															Kinder y Pre First
														</td>
														{datosHijos[0] !== undefined ? (
															<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																<>
																	{listaAnosEscolares.map((item) => {
																		if (
																			item.id ===
																			datosHijos[0].ano_escolar_actual
																		) {
																			return item.anos_faltantes_preescolar;
																		}
																	})}
																</>
															</td>
														) : null}
														{datosHijos[1] !== undefined ? (
															<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																<>
																	{listaAnosEscolares.map((item) => {
																		if (
																			item.id ===
																			datosHijos[1].ano_escolar_actual
																		) {
																			return item.anos_faltantes_preescolar;
																		}
																	})}
																</>
															</td>
														) : null}
														{datosHijos[2] !== undefined ? (
															<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																<>
																	{listaAnosEscolares.map((item) => {
																		if (
																			item.id ===
																			datosHijos[2].ano_escolar_actual
																		) {
																			return item.anos_faltantes_preescolar;
																		}
																	})}
																</>
															</td>
														) : null}
														{datosHijos[3] !== undefined ? (
															<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																<>
																	{listaAnosEscolares.map((item) => {
																		if (
																			item.id ===
																			datosHijos[3].ano_escolar_actual
																		) {
																			return item.anos_faltantes_preescolar;
																		}
																	})}
																</>
															</td>
														) : null}
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{totalAnosPreescolar}
														</td>
														{/* TODO Costos mensuales */}
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{datosHijos[0] !== undefined ? (
																totalAnosPreescolar > 0 ? (
																	<select
																		name={`${datosHijos[0].id} || costo_preescolar`}
																		className="block w-full mx-1 mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																		onChange={actualizarState}
																		required
																	>
																		<option key={10000} value={0}>
																			Seleccione un valor
																		</option>
																		{_.range(2000, 8500, 500).map(
																			(arr, index) => {
																				return datosHijos[0]
																					.costo_preescolar === arr ? (
																					<option
																						key={index}
																						value={arr}
																						selected
																					>
																						{arr}
																					</option>
																				) : (
																					<option key={index} value={arr}>
																						{arr}
																					</option>
																				);
																			}
																		)}
																	</select>
																) : (
																	<NumberFormat
																		thousandSeparator={true}
																		displayType={"text"}
																		prefix={"$ "}
																		value={0}
																	/>
																)
															) : null}
														</td>
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{datosHijos[0] !== undefined ? (
																totalAnosPreescolar > 0 ? (
																	<NumberFormat
																		thousandSeparator={true}
																		displayType={"text"}
																		prefix={"$ "}
																		value={datosHijos[0].costo_preescolar * 12}
																	/>
																) : (
																	<NumberFormat
																		thousandSeparator={true}
																		displayType={"text"}
																		prefix={"$ "}
																		value={0}
																	/>
																)
															) : null}
														</td>
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{datosHijos[0] !== undefined ? (
																totalAnosPreescolar > 0 ? (
																	<NumberFormat
																		thousandSeparator={true}
																		displayType={"text"}
																		prefix={"$ "}
																		value={
																			datosHijos[0].costo_preescolar *
																			12 *
																			totalAnosPreescolar
																		}
																	/>
																) : (
																	<NumberFormat
																		thousandSeparator={true}
																		displayType={"text"}
																		prefix={"$ "}
																		value={0}
																	/>
																)
															) : (
																<NumberFormat
																	thousandSeparator={true}
																	displayType={"text"}
																	prefix={"$ "}
																	value={0}
																/>
															)}
														</td>
													</tr>

													<tr
														key={i}
														className={
															i++ % 2 === 0 ? "bg-white" : "bg-gray-100"
														}
													>
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															Primaria
														</td>
														{datosHijos[0] !== undefined ? (
															<>
																{listaAnosEscolares.map((item) => {
																	if (
																		item.id === datosHijos[0].ano_escolar_actual
																	) {
																		return (
																			<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																				{item.anos_faltantes_primaria}
																			</td>
																		);
																	}
																})}
															</>
														) : null}
														{datosHijos[1] !== undefined ? (
															<>
																{listaAnosEscolares.map((item) => {
																	if (
																		item.id === datosHijos[1].ano_escolar_actual
																	) {
																		return (
																			<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																				{item.anos_faltantes_primaria}
																			</td>
																		);
																	}
																})}
															</>
														) : null}
														{datosHijos[2] !== undefined ? (
															<>
																{listaAnosEscolares.map((item) => {
																	if (
																		item.id === datosHijos[2].ano_escolar_actual
																	) {
																		return (
																			<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																				{item.anos_faltantes_primaria}
																			</td>
																		);
																	}
																})}
															</>
														) : null}
														{datosHijos[3] !== undefined ? (
															<>
																{listaAnosEscolares.map((item) => {
																	if (
																		item.id === datosHijos[3].ano_escolar_actual
																	) {
																		return (
																			<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																				{item.anos_faltantes_primaria}
																			</td>
																		);
																	}
																})}
															</>
														) : null}

														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{totalAnosPrimaria}
														</td>
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{datosHijos[0] !== undefined ? (
																totalAnosPrimaria > 0 ? (
																	<select
																		name={`${datosHijos[0].id} || costo_primaria`}
																		className="block w-full mx-1 mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																		onChange={actualizarState}
																		required
																	>
																		<option key={10000} value={0}>
																			Seleccione un valor
																		</option>
																		{_.range(2000, 12500, 500).map(
																			(arr, index) => {
																				return datosHijos[0].costo_primaria ===
																					arr ? (
																					<option
																						key={index}
																						value={arr}
																						selected
																					>
																						{arr}
																					</option>
																				) : (
																					<option key={index} value={arr}>
																						{arr}
																					</option>
																				);
																			}
																		)}
																	</select>
																) : (
																	<NumberFormat
																		thousandSeparator={true}
																		displayType={"text"}
																		prefix={"$ "}
																		value={0}
																	/>
																)
															) : null}
														</td>
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{datosHijos[0] !== undefined ? (
																totalAnosPrimaria > 0 ? (
																	<NumberFormat
																		thousandSeparator={true}
																		displayType={"text"}
																		prefix={"$ "}
																		value={datosHijos[0].costo_primaria * 12}
																	/>
																) : (
																	<NumberFormat
																		thousandSeparator={true}
																		displayType={"text"}
																		prefix={"$ "}
																		value={0}
																	/>
																)
															) : null}
														</td>
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{datosHijos[0] !== undefined ? (
																totalAnosPrimaria > 0 ? (
																	<NumberFormat
																		thousandSeparator={true}
																		displayType={"text"}
																		prefix={"$ "}
																		value={
																			datosHijos[0].costo_primaria *
																			12 *
																			totalAnosPrimaria
																		}
																	/>
																) : (
																	<NumberFormat
																		thousandSeparator={true}
																		displayType={"text"}
																		prefix={"$ "}
																		value={0}
																	/>
																)
															) : (
																<NumberFormat
																	thousandSeparator={true}
																	displayType={"text"}
																	prefix={"$ "}
																	value={0}
																/>
															)}
														</td>
													</tr>
													<tr
														key={i}
														className={
															i++ % 2 === 0 ? "bg-white" : "bg-gray-100"
														}
													>
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															Secundaria
														</td>

														{datosHijos[0] !== undefined ? (
															<>
																{listaAnosEscolares.map((item) => {
																	if (
																		item.id === datosHijos[0].ano_escolar_actual
																	) {
																		return (
																			<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																				{item.anos_faltantes_secundaria}
																			</td>
																		);
																	}
																})}
															</>
														) : null}
														{datosHijos[1] !== undefined ? (
															<>
																{listaAnosEscolares.map((item) => {
																	if (
																		item.id === datosHijos[1].ano_escolar_actual
																	) {
																		return (
																			<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																				{item.anos_faltantes_secundaria}
																			</td>
																		);
																	}
																})}
															</>
														) : null}
														{datosHijos[2] !== undefined ? (
															<>
																{listaAnosEscolares.map((item) => {
																	if (
																		item.id === datosHijos[2].ano_escolar_actual
																	) {
																		return (
																			<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																				{item.anos_faltantes_secundaria}
																			</td>
																		);
																	}
																})}
															</>
														) : null}
														{datosHijos[3] !== undefined ? (
															<>
																{listaAnosEscolares.map((item) => {
																	if (
																		item.id === datosHijos[3].ano_escolar_actual
																	) {
																		return (
																			<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																				{item.anos_faltantes_secundaria}
																			</td>
																		);
																	}
																})}
															</>
														) : null}

														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{totalAnosSecundaria}
														</td>
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{datosHijos[0] !== undefined ? (
																totalAnosSecundaria > 0 ? (
																	<select
																		name={`${datosHijos[0].id} || costo_secundaria`}
																		className="block w-full mx-1 mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																		onChange={actualizarState}
																		required
																	>
																		<option key={10000} value={0}>
																			Seleccione un valor
																		</option>
																		{_.range(3000, 15500, 500).map(
																			(arr, index) => {
																				return datosHijos[0]
																					.costo_secundaria === arr ? (
																					<option
																						key={index}
																						value={arr}
																						selected
																					>
																						{arr}
																					</option>
																				) : (
																					<option key={index} value={arr}>
																						{arr}
																					</option>
																				);
																			}
																		)}
																	</select>
																) : (
																	<NumberFormat
																		thousandSeparator={true}
																		displayType={"text"}
																		prefix={"$ "}
																		value={0}
																	/>
																)
															) : null}
														</td>
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{datosHijos[0] !== undefined ? (
																totalAnosSecundaria > 0 ? (
																	<NumberFormat
																		thousandSeparator={true}
																		displayType={"text"}
																		prefix={"$ "}
																		value={datosHijos[0].costo_secundaria * 12}
																	/>
																) : (
																	<NumberFormat
																		thousandSeparator={true}
																		displayType={"text"}
																		prefix={"$ "}
																		value={0}
																	/>
																)
															) : null}
														</td>
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{datosHijos[0] !== undefined ? (
																<NumberFormat
																	thousandSeparator={true}
																	displayType={"text"}
																	prefix={"$ "}
																	value={
																		datosHijos[0].costo_secundaria *
																		12 *
																		totalAnosSecundaria
																	}
																/>
															) : null}
														</td>
													</tr>
													<tr
														key={i}
														className={
															i++ % 2 === 0 ? "bg-white" : "bg-gray-100"
														}
													>
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															Preparatoria
														</td>
														{datosHijos[0] !== undefined ? (
															<>
																{listaAnosEscolares.map((item) => {
																	if (
																		item.id === datosHijos[0].ano_escolar_actual
																	) {
																		return (
																			<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																				{item.anos_faltantes_preparatoria}
																			</td>
																		);
																	}
																})}
															</>
														) : null}
														{datosHijos[1] !== undefined ? (
															<>
																{listaAnosEscolares.map((item) => {
																	if (
																		item.id === datosHijos[1].ano_escolar_actual
																	) {
																		return (
																			<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																				{item.anos_faltantes_preparatoria}
																			</td>
																		);
																	}
																})}
															</>
														) : null}
														{datosHijos[2] !== undefined ? (
															<>
																{listaAnosEscolares.map((item) => {
																	if (
																		item.id === datosHijos[2].ano_escolar_actual
																	) {
																		return (
																			<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																				{item.anos_faltantes_preparatoria}
																			</td>
																		);
																	}
																})}
															</>
														) : null}
														{datosHijos[3] !== undefined ? (
															<>
																{listaAnosEscolares.map((item) => {
																	if (
																		item.id === datosHijos[3].ano_escolar_actual
																	) {
																		return (
																			<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																				{item.anos_faltantes_preparatoria}
																			</td>
																		);
																	}
																})}
															</>
														) : null}
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{totalAnosPreparatoria}
														</td>
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{datosHijos[0] !== undefined ? (
																totalAnosPreparatoria > 0 ? (
																	<select
																		name={`${datosHijos[0].id} || costo_preparatoria`}
																		className="block w-full mx-1 mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																		onChange={actualizarState}
																		required
																	>
																		<option key={10000} value={0}>
																			Seleccione un valor
																		</option>
																		{_.range(4000, 16500, 500).map(
																			(arr, index) => {
																				return datosHijos[0]
																					.costo_preparatoria === arr ? (
																					<option
																						key={index}
																						value={arr}
																						selected
																					>
																						{arr}
																					</option>
																				) : (
																					<option key={index} value={arr}>
																						{arr}
																					</option>
																				);
																			}
																		)}
																	</select>
																) : (
																	<NumberFormat
																		thousandSeparator={true}
																		displayType={"text"}
																		prefix={"$ "}
																		value={0}
																	/>
																)
															) : null}
														</td>
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{datosHijos[0] !== undefined ? (
																totalAnosPreparatoria > 0 ? (
																	<NumberFormat
																		thousandSeparator={true}
																		displayType={"text"}
																		prefix={"$ "}
																		value={
																			datosHijos[0].costo_preparatoria * 12
																		}
																	/>
																) : (
																	<NumberFormat
																		thousandSeparator={true}
																		displayType={"text"}
																		prefix={"$ "}
																		value={0}
																	/>
																)
															) : null}
														</td>
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{datosHijos[0] !== undefined ? (
																<NumberFormat
																	thousandSeparator={true}
																	displayType={"text"}
																	prefix={"$ "}
																	value={
																		datosHijos[0].costo_preparatoria *
																		12 *
																		totalAnosPreparatoria
																	}
																/>
															) : null}
														</td>
													</tr>
													<tr
														key={i}
														className={
															i++ % 2 === 0 ? "bg-white" : "bg-gray-100"
														}
													>
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															Universidad
														</td>

														{datosHijos[0] !== undefined ? (
															<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																{datosHijos[0].anos_faltantes_universidad}
															</td>
														) : null}

														{datosHijos[1] !== undefined ? (
															<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																{datosHijos[1].anos_faltantes_universidad}
															</td>
														) : null}

														{datosHijos[2] !== undefined ? (
															<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																{datosHijos[2].anos_faltantes_universidad}
															</td>
														) : null}

														{datosHijos[3] !== undefined ? (
															<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																{datosHijos[3].anos_faltantes_universidad}
															</td>
														) : null}

														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{totalAnosUniversidad}
														</td>
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{datosHijos[0] !== undefined ? (
																<NumberFormat
																	thousandSeparator={true}
																	displayType={"text"}
																	prefix={"$ "}
																	value={maximoMontoUniversidad}
																/>
															) : null}
														</td>
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{datosHijos[0] !== undefined ? (
																totalAnosUniversidad > 0 ? (
																	<NumberFormat
																		thousandSeparator={true}
																		displayType={"text"}
																		prefix={"$ "}
																		value={maximoMontoUniversidad * 12}
																	/>
																) : (
																	<NumberFormat
																		thousandSeparator={true}
																		displayType={"text"}
																		prefix={"$ "}
																		value={0}
																	/>
																)
															) : null}
														</td>
														{/* acumulado */}
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{datosHijos[0] !== undefined ? (
																<NumberFormat
																	thousandSeparator={true}
																	displayType={"text"}
																	prefix={"$ "}
																	value={
																		maximoMontoUniversidad *
																		12 *
																		totalAnosUniversidad
																	}
																/>
															) : null}
														</td>
													</tr>
													<tr
														key={i}
														className={
															i++ % 2 === 0 ? "bg-white" : "bg-gray-100"
														}
													>
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															TOTAL
														</td>

														{datosHijos[0] !== undefined ? (
															<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																{anosHijo0}
															</td>
														) : null}

														{datosHijos[1] !== undefined ? (
															<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																{anosHijo1}
															</td>
														) : null}

														{datosHijos[2] !== undefined ? (
															<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																{anosHijo2}
															</td>
														) : null}

														{datosHijos[3] !== undefined ? (
															<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																{anosHijo3}
															</td>
														) : null}

														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{totalAnosHijos}
														</td>
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap"></td>
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap"></td>
														<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
															{datosHijos[0] !== undefined ? (
																<NumberFormat
																	thousandSeparator={true}
																	displayType={"text"}
																	prefix={"$ "}
																	value={granTotal}
																/>
															) : null}
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<br />
			<br />
			<br />
			<br />
		</div>
		// <div className="flex flex-row justify-center mb-10">
		// </div>
	);
};

export default Educacion;
