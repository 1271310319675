import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import _ from "lodash";

import {
	obtenerDatosCliente,
	estiloVidaSuma,
	obtenerHijoMenor,
	actualizarDatosCliente,
} from "../../functions/NecesidadesQueries";
import { calculate_age } from "../../functions/DependantsQueries";

const EstiloVida = ({ cliente_id }) => {
	const [datosCliente, setDatosCliente] = useState("");
	const [sumaEstiloVida, setSumaEstiloVida] = useState(0);
	const [hijoMenor, setHijoMenor] = useState({});

	const actualizarState = (e) => {
		console.log('aa', e.target.name, e.target.value)
		actualizarDatosCliente(cliente_id, e.target.name, e.target.value, setDatosCliente);
	};

	useEffect(() => {
		obtenerHijoMenor(cliente_id, setHijoMenor);
		obtenerDatosCliente(cliente_id, setDatosCliente);
		estiloVidaSuma(cliente_id, setSumaEstiloVida);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (datosCliente.esperanza_vida === 0) {
			console.log("en use effect");
			actualizarDatosCliente(cliente_id, "esperanza_vida", 60, setDatosCliente);
		}
	}, [datosCliente, cliente_id]);

	return (
		<div>
			<div className="pb-6 text-sm font-bold text-center sm:text-2xl">
				Gasto fijo
			</div>
			<form>
				<div className="flex flex-col items-center">
					<div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
						<table className="border divide-y divide-gray-200 shadow-xl">
							<tbody className="bg-white">
								<tr
									key="0"
									className={0 % 2 === 0 ? "bg-white" : "bg-gray-100"}
								>
									<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
										Gasto mensual
									</td>
									<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
										<NumberFormat
											thousandSeparator={true}
											displayType={"text"}
											prefix={"$ "}
											value={sumaEstiloVida}
										/>
									</td>
								</tr>
								<tr
									key="1"
									className={1 % 2 === 0 ? "bg-white" : "bg-gray-100"}
								>
									<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
										Edad de independencia de los hijos
									</td>
									<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
										<select
											name="edad_independencia_hijos"
											className="block w-full mx-1 mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
											onChange={actualizarState}
											required
										>
											{_.range(18, 31).map((item) => {
												return datosCliente.edad_independencia_hijos ===
													item ? (
													<option key={item} value={item} selected>
														{item}
													</option>
												) : (
													<option key={item} value={item}>
														{item}
													</option>
												);
											})}
										</select>
									</td>
								</tr>
								<tr
									key="2"
									className={2 % 2 === 0 ? "bg-white" : "bg-gray-100"}
								>
									<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
										Edad a proyectar
									</td>
									<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
										<select
											name="esperanza_vida"
											className="block w-full mx-1 mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
											onChange={actualizarState}
											required
										>
											{_.range(60, 100).map((item) => {
												return datosCliente.esperanza_vida === item ? (
													<option key={item} value={item} selected>
														{item}
													</option>
												) : (
													<option key={item} value={item}>
														{item}
													</option>
												);
											})}
										</select>
									</td>
								</tr>
								<tr
									key="3"
									className={2 % 2 === 0 ? "bg-white" : "bg-gray-100"}
								>
									<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
										Edad actual del hijo más pequeño
									</td>
									<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
										{calculate_age(hijoMenor.fecha_nacimiento)}
									</td>
								</tr>
								<tr
									key="4"
									className={3 % 2 === 0 ? "bg-white" : "bg-gray-100"}
								>
									<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
										Años para la independencia financiera
										<br />
										del más pequeño de los hijos
									</td>
									<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
										{datosCliente.edad_independencia_hijos - calculate_age(hijoMenor.fecha_nacimiento)}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</form>
		</div>
	);
};

export default EstiloVida;
