import { useState } from "react";
import { Link } from "react-router-dom";
import { getUserDetails } from "../functions/UsuariosQueries";
import { actualizarUsuario, actualizarPermisosUsuario } from "../functions/UsuariosQueries";

const UsuarioEditar = ({
	user,
	setUser,
	setEditarUsuario,
	setRecargarUsuario,
	permissions,
	userPermissions
}) => {
	const [data, setData] = useState({
		id: user.id,
		name: user.name ? user.name : "",
		username: user.username ? user.username : "",
		email: user.email ? user.email : "",
		activo: user.activo ? user.activo : "",
		role: user.role ? user.role : "",
		promotoria: user.promotoria ? user.promotoria : "",
		unidad: user.unidad ? user.unidad : "",
		ciudad: user.ciudad ? user.ciudad : "",
		lider: user.lider ? user.lider : "",
		promotor: user.promotor ? user.promotor : "",
		generacion: user.generacion ? user.generacion : "",
		tipo_origen_agente: user.tipo_origen_agente ? user.tipo_origen_agente : "",
		logo: user.logo ? user.logo : "",
	});

	const [permisos, setPermisos] = useState(userPermissions)

	const updatePermisos = (e) => {
		const tname = Number(e.target.name)
		let newPermisos = [...permisos]

		// check if tname is included in newPermisos
		if (newPermisos.includes(tname)) {
			// remove tname from newPermisos
			newPermisos = newPermisos.filter(p => p !== tname)
		} else {
			// add tname to newPermisos
			newPermisos.push(tname)
		}
		// remove duplicates
		newPermisos = newPermisos.filter((item, index) => newPermisos.indexOf(item) === index)

		setPermisos(newPermisos)
	}

	const updateData = (e) => {
		setData({
			...data,
			[e.target.name]: e.target.value,
		});
	};

	const submitForm = (event) => {
		event.preventDefault();
		actualizarUsuario(data, setUser);
		actualizarPermisosUsuario(permisos, data.id, console.log);
		setEditarUsuario(false);
		setRecargarUsuario(true);
	};

	return (
		<div>
			<form method="POST" onSubmit={submitForm}>
				<table className="min-w-full border divide-y divide-gray-200 rounded-lg ">
					<tbody>
						<tr
							key={1}
							className={
								1 % 2 === 0
									? "bg-white px-6 py-3 text-s font-medium"
									: "bg-gray-50 px-6 py-3 text-s font-medium"
							}
						>
							<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
								Nombre *
							</td>
							<input
								type="text"
								name="name"
								id="name"
								value={data.name}
								onChange={updateData}
								autoComplete="name"
								className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								required
							/>
						</tr>
						<tr
							key={2}
							className={
								2 % 2 === 0
									? "bg-white px-6 py-3 text-s font-medium"
									: "bg-gray-50 px-6 py-3 text-s font-medium"
							}
						>
							<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
								Nombre de usuario *
							</td>
							<input
								type="text"
								name="username"
								id="username"
								value={data.username}
								onChange={updateData}
								autoComplete="username"
								className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								required
							/>
						</tr>
						<tr
							key={3}
							className={
								3 % 2 === 0
									? "bg-white px-6 py-3 text-s font-medium"
									: "bg-gray-50 px-6 py-3 text-s font-medium"
							}
						>
							<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
								Email *
							</td>
							<input
								type="email"
								name="email"
								id="email"
								value={data.email}
								onChange={updateData}
								autoComplete="email"
								className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								required
							/>
						</tr>
						<tr
							key={4}
							className={
								4 % 2 === 0
									? "bg-white px-6 py-3 text-s font-medium"
									: "bg-gray-50 px-6 py-3 text-s font-medium"
							}
						>
							<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
								Status *
							</td>
							<select
								name="activo"
								className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								onChange={updateData}
								required
							>
								{data.activo.length < 1 ? (
									<option key="0" value="" selected>
										Seleccione una opción
									</option>
								) : null}
								{data.activo === "1" ? (
									<option key="1" value="activo" selected>
										Activo
									</option>
								) : (
									<option key="1" value="activo">
										Activo
									</option>
								)}
								{data.activo === "0" ? (
									<option key="2" value="0" selected>
										Inactivo
									</option>
								) : (
									<option key="2" value="0">
										Inactivo
									</option>
								)}
							</select>
						</tr>
						<tr
							key={5}
							className={
								5 % 2 === 0
									? "bg-white px-6 py-3 text-s font-medium"
									: "bg-gray-50 px-6 py-3 text-s font-medium"
							}
						>
							<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
								Rol *
							</td>
							<select
								name="role"
								className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								onChange={updateData}
								required
							>
								{data.role.length < 1 ? (
									<option key="0" value="" selected>
										Seleccione una opción
									</option>
								) : null}
								{data.role === "Life Planner" ? (
									<option key="1" value="Life Planner" selected>
										Life Planner
									</option>
								) : (
									<option key="1" value="Life Planner">
										Life Planner
									</option>
								)}
								{data.role === "Líder" ? (
									<option key="2" value="Líder" selected>
										Líder
									</option>
								) : (
									<option key="2" value="Líder">
										Líder
									</option>
								)}
								{data.role === "Promotor" ? (
									<option key="3" value="Promotor" selected>
										Promotor
									</option>
								) : (
									<option key="3" value="Promotor">
										Promotor
									</option>
								)}
								{data.role === "Director" ? (
									<option key="4" value="Director" selected>
										Director
									</option>
								) : (
									<option key="4" value="Director">
										Director
									</option>
								)}
							</select>
						</tr>
						<tr
							key={66}
							className={
								6 % 2 === 0
									? "bg-white px-6 py-3 text-s font-medium"
									: "bg-gray-50 px-6 py-3 text-s font-medium"
							}
						>
							<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
								Unidad *
							</td>
							<input
								type="text"
								name="unidad"
								id="unidad"
								value={data.unidad}
								onChange={updateData}
								autoComplete="unidad"
								className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								required
							/>
						</tr>
						<tr
							key={6}
							className={
								6 % 2 === 0
									? "bg-white px-6 py-3 text-s font-medium"
									: "bg-gray-50 px-6 py-3 text-s font-medium"
							}
						>
							<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
								Promotoría *
							</td>
							<input
								type="text"
								name="promotoria"
								id="promotoria"
								value={data.promotoria}
								onChange={updateData}
								autoComplete="promotoria"
								className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								required
							/>
						</tr>
						<tr
							key={7}
							className={
								7 % 2 === 0
									? "bg-white px-6 py-3 text-s font-medium"
									: "bg-gray-50 px-6 py-3 text-s font-medium"
							}
						>
							<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
								Líder *
							</td>
							<input
								type="text"
								name="lider"
								id="lider"
								value={data.lider}
								onChange={updateData}
								autoComplete="lider"
								className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								required
							/>
						</tr>
						<tr
							key={70}
							className={
								7 % 2 === 0
									? "bg-white px-6 py-3 text-s font-medium"
									: "bg-gray-50 px-6 py-3 text-s font-medium"
							}
						>
							<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
								Promotor *
							</td>
							<input
								type="text"
								name="promotor"
								id="promotor"
								value={data.promotor}
								onChange={updateData}
								autoComplete="promotor"
								className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								required
							/>
						</tr>
						<tr
							key={71}
							className={
								7 % 2 === 0
									? "bg-white px-6 py-3 text-s font-medium"
									: "bg-gray-50 px-6 py-3 text-s font-medium"
							}
						>
							<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
								Ciudad *
							</td>
							<input
								type="text"
								name="ciudad"
								id="ciudad"
								value={data.ciudad}
								onChange={updateData}
								autoComplete="ciudad"
								className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								required
							/>
						</tr>
						<tr
							key={8}
							className={
								8 % 2 === 0
									? "bg-white px-6 py-3 text-s font-medium"
									: "bg-gray-50 px-6 py-3 text-s font-medium"
							}
						>
							<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
								Generación *
							</td>
							<input
								type="text"
								name="generacion"
								id="generacion"
								value={data.generacion}
								onChange={updateData}
								autoComplete="generacion"
								className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								required
							/>
						</tr>
						<tr
							key={9}
							className={
								9 % 2 === 0
									? "bg-white px-6 py-3 text-s font-medium"
									: "bg-gray-50 px-6 py-3 text-s font-medium"
							}
						>
							<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
								Tipo de origen
							</td>
							<select
								name="tipo_origen_agente"
								className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								onChange={updateData}
								required
							>
								{data.tipo_origen_agente.length < 1 ? (
									<option key="0" value="" selected>
										Seleccione una opción
									</option>
								) : null}
								{data.tipo_origen_agente === "Novel" ? (
									<option key="1" value="Novel" selected>
										Novel
									</option>
								) : (
									<option key="1" value="Novel">
										Novel
									</option>
								)}
								{data.tipo_origen_agente === "Consolidado" ? (
									<option key="2" value="Consolidado" selected>
										Consolidado
									</option>
								) : (
									<option key="2" value="Consolidado">
										Consolidado
									</option>
								)}
							</select>
						</tr>
						<tr
							key={10}
							className={
								9 % 2 === 0
									? "bg-white px-6 py-3 text-s font-medium"
									: "bg-gray-50 px-6 py-3 text-s font-medium"
							}
						>
							<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
								Logo
							</td>
							<select
								name="logo"
								className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								onChange={updateData}
								required
							>
								{data.logo.length < 1 ? (
									<option key="0" value="" selected>
										Seleccione una opción
									</option>
								) : null}
								{data.logo === "Zeus Full" ? (
									<option key="1" value="Zeus Full" selected>
										Zeus Full
									</option>
								) : (
									<option key="1" value="Zeus Full">
										Zeus Full
									</option>
								)}
								{data.logo === "Zeus Retiro" ? (
									<option key="2" value="Zeus Retiro" selected>
										Zeus Retiro
									</option>
								) : (
									<option key="2" value="Zeus Retiro">
										Zeus Retiro
									</option>
								)}
								{data.logo === "Zeus Short" ? (
									<option key="3" value="Zeus Short" selected>
										Zeus Short
									</option>
								) : (
									<option key="3" value="Zeus Short">
										Zeus Short
									</option>
								)}
							</select>
						</tr>
					</tbody>
				</table>

				<div className="py-4 text-center text-md">
					Permisos del usuario
				</div>

				<table className="min-w-full border divide-y divide-gray-200 rounded-lg ">
					<thead>
						<tr>
							<th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-center text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
								Tipo
							</th>
							<th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-center text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
								Permiso
							</th>
						</tr>
					</thead>
					<tbody>
						{permissions.map((permission, index) => (
							<tr
								key={index}
								className={
									index % 2 === 0
										? "bg-white px-6 py-3 text-s font-medium"
										: "bg-gray-50 px-6 py-3 text-s font-medium"
								}
							>
								<td className="px-6 py-2 text-sm text-left text-gray-600 md:table-cell">
									{permission.id} - {permission.permiso}
								</td>
								<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
									<input type="checkbox" name={permission.id} checked={permisos.includes(permission.id)} onChange={updatePermisos} />

								</td>
							</tr>
						)
						)}
					</tbody>
				</table>

				<div className="flex flex-row justify-around w-full h-full mt-6">
					<Link to="/Usuarios">
						<button className="px-3 py-1 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-xl text-light">
							Cancelar
						</button>
					</Link>
					<button
						type="submit"
						onKeyPress={submitForm}
						className="px-3 py-1 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
					>
						Guardar
					</button>
				</div>
			</form>
		</div>
	);
};

export default UsuarioEditar;
