import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getAllLifePlanners } from "../functions/UsuariosQueries";

const Panel = () => {
	const [lifePlanners, setLifePlanners] = useState();

	useEffect(() => {
		setTimeout(() => {
			getAllLifePlanners(setLifePlanners);
		}, 300);
	}, []);

	const capitalizeFirstLetter = (str) =>
		str.replace(
			/(^\w|\s\w)(\S*)/g,
			(_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
		);

	return (
		<div className="p-3 bg-gray-100">
			<div className="flex flex-col px-8">
				<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
						<div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
							<div className="py-2 text-2xl text-center">Usuarios</div>
							<div>
								<table className="min-w-full divide-y divide-gray-200">
									<thead className="bg-gray-50">
										<tr>
											<th
												scope="col"
												className="px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase"
											>
												Nombre
											</th>
											<th
												scope="col"
												className="hidden px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase md:table-cell"
											>
												Rol
											</th>

											<th
												scope="col"
												className="px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase"
											>
												Detalles
											</th>
										</tr>
									</thead>
									<tbody>
										{typeof lifePlanners === "undefined" ||
											lifePlanners === null ||
											lifePlanners.length === 0 ? null : (
											<>
												{lifePlanners.map((item) => {
													return (
														<tr
															key={item?.id}
															className={
																item?.id % 2 === 0
																	? "bg-white px-6 py-3 text-s font-medium"
																	: "bg-gray-50 px-6 py-3 text-s font-medium"
															}
														>
															<td className={
																item?.activo === "1"
																	? "px-6 py-2 text-sm text-center text-gray-600 md:table-cell"
																	: "px-6 py-2 text-sm text-center text-gray-600 md:table-cell line-through decoration-red-500"
															}>
																{capitalizeFirstLetter(item?.name)}
															</td>
															<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
																{item?.role}
															</td>
															<td className="text-center text-gray-600">
																<Link to={`/Usuario/${item.id}`}>
																	<button className="px-4 py-1 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-2xl text-light">
																		Detalles
																	</button>
																</Link>
															</td>
														</tr>
													);
												})}
											</>
										)}
									</tbody>
								</table>
							</div>
							<div className="flex flex-row justify-around m-6">
								<div className="py-2">
									<Link to={`/Usuario/0`} className="py-8">
										<button className="px-4 py-1 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-2xl text-light">
											Nuevo usuario
										</button>
									</Link>
									<br />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Panel;
