import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { crearUsuario } from "../functions/UsuariosQueries";

const UsuarioNuevo = () => {
	const [usuario, setUsuario] = useState("");
	let name,
		username,
		password,
		email,
		activo,
		role,
		promotoria,
		unidad,
		ciudad,
		lider,
		promotor,
		generacion,
		tipo_origen_agente;

	const [data, setData] = useState({
		name: "",
		username: "",
		password: "",
		email: "",
		activo: "",
		role: "",
		promotoria: "",
		unidad: "",
		ciudad: "",
		lider: "",
		promotor: "",
		generacion: "",
		tipo_origen_agente: "",
		logo: "",
	});

	const updateData = (e) => {
		setData({
			...data,
			[e.target.name]: e.target.value,
		});
	};

	let navigate = useNavigate();

	const submitForm = (event) => {
		event.preventDefault();
		crearUsuario(data, setUsuario);
		navigate(`/Usuarios/${usuario}`);
	};

	return (
		<div>
			<form method="POST" onSubmit={submitForm}>
				<table className="min-w-full border divide-y divide-gray-200 rounded-lg ">
					<tbody>
						<tr
							key={1}
							className={
								1 % 2 === 0
									? "bg-white px-6 py-3 text-s font-medium"
									: "bg-gray-50 px-6 py-3 text-s font-medium"
							}
						>
							<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
								Nombre *
							</td>
							<input
								type="text"
								name="name"
								id="name"
								value={name}
								onChange={updateData}
								autoComplete="name"
								className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								required
							/>
						</tr>
						<tr
							key={2}
							className={
								2 % 2 === 0
									? "bg-white px-6 py-3 text-s font-medium"
									: "bg-gray-50 px-6 py-3 text-s font-medium"
							}
						>
							<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
								Nombre de usuario *
							</td>
							<input
								type="text"
								name="username"
								id="username"
								value={username}
								onChange={updateData}
								autoComplete="username"
								className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								required
							/>
						</tr>
						<tr
							key={22}
							className={
								2 % 2 === 0
									? "bg-white px-6 py-3 text-s font-medium"
									: "bg-gray-50 px-6 py-3 text-s font-medium"
							}
						>
							<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
								Password (8+) *
							</td>
							<input
								type="password"
								name="password"
								id="password"
								value={password}
								onChange={updateData}
								autoComplete="password"
								className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								minlength="8"
								required
							/>
						</tr>
						<tr
							key={3}
							className={
								3 % 2 === 0
									? "bg-white px-6 py-3 text-s font-medium"
									: "bg-gray-50 px-6 py-3 text-s font-medium"
							}
						>
							<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
								Email *
							</td>
							<input
								type="email"
								name="email"
								id="email"
								value={email}
								onChange={updateData}
								autoComplete="email"
								className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								required
							/>
						</tr>
						<tr
							key={4}
							className={
								4 % 2 === 0
									? "bg-white px-6 py-3 text-s font-medium"
									: "bg-gray-50 px-6 py-3 text-s font-medium"
							}
						>
							<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
								Status *
							</td>
							<select
								name="activo"
								className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								onChange={updateData}
								required
							>
								<option value="">Seleccione una opción</option>
								<option value="1">Activo</option>
								<option value="0">Inactivo</option>
							</select>
						</tr>
						<tr
							key={5}
							className={
								5 % 2 === 0
									? "bg-white px-6 py-3 text-s font-medium"
									: "bg-gray-50 px-6 py-3 text-s font-medium"
							}
						>
							<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
								Rol *
							</td>
							<select
								name="role"
								className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								onChange={updateData}
								required
							>
								<option value="">Seleccione una opción</option>
								<option value="Life Planner">Life Planner</option>
								<option value="Líder">Líder</option>
								<option value="Promotor">Promotor</option>
								<option value="Director">Director</option>
							</select>
						</tr>
						<tr
							key={66}
							className={
								6 % 2 === 0
									? "bg-white px-6 py-3 text-s font-medium"
									: "bg-gray-50 px-6 py-3 text-s font-medium"
							}
						>
							<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
								Unidad *
							</td>
							<input
								type="text"
								name="unidad"
								id="unidad"
								value={unidad}
								onChange={updateData}
								autoComplete="unidad"
								className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								required
							/>
						</tr>
						<tr
							key={6}
							className={
								6 % 2 === 0
									? "bg-white px-6 py-3 text-s font-medium"
									: "bg-gray-50 px-6 py-3 text-s font-medium"
							}
						>
							<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
								Promotoría *
							</td>
							<input
								type="text"
								name="promotoria"
								id="promotoria"
								value={promotoria}
								onChange={updateData}
								autoComplete="promotoria"
								className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								required
							/>
						</tr>
						<tr
							key={71}
							className={
								71 % 2 === 0
									? "bg-white px-6 py-3 text-s font-medium"
									: "bg-gray-50 px-6 py-3 text-s font-medium"
							}
						>
							<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
								Líder *
							</td>
							<input
								type="text"
								name="lider"
								id="lider"
								value={lider}
								onChange={updateData}
								autoComplete="lider"
								className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								required
							/>
						</tr>
						<tr
							key={72}
							className={
								72 % 2 === 0
									? "bg-white px-6 py-3 text-s font-medium"
									: "bg-gray-50 px-6 py-3 text-s font-medium"
							}
						>
							<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
								Promotor *
							</td>
							<input
								type="text"
								name="promotor"
								id="promotor"
								value={promotor}
								onChange={updateData}
								autoComplete="promotor"
								className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								required
							/>
						</tr>
						<tr
							key={7}
							className={
								7 % 2 === 0
									? "bg-white px-6 py-3 text-s font-medium"
									: "bg-gray-50 px-6 py-3 text-s font-medium"
							}
						>
							<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
								Ciudad *
							</td>
							<input
								type="text"
								name="ciudad"
								id="ciudad"
								value={ciudad}
								onChange={updateData}
								autoComplete="ciudad"
								className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								required
							/>
						</tr>
						<tr
							key={8}
							className={
								8 % 2 === 0
									? "bg-white px-6 py-3 text-s font-medium"
									: "bg-gray-50 px-6 py-3 text-s font-medium"
							}
						>
							<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
								Generación *
							</td>
							<input
								type="text"
								name="generacion"
								id="generacion"
								value={generacion}
								onChange={updateData}
								autoComplete="generacion"
								className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								required
							/>
						</tr>
						<tr
							key={9}
							className={
								9 % 2 === 0
									? "bg-white px-6 py-3 text-s font-medium"
									: "bg-gray-50 px-6 py-3 text-s font-medium"
							}
						>
							<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
								Tipo de origen *
							</td>
							<select
								name="tipo_origen_agente"
								className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								onChange={updateData}
								required
							>
								<option value="">Seleccione una opción</option>
								<option value="Novel">Novel</option>
								<option value="Consolidado">Consolidado</option>
							</select>
						</tr>
						<tr
							key={10}
							className={
								9 % 2 === 0
									? "bg-white px-6 py-3 text-s font-medium"
									: "bg-gray-50 px-6 py-3 text-s font-medium"
							}
						>
							<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
								Logo *
							</td>
							<select
								name="tipo_origen_agente"
								className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								onChange={updateData}
								required
							>
								<option value="">Seleccione una opción</option>
								<option value="Zeus Full">Zeus Full</option>
								<option value="Zeus Retiro">Zeus Retiro</option>
								<option value="Zeus Short">Zeus Short</option>
							</select>
						</tr>
					</tbody>
				</table>
				<div className="flex flex-row justify-around w-full h-full mt-6">
					<Link to="/Usuarios">
						<button className="px-3 py-1 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-xl text-light">
							Cancelar
						</button>
					</Link>
					<button
						type="submit"
						onKeyPress={submitForm}
						className="px-3 py-1 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
					>
						Guardar
					</button>
				</div>
			</form>
		</div>
	);
};

export default UsuarioNuevo;
