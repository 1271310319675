import axios from "axios";
import useAxios from "../hooks/useAxios";
import { baseurl } from "./constants";

export const LlenadoTablaDatos = (id) => {
	const { response, loading, error } = useAxios({
		axiosInstance: axios,
		method: "GET",
		url: `${baseurl}/api/auth/datos/llenadoTablaDatos/${id}`,
	});
	return { response, loading, error };
}

export const ObtenerDatos = (id) => {
	const { response, loading, error } = useAxios({
		axiosInstance: axios,
		method: "GET",
		url: `${baseurl}/api/auth/datos/show/${id}`,
	});
	return { response, loading, error };
}

export const obtenerDatos = async (user_id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/datos/show/${user_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
		})
		.catch((error) => console.error(error));
};

export const obtenerUltimoAnoEducacionUniversitaria = async (
	user_id,
	callback
) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/datos/obtenerUltimoAnoEducacionUniversitaria/${user_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON);
		})
		.catch((error) => console.error(error));
};

export const obtenerUltimoAnoFondosNoPrevistos = async (user_id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/datos/obtenerUltimoAnoFondosNoPrevistos/${user_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON);
		})
		.catch((error) => console.error(error));
};

export const obtenerEdadIniciaRetiro = async (user_id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/retiro/obtenerEdadIniciaRetiro/${user_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON);
		})
		.catch((error) => console.error(error));
};

export const obtenerEdadFinRetiro = async (user_id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/retiro/obtenerEdadFinRetiro/${user_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON);
		})
		.catch((error) => console.error(error));
};

export const obtenerDatosLimitadoEdad = async (user_id, edad, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/datos/obtenerDatosLimitadoEdad/${user_id}/${edad}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
		})
		.catch((error) => console.error(error));
};

export const obtenerUltimoDato = async (user_id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/datos/obtenerUltimoDato/${user_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
		})
		.catch((error) => console.error(error));
};

export const obtenerPrimerDato = async (user_id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/datos/obtenerPrimerDato/${user_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
		})
		.catch((error) => console.error(error));
};

export const obtenerTodosDatosMensuales = async (user_id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/mensuales/all/${user_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
		})
		.catch((error) => console.error(error));
};

export const obtenerDatoMensual = async (
	principal,
	tipo,
	subtipo,
	concepto,
	cliente_id,
	callback
) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/mensuales/obtenerDatoMensual/${principal}/${tipo}/${subtipo}/${concepto}/${cliente_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON);
		})
		.catch((error) => console.error(error));
};

// export const llenadoTablaDatos = async (user_id) => {
// 	const token = JSON.parse(localStorage.getItem("jwt"));
// 	let myHeaders = new Headers();
// 	myHeaders.append("Authorization", `Bearer ${token}`);
// 	myHeaders.append("Accept", "application/json");
// 	myHeaders.append("Content-Type", "application/json");
// 	myHeaders.append("Access-Control-Allow-Origin", "*");
// 	myHeaders.append("Access-Control-Allow-Credentials", "true");
// 	const requestOptions = {
// 		method: "GET",
// 		headers: myHeaders,
// 		cors: "no-cors",
// 	};
// 	const url = `${baseurl}/api/auth/datos/llenadoTablaDatos/${user_id}`;
// 	return await fetch(url, requestOptions)
// 		.then((response) => response.json())
// 		.catch((error) => console.error(error));
// };

export const obtenerFondoPrevistoRetiroAcumulado = async (user_id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/retiro/obtenerFondoPrevistoRetiroAcumulado/${user_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON);
		})
		.catch((error) => console.error(error));
};
export const obtenerFondoNoPrevistoRetiro = async (user_id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/retiro/obtenerFondoNoPrevistoRetiro/${user_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON);
		})
		.catch((error) => console.error(error));
};