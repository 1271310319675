export const obtenerColor = (id) => {
  switch (id) {
    case 1:
      return "red-700";
    case 2:
      return "amber-500";
    case 3:
      return "orange-600";
    case 4:
      return "lime-400";
    case 5:
      return "cyan-400";
    case 6:
      return "indigo-600";
    case 7:
      return "sky-500";
    case 8:
      return "green-600";
    case 9:
      return "fuchsia-500";
    case 10:
      return "yellow-300";
    default:
      return "yellow-300";
  }
};

// function to determine if an array has any of the elements of another array
export const hasAny = (arr, arr2) => {
  return arr2.some((e) => arr.includes(e));
}
