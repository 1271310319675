import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getUserPermissions } from "../functions/AuthQueries";

const Soluciones = () => {
	let params = useParams();

	const cliente_id = params.cliente_id;
	const [userPermissions, setUserPermissions] = useState([])
	const [mostrarSolucionesRetiro, setMostrarSolucionesRetiro] = useState(0)

	useEffect(() => {
		const user_id = JSON.parse(localStorage.getItem("user_id"));
		getUserPermissions(user_id, setUserPermissions)
	}, [])

	const [seccionActiva, setSeccionActiva] = useState('');
	const buttonSeccion = (num) => {
		if (seccionActiva === num) {
			setSeccionActiva("");
		} else {
			setSeccionActiva(num);
		}
		if (num !== 2) setMostrarSolucionesRetiro(0)
	};

	const mostrarSoluciones = (value) => {
		setMostrarSolucionesRetiro(value);
	}

	console.log('seccionActiva', seccionActiva)
	console.log('mostrarSolucionesRetiro', mostrarSolucionesRetiro)

	const IframeSolucionesRetiroPrudential = () => {
		return (
			<iframe
				src="https://view.genial.ly/63194dba5de3a900186fcb99"
				title="genial.ly"
				className="w-screen h-screen"
			/>)
	}

	const IframeSolucionesRetiroVida = () => {
		return (
			<iframe
				src="https://view.genial.ly/6319e3fff167e0001183291c"
				title="genial.ly"
				className="w-screen h-screen"
			/>)
	}

	return (
		<div className="p-3 bg-gray-100 ">
			<div className="mb-6 text-3xl text-center">Soluciones</div>

			<div className="flex flex-row justify-around mt-6">
				<Link to={`/client/${cliente_id}`}>
					<button className="px-3 py-1 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-xl text-light">
						Regresar
					</button>
				</Link>
			</div>

			<div className="bg-gray-100">
				<div className="flex flex-col justify-around mt-6 md:flex-row">

					{userPermissions.includes(61) ? (
						<button
							className={
								seccionActiva === 1
									? "px-3 py-1 mb-2 text-gray-100 bg-blue-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-gray-700 rounded-xl text-light"
									: "px-3 py-1 mb-2 text-gray-100 bg-gray-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
							}
							onClick={() => buttonSeccion(1)}
						>
							Respaldos Prudential
						</button>
					) : null}

					{userPermissions.includes(62) ? (
						<button
							className={
								seccionActiva === 2
									? "px-3 py-1 mb-2 text-gray-100 bg-blue-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-gray-700 rounded-xl text-light"
									: "px-3 py-1 mb-2 text-gray-100 bg-gray-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
							}
							onClick={() => buttonSeccion(2)}
						>
							Soluciones Retiro
						</button>
					) : null}

					{userPermissions.includes(63) ? (
						<button
							className={
								seccionActiva === 3
									? "px-3 py-1 mb-2 text-gray-100 bg-blue-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-gray-700 rounded-xl text-light"
									: "px-3 py-1 mb-2 text-gray-100 bg-gray-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
							}
							onClick={() => buttonSeccion(3)}
						>
							Soluciones 10+
						</button>
					) : null}

				</div>
			</div>

			<br />

			{seccionActiva === 1 ? (
				<iframe
					src="https://view.genial.ly/625de27d302a8e00114bbe4f"
					title="genial.ly"
					className="w-screen h-screen"
				/>
			) : null}

			{seccionActiva === 2 ? (
				<>
					<div className="flex flex-col justify-around mt-2 md:flex-row">
						<button
							className={
								seccionActiva === 1
									? "px-3 py-1 mb-2 text-gray-100 bg-blue-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-gray-700 rounded-xl text-light"
									: "px-3 py-1 mb-2 text-gray-100 bg-gray-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
							}
							onClick={() => mostrarSoluciones(1)}
						>
							Soluciones Retiro Prudential
						</button>
						<button
							className={
								seccionActiva === 1
									? "px-3 py-1 mb-2 text-gray-100 bg-blue-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-gray-700 rounded-xl text-light"
									: "px-3 py-1 mb-2 text-gray-100 bg-gray-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
							}
							onClick={() => mostrarSoluciones(2)}
						>
							Soluciones Retiro Vida
						</button>
					</div>
					<div className="flex flex-col justify-around mt-6 md:flex-row">
						{mostrarSolucionesRetiro === 1
							? <IframeSolucionesRetiroPrudential /> :
							mostrarSolucionesRetiro === 2
								? <IframeSolucionesRetiroVida /> : null}
					</div>
				</>
			) : null
			}

			{
				seccionActiva === 3 ? (
					<div className="mb-6 text-2xl text-center">
						Sección <b>Soluciones 10+</b> no disponible temporalmente
					</div>
				) : null
			}

		</div >
	);
};

export default Soluciones;
