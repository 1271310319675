import React, { useState } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import { reporteDirectivo } from "../../functions/ReportesQueries";
import Spinner from "../../Spinner";

const ReporteLider = () => {
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [datos, setDatos] = useState([]);
	const [loading, setLoading] = useState(false);

	const buscar = () => {
		setLoading(true);
		reporteDirectivo(
			moment(startDate).format("YYYY-MM-DD"),
			moment(endDate).format("YYYY-MM-DD"),
			setDatos,
			setLoading
		);
	};

	const capitalizeFirstLetter = (str) =>
		str.replace(
			/(^\w|\s\w)(\S*)/g,
			(_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
		);

	return (
		<div className="h-screen py-3 bg-gray-100">
			<div className="p-3 bg-gray-100">
				<div className="flex flex-col px-8">
					<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
								<div className="py-2 text-2xl text-center">
									Reportes Directivo
								</div>
								<div>
									<div className="flex flex-row justify-around w-full h-full mt-6">
										<div>
											<div className="text-center">Inicio:</div>
											<DatePicker
												dateFormat="dd-MM-yyyy"
												locale="es"
												selected={startDate}
												onChange={(date) => setStartDate(date)}
												className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
											/>
										</div>
										<div>
											<div className="text-center">Fin:</div>
											<DatePicker
												dateFormat="dd-MM-yyyy"
												locale="es"
												selected={endDate}
												onChange={(date) => setEndDate(date)}
												className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
											/>
										</div>
									</div>
									<div className="flex flex-row justify-around w-full h-full mt-6">
										<button
											className="px-3 py-1 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
											onClick={() => buscar()}
										>
											Buscar
										</button>
									</div>
								</div>
								<table className="min-w-full my-6 divide-y divide-gray-200">
									<thead className="bg-gray-50">
										<tr>
											<th
												scope="col"
												className="px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase"
											>
												Usuario
											</th>
											<th
												scope="col"
												className="hidden px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase md:table-cell"
											>
												Número de clientes
											</th>
										</tr>
									</thead>
									<tbody>
										{typeof datos === "undefined" ||
										datos === null ||
										datos.length === 0 ? null : (
											<>
												{datos.map((item) => {
													return (
														<tr
															key={item?.id}
															className={
																item?.id % 2 === 0
																	? "bg-white px-6 py-3 text-s font-medium"
																	: "bg-gray-50 px-6 py-3 text-s font-medium"
															}
														>
															<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
																{capitalizeFirstLetter(item?.name)}
															</td>
															<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
																{item?.total}
															</td>
														</tr>
													);
												})}
											</>
										)}
									</tbody>
								</table>
							</div>
							{loading ? <Spinner /> : null}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReporteLider;
