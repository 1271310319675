import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { showClient, countClientDependants } from "../functions/ClientsQueries";
import { getUserPermissions } from "../functions/AuthQueries";
import {
	getClientDependants,
	removeDependant,
	calculate_age,
} from "../functions/DependantsQueries";

import DetalleCliente from "./cards/DetalleCliente";
import NuevoCliente from "./cards/NuevoCliente";
import EditarCliente from "./cards/EditarCliente";
import { ButtonListado } from "./ButtonListado";
import { ClientButtonsBar } from "./ClientButtonsBar";

const Client = () => {
	let params = useParams();

	const [cliente, setCliente] = useState({});
	const [irEditar, setIrEditar] = useState(false);
	const [cuantosDependientes, setCuantosDependientes] = useState(0);
	const [clientDependants, setClientDependants] = useState([]);

	const [userPermissions, setUserPermissions] = useState([])

	const eliminar_dependiente = (id) => {
		removeDependant(id);
		setTimeout(() => {
			countClientDependants(params.id, setCuantosDependientes);
			getClientDependants(params.id, setClientDependants);
		}, 100);
	};

	useEffect(() => {
		if (params.id?.length) {
			showClient(params.id, setCliente);
			setTimeout(() => {
				countClientDependants(params.id, setCuantosDependientes);
				getClientDependants(params.id, setClientDependants);
			}, 100);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const user_id = JSON.parse(localStorage.getItem("user_id"));
		getUserPermissions(user_id, setUserPermissions)
	}, [])

	return (
		<div className="pt-6 mb-16 bg-gray-100 pb-18">
			<h3 className="mb-6 text-3xl text-center">
				Información general del Cliente
			</h3>
			{irEditar ? (
				<EditarCliente
					cliente={cliente}
					setCliente={setCliente}
					setIrEditar={setIrEditar}
				/>
			) : Object.keys(cliente).length === 0 ? (
				<>
					<NuevoCliente setCliente={setCliente} />
					{/* // TODO: fix */}
				</>
			) : (
				<DetalleCliente
					cliente={cliente}
					setCliente={setCliente}
					irEditar={irEditar}
					setIrEditar={setIrEditar}
				/>
			)}

			{cuantosDependientes > 0 ? (
				<div className="px-4 py-5 mx-4 bg-white shadow sm:rounded-lg sm:p-6">
					<div className="pb-4 text-lg font-medium leading-6 text-center text-gray-900">
						Dependientes
					</div>
					<table className="min-w-full border divide-y divide-gray-200 rounded-lg ">
						<thead className="bg-gray-50">
							<tr>
								<th
									scope="col"
									className="px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase"
								>
									Nombre
								</th>
								<th
									scope="col"
									className="hidden px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase md:table-cell"
								>
									Relación
								</th>
								<th
									scope="col"
									className="hidden px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase md:table-cell"
								>
									Edad
								</th>
								<th
									scope="col"
									className="hidden px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase md:table-cell"
								>
									Fuma
								</th>
								<th
									scope="col"
									className="px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase"
								>
									Detalles
								</th>
							</tr>
						</thead>
						<tbody>
							{clientDependants.map((item, index) => {
								return (
									<tr
										key={index}
										className={
											index % 2 === 0
												? "bg-white px-6 py-3 text-s font-medium"
												: "bg-gray-50 px-6 py-3 text-s font-medium"
										}
									>
										<td className="text-center text-gray-600">
											{item.nombre} {item?.apellidos}
										</td>
										<td className="hidden text-center text-gray-600 md:table-cell">
											{item.relacion}
										</td>
										<td className="hidden text-center text-gray-600 md:table-cell">
											{calculate_age(item?.fecha_nacimiento)}
										</td>
										<td className="hidden text-center text-gray-600 md:table-cell">
											{item?.fuma === 'n' ? 'No' : 'Si'}
										</td>
										<td className="text-center text-gray-600">
											<button
												className="px-4 py-1 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-2xl text-light"
												onClick={() => {
													if (
														window.confirm(
															"Esta seguro que desea eliminar el dependiente?"
														)
													)
														eliminar_dependiente(item.id);
												}}
											>
												Eliminar
											</button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			) : null}

			{!irEditar && Object.keys(cliente).length > 0 ?
				<ButtonListado />
				: null}

			<br />
			<div className="relative flex flex-col items-center">
				<div className="w-5/6 border-t border-gray-700"></div>
			</div>
			{(!irEditar && Object.keys(cliente).length > 0) &&
				<ClientButtonsBar userPermissions={userPermissions} cliente={cliente} />}
		</div>
	);
};

export default Client;
