import { baseurl } from "./constants";
import moment from "moment";
import useAxios from "../hooks/useAxios";
import axios from "../apis/zeusApi.js";

/**
 * CLIENTS
 */

export const AllUserClients = (user_id) => {
	const { response, loading, error } = useAxios({
		axiosInstance: axios,
		method: "GET",
		url: `/api/auth/clientes/allUserClients/${user_id}`,
	});
	return { response: response.data, loading, error };
}

export const allUserClients = async (user_id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/clientes/allUserClients/${user_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
		})
		.catch((error) => console.error(error));
};

export const saveNuevoCliente = async (data, callback) => {
	const {
		user_id,
		nombre,
		apellidos,
		email,
		cp,
		celular,
		fecha_nacimiento,
		fuentes,
	} = data;
	let raw = JSON.stringify({
		user_id: `${user_id}`,
		nombre: `${nombre}`,
		apellidos: `${apellidos}`,
		email: `${email}`,
		cp: `${cp}`,
		celular: `${celular}`,
		fecha_nacimiento: moment(fecha_nacimiento).format("YYYY-MM-DD"),
		fuentes: `${fuentes}`,
	});
	let myHeaders = new Headers();
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	let requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/clientes/store`;
	await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
		})
		.catch((error) => {
			console.error(error);
		});
};

export const SaveNuevoCliente2 = (user_id) => {
	const { response, loading, error } = useAxios({
		axiosInstance: axios,
		method: "GET",
		url: `/api/auth/clientes/allUserClients/${user_id}`,
		// requestConfig: {
		// 	body: JSON.stringify({
		// 		user_id: `${data.user_id}`,
		// 		nombre: `${data.nombre}`,
		// 		apellidos: `${data.apellidos}`,
		// 		email: `${data.email}`,
		// 		cp: `${data.cp}`,
		// 		celular: `${data.celular}`,
		// 		fecha_nacimiento: moment(data.fecha_nacimiento).format("YYYY-MM-DD"),
		// 		fuentes: `${data.fuentes}`,
		// 	})
		// }
	});
	return { response, loading, error };
}

export const SaveNuevoCliente = (data) => {
	const { response, loading, error } = useAxios({
		axiosInstance: axios,
		method: "GET",
		url: `/api/auth/clientes/store`,
	});
	return { response: response.data, loading, error };
}
export const showClient = async (user_id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/clientes/show/${user_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
		})
		.catch((error) => console.error(error));
};

export const updateCliente = async (data, id, callback) => {
	const { nombre, apellidos, email, cp, celular, fecha_nacimiento, fuentes, fuma } =
		data;
	let myHeaders = new Headers();
	const fn = moment(fecha_nacimiento).format("YYYY-MM-DD");
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	let requestOptions = {
		method: "PUT",
		headers: myHeaders,
		body: data,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/clientes/update/${id}?nombre=${nombre}&apellidos=${apellidos}&email=${email}&cp=${cp}&celular=${celular}&fecha_nacimiento=${fn}&fuentes=${fuentes}&fuma=${fuma}`;
	await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
		})
		.catch((error) => {
			console.error(error);
		});
};

export const countClientDependants = async (cliente_id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/clientesDependientes/countClientDependants/${cliente_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON);
		})
		.catch((error) => console.error(error));
};
