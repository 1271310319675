import { baseurl } from "./constants";

/**
 * Usuarios
 */

export const getAllLifePlanners = async (callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/fDwdxTdass`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
		})
		.catch((error) => console.error(error));
};

export const getUserDetails = async (id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/show/${id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON);
		})
		.catch((error) => console.error(error));
};

export const crearUsuario = async (data, callback) => {
	const {
		name,
		username,
		password,
		email,
		activo,
		role,
		promotoria,
		unidad,
		ciudad,
		lider,
		promotor,
		generacion,
		tipo_origen_agente,
	} = data;
	let raw = JSON.stringify({
		name: `${name}`,
		username: `${username}`,
		password: `${password}`,
		email: `${email}`,
		activo: `${activo}`,
		role: `${role}`,
		promotoria: `${promotoria}`,
		unidad: `${unidad}`,
		ciudad: `${ciudad}`,
		lider: `${lider}`,
		promotor: `${promotor}`,
		generacion: `${generacion}`,
		tipo_origen_agente: `${tipo_origen_agente}`,
	});
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	let requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/dxSsxeDsw5Sx23Dss`;
	await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
		})
		.catch((error) => {
			console.error(error);
		});
};

export const updateUserPwd = async (data) => {
	const {
		id,
		password,
	} = data;
	let raw = JSON.stringify({
		id: `${id}`,
		password: `${password}`,
	});
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	let requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/dcS5dhVwadtsXsxsrt`;
	await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			console.log(200)
		})
		.catch((error) => {
			console.error(error);
		});
};

export const actualizarUsuario = async (data, callback) => {
	const {
		id,
		name,
		username,
		email,
		activo,
		role,
		promotoria,
		unidad,
		ciudad,
		lider,
		promotor,
		generacion,
		tipo_origen_agente,
		logo,
	} = data;
	let raw = JSON.stringify({
		name: `${name}`,
		username: `${username}`,
		email: `${email}`,
		activo: `${activo}`,
		role: `${role}`,
		promotoria: `${promotoria}`,
		unidad: `${unidad}`,
		ciudad: `${ciudad}`,
		lider: `${lider}`,
		promotor: `${promotor}`,
		generacion: `${generacion}`,
		tipo_origen_agente: `${tipo_origen_agente}`,
		logo: `${logo}`,
	});
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	let requestOptions = {
		method: "PUT",
		headers: myHeaders,
		body: raw,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/update/${id}`;
	await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
		})
		.catch((error) => {
			console.error(error);
		});
};

export const getAllPermissions = async (callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/getAllPermissions`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
		})
		.catch((error) => console.error(error));
};

export const actualizarPermisosUsuario = async (arr, usuario, callback) => {
	let raw = JSON.stringify({
		arr: `${arr}`,
		usuario: `${usuario}`,
	});
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	let requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/user/actualizarPermisosUsuario`;
	await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
		})
		.catch((error) => {
			console.error(error);
		});
};

export const activarDesactivarUser = async (user_id, callback) => {
	let raw = JSON.stringify({
		user_id: `${user_id}`,
	});
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	let requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/activarDesactivarUser`;
	await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
		})
		.catch((error) => {
			console.error(error);
		});
};
