import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import {
	saveNewDependant,
	cuentaCuantosConyuges,
	maximoPorcentajePosible,
} from "./../../functions/DependantsQueries";

registerLocale("es", es);
let fechaNac = new Date("01/01/1980")

const Dependientes = () => {
	let nombre, apellidos, relacion, fecha_nacimiento;
	let params = useParams();

	const [tipoDependiente, setTipoDependiente] = useState("");
	const [mostrarComponente, setMostrarComponente] = useState(false);
	const [dependiente, setDependiente] = useState({});
	const [cuantosConyuges, setCuantosConyuges] = useState();
	const [maxPorcentaje, setMaxPorcentaje] = useState(0);
	// let fecha = new Date();
	// let fechaNac = new Date("01/01/2000");

	const [data, setData] = useState({
		cliente_id: params.cliente_id,
		nombre: "",
		apellidos: "",
		relacion: "",
		fecha_nacimiento: fechaNac,
		porcentaje_aportacion: "0",
		fuma: "n",
	});

	const updateTipoDependiente = (e) => {
		setTipoDependiente(e.target.value);
		setMostrarComponente(false);
		setData({
			...data,
			relacion: e.target.value,
		});
	};

	const buscar = () => {
		setMostrarComponente(true);
	};

	const updateData = (e) => {
		setData({
			...data,
			[e.target.name]: e.target.value,
		});
	};

	const updateFecha = (value) => {
		setData({
			...data,
			fecha_nacimiento: moment(value).format("YYYY-MM-DD"),
		});
	};

	const submitForm = (e) => {
		e.preventDefault();
		saveNewDependant(data, setDependiente);
		Regresar();
	};

	let navigate = useNavigate();

	const Regresar = () => {
		setTimeout(() => {
			navigate(`/client/${params.cliente_id}`);
		}, 100);
	};

	useEffect(() => {
		cuentaCuantosConyuges(params.cliente_id, setCuantosConyuges);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const listener = (event) => {
			if (event.code === "Enter" || event.code === "NumpadEnter") {
				alert(
					"Asegúrese de llenar los datos marcados con asterisco y después de click en el botón Guardar"
				);
				event.preventDefault();
			}
		};
		document.addEventListener("keydown", listener);
		return () => {
			document.removeEventListener("keydown", listener);
		};
	}, []);
	useEffect(() => {
		maximoPorcentajePosible(params.cliente_id, setMaxPorcentaje);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<div>
				<div className="px-4 py-5 mx-4 bg-white shadow sm:rounded-lg sm:p-6">
					<div className="text-center">Seleccione el tipo de dependiente</div>
					<div className="flex flex-row justify-center w-full h-full mt-2">
						{cuantosConyuges < 1 ? (
							<select
								name="relacion"
								className="block w-1/3 mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								onChange={updateTipoDependiente}
								required
							>
								<option value=""></option>
								<option value="Cónyuge">Cónyuge</option>
								<option value="Hijo">Hijo</option>
								<option value="Padres">Padres</option>
								<option value="Hermano">Hermano</option>
								<option value="Abuelo">Abuelo</option>
								<option value="Otro">Otro</option>
							</select>
						) : (
							<select
								name="relacion"
								className="block w-1/3 mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								onChange={updateTipoDependiente}
								required
							>
								<option value=""></option>
								<option value="Hijo">Hijo</option>
								<option value="Padres">Padres</option>
								<option value="Hermano">Hermano</option>
								<option value="Abuelo">Abuelo</option>
								<option value="Otro">Otro</option>
							</select>
						)}
						{tipoDependiente ? (
							<button
								className="px-3 mx-4 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
								onClick={buscar}
							>
								Agregar
							</button>
						) : (
							<button
								className="px-3 mx-4 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
								onClick={() => alert("Debe seleccionar un valor")}
							>
								Agregar
							</button>
						)}
					</div>
					{cuantosConyuges > 0 ? (
						<div className="min-w-full pt-5 text-sm font-bold text-center sm:text-xl">
							<p className="text-sm font-light text-center">
								Si ya existe un cónyuge registrado, no podrá seleccionar un
								segundo cónyuge.
							</p>
						</div>
					) : null}
				</div>
				<br />
				{mostrarComponente ? (
					<div className="px-4 py-5 mx-4 bg-white shadow sm:rounded-lg sm:p-6">
						<form onSubmit={submitForm}>
							<div className="grid grid-cols-6 gap-6">
								<div className="col-span-6 sm:col-span-6 lg:col-span-2">
									<label
										htmlFor="nombre"
										className="block text-sm font-medium text-gray-700"
									>
										Nombre *
									</label>
									<input
										type="text"
										name="nombre"
										id="nombre"
										autoComplete="nombre"
										value={nombre}
										onChange={updateData}
										className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
										required
									/>
								</div>

								{tipoDependiente === "Conyuge" || tipoDependiente === "Otro" ? (
									<div className="col-span-6 sm:col-span-6 lg:col-span-2">
										<label
											htmlFor="last-name"
											className="block text-sm font-medium text-gray-700"
										>
											Apellidos
										</label>
										<input
											type="text"
											name="apellidos"
											id="apellidos"
											value={apellidos}
											onChange={updateData}
											autoComplete="apellidos"
											className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
										/>
									</div>
								) : null}
								<div className="col-span-6 sm:col-span-6 lg:col-span-2">
									<label
										htmlFor="fecha_nacimiento"
										className="block text-sm font-medium text-gray-700"
									>
										Fecha de nacimiento (AAAA-MM-DD) *
									</label>
									<DatePicker
										dateFormat="yyyy-MM-dd"
										name="fecha_nacimiento"
										locale="es"
										showMonthDropdown
										showYearDropdown
										popperPlacement="bottom"
										scrollableYearDropdown
										// selected={
										// 	new Date(
										// 		moment(data.fecha_nacimiento).format("MM-DD-YYYY")
										// 	)
										// }
										selected={
											data?.fecha_nacimiento
												? new Date(moment(data?.fecha_nacimiento))
												: new Date(moment(fechaNac))
										}
										onChange={(fecha_nacimiento) =>
											updateFecha(fecha_nacimiento)
										}
										className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
									/>
								</div>
								<div className="col-span-6 sm:col-span-6 lg:col-span-2">
									<label
										htmlFor="porcentaje_aportacion"
										className="block text-sm font-medium text-gray-700"
									>
										% Económico que aporta al gasto familiar
									</label>
									<select
										name="porcentaje_aportacion"
										className="block w-full mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
										onChange={updateData}
									>
										{_.range(0, maxPorcentaje, 10).map((arr, index) => {
											return (
												<>
													<option key={index} value={arr}>
														{arr}
													</option>
												</>
											);
										})}
									</select>
								</div>
								{tipoDependiente !== "Hijo" ? (
									<div className="col-span-6 sm:col-span-6 lg:col-span-2">
										<label
											htmlFor="fuma"
											className="block text-sm font-medium text-gray-700"
										>
											Fuma / no fuma
										</label>
										<select
											name="fuma"
											className="block w-full mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
											onChange={updateData}
											defaultValue="n"
										>
											<option value="n">No</option>
											<option value="s">Si</option>
										</select>
									</div>
								) : null}
								{tipoDependiente === "Otro" ? (
									<div className="col-span-6 sm:col-span-6 lg:col-span-2">
										<label
											htmlFor="nombre"
											className="block text-sm font-medium text-gray-700"
										>
											Relación *
										</label>
										<input
											type="text"
											name="relacion"
											id="relacion"
											autoComplete="relacion"
											value={relacion}
											onChange={updateData}
											className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
											required
										/>
									</div>
								) : null}
							</div>
							<div className="flex flex-row justify-around w-full h-full mt-6">
								<Link to="/clients">
									<button className="px-3 py-1 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-xl text-light">
										Cancelar
									</button>
								</Link>
								<button
									type="submit"
									className="px-3 py-1 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
								>
									Guardar
								</button>
							</div>
						</form>
					</div>
				) : null}
			</div>
			{!mostrarComponente ? (
				<div className="flex flex-row justify-around w-full h-full mt-6">
					<button
						className="px-3 py-1 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
						onClick={Regresar}
					>
						Cancelar
					</button>
				</div>
			) : null}
		</div>
	);
};

export default Dependientes;
