import React from "react";
// import Navbar from "./Navbar";

const Home = () => {
  return (
    <div className="h-full p-3 bg-gray-100">
      <div className="px-4 py-5 mx-4 bg-white shadow sm:rounded-lg sm:p-6 text-center">
        <p> Vaya! Algo ha ido mal...</p>
        <br />
        <p>
          Por favor intenta nuevamente con alguno de los enlaces en la barra de
          navegacion
        </p>
      </div>
    </div>
  );
};

export default Home;
