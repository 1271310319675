import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import CurrencyInput from "react-currency-input-field";
import _ from "lodash";

import {
	obtenerProyectos10Mas,
	actualizarDatosProyectos10Mas,
	arregloProyectos10MasFindOrCreate,
	obtenerSumaProyectos10Mas,
	obtenerMaximoAnosProyectos10Mas,
} from "../../functions/NecesidadesQueries";

const Proyectos10Mas = ({ cliente_id }) => {
	const [sumaProyectos, setSumaProyectos] = useState(0);
	const [maximoAnos, setMaximoAnos] = useState(0);
	const [proyectos, setProyectos] = useState([]);
	let i = 0;

	const actualizarState = (e) => {
		let arr = e.target.name.split(" || ");
		const val =
			arr[1] === "monto" ? e.target.value.replace(/\D+/g, "") : e.target.value;
		actualizarDatosProyectos10Mas(arr[0], arr[1], val);
		setTimeout(() => {
			obtenerSumaProyectos10Mas(cliente_id, setSumaProyectos);
			obtenerMaximoAnosProyectos10Mas(cliente_id, setMaximoAnos);
		}, 300);
	};

	useEffect(() => {
		arregloProyectos10MasFindOrCreate(cliente_id, console.log);
		setTimeout(() => {
			obtenerProyectos10Mas(cliente_id, setProyectos);
			obtenerSumaProyectos10Mas(cliente_id, setSumaProyectos);
			obtenerMaximoAnosProyectos10Mas(cliente_id, setMaximoAnos);
		}, 500);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<div className="flex flex-row justify-around mb-10">
				<div className="space-y-2 sm:space-y-4">
					<div className="text-sm font-bold text-center sm:text-2xl">
						Proyectos 10+
					</div>

					<div className="flex flex-col">
						<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
								<div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
									<form>
										<table className="min-w-full divide-y divide-gray-200">
											<thead className="bg-gray-50">
												<tr
													key={i}
													className={i++ % 2 === 0 ? "bg-white" : "bg-gray-100"}
												>
													<th
														scope="col"
														className="px-6 py-3 text-sm font-medium tracking-wider text-center text-gray-700 uppercase"
													>
														Proyecto
													</th>
													<th
														scope="col"
														className="px-6 py-3 text-sm font-medium tracking-wider text-center text-gray-700 uppercase"
													>
														Descripción
													</th>
													<th
														scope="col"
														className="px-6 py-3 text-sm font-medium tracking-wider text-center text-gray-700 uppercase"
													>
														Objetivo
													</th>
													<th
														scope="col"
														className="px-6 py-3 text-sm font-medium tracking-wider text-center text-gray-700 uppercase"
													>
														Monto
														<br />
														requerido
													</th>
													<th
														scope="col"
														className="px-6 py-3 text-sm font-medium tracking-wider text-center text-gray-700 uppercase"
													>
														Plazo
														<br />
														(en años)
													</th>
												</tr>
											</thead>
											<tbody className="bg-white">
												{typeof proyectos === "undefined" ||
												proyectos === null ||
												proyectos.length === 0
													? null
													: proyectos.map((item, index) => {
															return (
																<tr
																	key={i}
																	className={
																		i++ % 2 === 0 ? "bg-white" : "bg-gray-100"
																	}
																>
																	<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																		<input
																			type="text"
																			name={`${item.id} || nombre_proyecto`}
																			id="item.subtipo"
																			defaultValue={item.nombre_proyecto}
																			onChange={actualizarState}
																			className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																			required
																		/>
																	</td>
																	<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																		<input
																			type="text"
																			name={`${item.id} || descripcion`}
																			id="item.subtipo"
																			defaultValue={item.descripcion}
																			onChange={actualizarState}
																			className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																			required
																		/>
																	</td>
																	<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																		<input
																			type="text"
																			name={`${item.id} || objetivo`}
																			id="item.subtipo"
																			defaultValue={item.objetivo}
																			onChange={actualizarState}
																			className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																			required
																		/>
																	</td>
																	<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																		{/* <input
                                      type="text"
                                      name={`${item.id} || monto`}
                                      id="item.subtipo"
                                      defaultValue={item.monto}
                                      onChange={actualizarState}
                                      className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                      required
                                    /> */}
																		<CurrencyInput
																			id="item.subtipo"
																			name={`${item.id} || monto`}
																			defaultValue={item.monto}
																			className="block w-full mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																			onChange={actualizarState}
																			decimalsLimit={2}
																			required
																		/>
																	</td>
																	<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																		<select
																			name={`${item.id} || plazo_anos`}
																			className="block w-full mx-1 mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																			onChange={actualizarState}
																			required
																		>
																			{_.range(10, 31).map((arr, index) => {
																				return item.plazo_anos === arr ? (
																					<option
																						key={index}
																						value={arr}
																						selected
																					>
																						{arr}
																					</option>
																				) : (
																					<option key={index} value={arr}>
																						{arr}
																					</option>
																				);
																			})}
																		</select>
																	</td>
																</tr>
															);
													  })}
												<tr
													key={i}
													className={i++ % 2 === 0 ? "bg-white" : "bg-gray-100"}
												>
													<td className="px-6 py-2 font-medium text-center text-gray-900 whitespace-nowrap">
														TOTAL
													</td>
													<td colSpan={2}></td>
													<td className="px-6 py-2 font-medium text-center text-gray-900 whitespace-nowrap">
														<NumberFormat
															thousandSeparator={true}
															displayType={"text"}
															prefix={"$ "}
															value={sumaProyectos}
														/>
													</td>
													<td className="px-6 py-2 font-medium text-center text-gray-900 whitespace-nowrap">
														<NumberFormat
															thousandSeparator={true}
															displayType={"text"}
															value={maximoAnos}
														/>{" "}
														años
													</td>
												</tr>
											</tbody>
										</table>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<br />
			<br />
			<br />
			<br />
		</div>
	);
};

export default Proyectos10Mas;
