import React, { useState, useEffect } from "react";
import {
  obtenerDatosCliente,
  obtenerDatosDependientes,
  obtenerDatosDependientesRestringido,
  // cuentaCuantosHijos,
} from "../../functions/NecesidadesQueries";
import { calculate_age } from "../../functions/DependantsQueries";

const DatosGenerales = ({ cliente_id }) => {
  const [datosCliente, setDatosCliente] = useState("");
  const [datosConyuge, setDatosConyuge] = useState("");
  const [datosFamiliares, setDatosFamiliares] = useState("");
  // const [cuantosHijos, setCuantosHijos] = useState(0);
  let i = 0;

  useEffect(() => {
    obtenerDatosCliente(cliente_id, setDatosCliente);
    obtenerDatosDependientes(cliente_id, "Conyuge", setDatosConyuge);
    obtenerDatosDependientesRestringido(
      cliente_id,
      "Conyuge",
      setDatosFamiliares
    );
    // cuentaCuantosHijos(cliente_id, setCuantosHijos);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <div className="flex flex-row justify-around mb-10">
        <div className="space-y-2 sm:space-y-4">
          <div className="text-sm font-bold text-center sm:text-2xl">
            Datos generales
          </div>

          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                  <form>
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-100">
                        <tr
                          key={i}
                          className={i++ % 2 === 0 ? "bg-white" : "bg-gray-100"}
                        >
                          <th
                            scope="col"
                            className="px-6 py-3 text-sm font-medium tracking-wider text-center text-gray-700 uppercase"
                          >
                            Relación
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-sm font-medium tracking-wider text-center text-gray-700 uppercase"
                          >
                            Nombre
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-sm font-medium tracking-wider text-center text-gray-700 uppercase"
                          >
                            Fecha de nacimiento
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-sm font-medium tracking-wider text-center text-gray-700 uppercase"
                          >
                            Edad
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-sm font-medium tracking-wider text-center text-gray-700 uppercase"
                          >
                            Fuma / No fuma
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-sm font-medium tracking-wider text-center text-gray-700 uppercase"
                          >
                            % Económico
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        <tr
                          key={i}
                          className={i++ % 2 === 0 ? "bg-white" : "bg-gray-100"}
                        >
                          <td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
                            Titular
                          </td>
                          <td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
                            {datosCliente?.nombre} {datosCliente?.apellidos}
                          </td>
                          <td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
                            {datosCliente?.fecha_nacimiento}
                          </td>
                          <td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
                            {calculate_age(datosCliente?.fecha_nacimiento)}
                          </td>
                          <td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
                            {datosCliente?.fuma === "n" ? "No" : "Si"}
                          </td>
                          <td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
                            {datosCliente?.porcentaje_aportacion} %
                          </td>
                        </tr>
                        {typeof datosConyuge === "undefined" ||
                        datosConyuge === null ||
                        datosConyuge.length === 0
                          ? null
                          : datosConyuge.map((item) => {
                              return (
                                <tr
                                  key={i}
                                  className={
                                    i++ % 2 === 0 ? "bg-white" : "bg-gray-100"
                                  }
                                >
                                  <td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
                                    Conyuge
                                  </td>
                                  <td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
                                    {item?.nombre} {item?.apellidos}
                                  </td>
                                  <td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
                                    {item?.fecha_nacimiento}
                                  </td>
                                  <td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
                                    {calculate_age(item?.fecha_nacimiento)}
                                  </td>
                                  <td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
                                    {item?.fuma === "n" ? "No" : "Si"}
                                  </td>
                                  <td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
                                    {item?.porcentaje_aportacion} %
                                  </td>
                                </tr>
                              );
                            })}
                        {typeof datosFamiliares === "undefined" ||
                        datosFamiliares === null ||
                        datosFamiliares.length === 0
                          ? null
                          : datosFamiliares.map((item) => {
                              return (
                                <tr
                                  key={i}
                                  className={
                                    i++ % 2 === 0 ? "bg-white" : "bg-gray-100"
                                  }
                                >
                                  <td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
                                    {item?.relacion}
                                  </td>
                                  <td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
                                    {item?.nombre} {item?.apellidos}
                                  </td>
                                  <td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
                                    {item?.fecha_nacimiento}
                                  </td>
                                  <td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
                                    {calculate_age(item?.fecha_nacimiento)}
                                  </td>
                                  <td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
                                    {item?.fuma === "n" ? "No" : "Si"}
                                  </td>
                                  <td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
                                    {item?.porcentaje_aportacion} %
                                  </td>
                                </tr>
                              );
                            })}
                        {/* <tr
                key={i}
                className={i++ % 2 === 0 ? "bg-white" : "bg-gray-100"}
              >
                <td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
                  Número de hijos
                </td>
                <td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
                  {cuantosHijos}
                </td>
                <td
                  className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap"
                  colSpan={4}
                ></td>
              </tr>
              <tr
                key={i}
                className={i++ % 2 === 0 ? "bg-white" : "bg-gray-100"}
              >
                <td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
                  Conyuge
                </td>
                <td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
                  {datosConyuge.length}
                </td>
                <td
                  className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap"
                  colSpan={4}
                ></td>
              </tr> */}
                      </tbody>
                    </table>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default DatosGenerales;
