import axios from "axios";
import { baseurl } from "../functions/constants.js";

export default axios.create({
	baseURL: baseurl,
	headers: {
		"Content-type": "application/json",
		"Accept": "application/json",
		"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Credentials": "true"
	}
});