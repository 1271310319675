import { baseurl } from "./constants";

export const obtenerDatosCliente = async (cliente_id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/clientes/show/${cliente_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
		})
		.catch((error) => console.error(error));
};

export const obtenerEdadRetiro = async (cliente_id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/retiro/obtenerEdadRetiro/${cliente_id}`;

	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
		})
		.catch((error) => console.error(error));
};

export const obtenerDatosDependientes = async (cliente_id, tipo, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/clientesDependientes/obtenerDatosDependientes/${cliente_id}/${tipo}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
		})
		.catch((error) => console.error(error));
};

export const obtenerDatosDependientesRestringido = async (
	cliente_id,
	tipo,
	callback
) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/clientesDependientes/obtenerDatosDependientesRestringido/${cliente_id}/${tipo}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
		})
		.catch((error) => console.error(error));
};

export const cuentaCuantosHijos = async (cliente_id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/clientesDependientes/cuentaCuantosHijos/${cliente_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON);
		})
		.catch((error) => console.error(error));
};

export const estiloVidaSuma = async (cliente_id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/conceptos_mensuales/estiloVidaSuma/${cliente_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON);
		})
		.catch((error) => console.error(error));
};

export const activosSuma = async (cliente_id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/conceptos_mensuales/activosSuma/${cliente_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON);
		})
		.catch((error) => console.error(error));
};

export const gastosMensualesSuma = async (cliente_id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/conceptos_mensuales/gastosMensualesSuma/${cliente_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON);
		})
		.catch((error) => console.error(error));
};

export const ingresosMensualesSuma = async (cliente_id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/conceptos_mensuales/ingresosMensualesSuma/${cliente_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON);
		})
		.catch((error) => console.error(error));
};

export const segurosSuma = async (cliente_id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/conceptos_mensuales/segurosSuma/${cliente_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON);
		})
		.catch((error) => console.error(error));
};

export const obtenerHijoMenor = async (cliente_id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/clientesDependientes/obtenerHijoMenor/${cliente_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
		})
		.catch((error) => console.error(error));
};

export const actualizarDatosCliente = async (cliente_id, columna, valor, callback) => {
	let raw = JSON.stringify({
		cliente_id: `${cliente_id}`,
		columna: `${columna}`,
		valor: `${valor}`,
	});
	let myHeaders = new Headers();
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	let requestOptions = {
		method: "PUT",
		headers: myHeaders,
		body: raw,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/clientes/actualizarDatosCliente`;
	await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => callback(responseJSON.data))
		.catch((error) => {
			console.error(error);
		});
};

export const actualizarDatosClienteDependiente = async (id, columna, valor) => {
	let raw = JSON.stringify({
		id: `${id}`,
		columna: `${columna}`,
		valor: `${valor}`,
	});
	let myHeaders = new Headers();
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	let requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/clientesDependientes/actualizarDatosClienteDependiente`;
	await fetch(url, requestOptions)
		.then((response) => response.json())
		// .then((responseJSON) => callback(responseJSON))
		.catch((error) => {
			console.error(error);
		});
};

export const obtenerApoyoPadres = async (cliente_id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/apoyo_padres/obtenerApoyoPadres/${cliente_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
		})
		.catch((error) => console.error(error));
};

export const actualizarDatosApoyoPadres = async (id, columna, valor) => {
	let raw = JSON.stringify({
		id: `${id}`,
		columna: `${columna}`,
		valor: `${valor}`,
	});
	let myHeaders = new Headers();
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	let requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/apoyo_padres/actualizarDatosApoyoPadres`;
	await fetch(url, requestOptions)
		.then((response) => response.json())
		// .then((responseJSON) => callback(responseJSON))
		.catch((error) => {
			console.error(error);
		});
};

export const obtenerSumaApoyosMensuales = async (cliente_id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/apoyo_padres/obtenerSumaApoyosMensuales/${cliente_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON);
		})
		.catch((error) => console.error(error));
};

export const arregloPadresFindOrCreate = async (cliente_id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/apoyo_padres/arregloPadresFindOrCreate/${cliente_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON);
		})
		.catch((error) => console.error(error));
};

export const encontrarIdPreguntaAnos = async (cliente_id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/retiro/encontrarIdPreguntaAnos/${cliente_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON);
		})
		.catch((error) => console.error(error));
};

export const retiroFindOrCreate = async (cliente_id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/retiro/retiroFindOrCreate/${cliente_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON);
		})
		.catch((error) => console.error(error));
};

export const obtenerListadoGradosEscolares = async (callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/anos_escolares/index`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
		})
		.catch((error) => console.error(error));
};

export const obtenerGradosEscolaresPrecios = async (callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/grados_escolares/index`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
		})
		.catch((error) => console.error(error));
};

export const obtenerProyectos10Mas = async (cliente_id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/proyectos10mas/obtenerProyectos10Mas/${cliente_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
		})
		.catch((error) => console.error(error));
};
export const obtenerRetiro = async (cliente_id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/retiro/obtenerRetiro/${cliente_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
		})
		.catch((error) => console.error(error));
};

export const actualizarDatosProyectos10Mas = async (id, columna, valor) => {
	let raw = JSON.stringify({
		id: `${id}`,
		columna: `${columna}`,
		valor: `${valor}`,
	});
	let myHeaders = new Headers();
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	let requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/proyectos10mas/actualizarDatosProyectos10Mas`;
	await fetch(url, requestOptions)
		.then((response) => response.json())
		// .then((responseJSON) => callback(responseJSON))
		.catch((error) => {
			console.error(error);
		});
};

export const actualizarDatosRetiro = async (id, columna, valor) => {
	let raw = JSON.stringify({
		id: `${id}`,
		columna: `${columna}`,
		valor: `${valor}`,
	});
	let myHeaders = new Headers();
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	let requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/retiro/actualizarDatosRetiro`;
	await fetch(url, requestOptions)
		.then((response) => response.json())
		// .then((responseJSON) => callback(responseJSON))
		.catch((error) => {
			console.error(error);
		});
};

export const obtenerSumaProyectos10Mas = async (cliente_id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/proyectos10mas/obtenerSumaProyectos10Mas/${cliente_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON);
		})
		.catch((error) => console.error(error));
};
export const obtenerMaximoAnosProyectos10Mas = async (cliente_id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/proyectos10mas/obtenerMaximoAnosProyectos10Mas/${cliente_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON);
		})
		.catch((error) => console.error(error));
};

export const arregloProyectos10MasFindOrCreate = async (
	cliente_id,
	callback
) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/proyectos10mas/arregloProyectos10MasFindOrCreate/${cliente_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON);
		})
		.catch((error) => console.error(error));
};
