import React, { useEffect } from "react";
import { showClient } from "../../functions/ClientsQueries";
import { Link } from "react-router-dom";

const DetalleCliente = ({ cliente, setCliente, irEditar, setIrEditar }) => {
	useEffect(() => {
		if (cliente.id?.length) {
			showClient(cliente.id, setCliente);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<div className="px-4 py-5 mx-4 mb-8 bg-white shadow sm:rounded-lg sm:p-6">
				<div className="md:grid md:grid-cols-3 md:gap-6">
					<div className="md:col-span-1">
						<h3 className="text-lg font-medium leading-6 text-gray-900">
							Información del Titular
						</h3>
						<p className="mt-1 text-sm text-justify text-gray-500">
							Toda la información será tratada de manera confidencial y de
							conformidad con con lo dispuesto en la Ley Federal de Protección
							de Datos Personales en Posesión de los Particulares y a los
							requisitos que dicha legislación establece.
						</p>
						<p className="mt-1 text-sm text-justify text-gray-500">
							Puede consultar la política de privacidad{" "}
							<Link to={`/politicaPrivacidad`}>Aquí</Link>
						</p>
					</div>
					<div className="mt-5 md:mt-0 md:col-span-2">
						<div className="grid grid-cols-6 gap-6">
							<div className="col-span-6 sm:col-span-3">
								<label
									htmlFor="nombre"
									className="block text-sm font-medium text-gray-700"
								>
									Nombre
								</label>
								<input
									type="text"
									name="nombre"
									id="nombre"
									value={cliente?.nombre}
									readOnly
									autoComplete="nombre"
									className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								/>
							</div>

							<div className="col-span-6 sm:col-span-3">
								<label
									htmlFor="apellidos"
									className="block text-sm font-medium text-gray-700"
								>
									Apellidos
								</label>
								<input
									type="text"
									name="apellidos"
									id="apellidos"
									value={cliente?.apellidos}
									readOnly
									autoComplete="apellidos"
									className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								/>
							</div>

							<div className="col-span-6 sm:col-span-3">
								<label
									htmlFor="email"
									className="block text-sm font-medium text-gray-700"
								>
									Correo electrónico
								</label>
								<input
									type="text"
									name="email"
									id="email"
									value={cliente?.email}
									readOnly
									autoComplete="email"
									className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								/>
							</div>

							<div className="col-span-6 sm:col-span-3">
								<label
									htmlFor="cp"
									className="block text-sm font-medium text-gray-700"
								>
									Código postal
								</label>
								<input
									type="text"
									name="cp"
									id="cp"
									value={cliente?.cp}
									readOnly
									autoComplete="cp"
									className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								/>
							</div>

							<div className="col-span-6 sm:col-span-3">
								<label
									htmlFor="celular"
									className="block text-sm font-medium text-gray-700"
								>
									Celular
								</label>
								<input
									type="tel"
									name="celular"
									id="celular"
									value={cliente.celular}
									readOnly
									autoComplete="celular"
									className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								/>
							</div>

							<div className="col-span-6 sm:col-span-3">
								<label
									htmlFor="fecha_nacimiento"
									className="block text-sm font-medium text-gray-700"
								>
									Fecha de nacimiento (AAAA-MM-DD)
								</label>
								<input
									type="text"
									name="fecha_nacimiento"
									id="fecha_nacimiento"
									autoComplete="fecha_nacimiento"
									value={cliente.fecha_nacimiento}
									readOnly
									className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								/>
							</div>

							<div className="col-span-6 sm:col-span-3">
								<label
									htmlFor="fuentes"
									className="block text-sm font-medium text-gray-700"
								>
									Fuente
								</label>
								<input
									type="text"
									name="fuentes"
									id="fuentes"
									autoComplete="fuentes"
									value={cliente.fuentes}
									readOnly
									className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								/>
							</div>

							<div className="col-span-6 sm:col-span-3">
								<label
									htmlFor="fuma"
									className="block text-sm font-medium text-gray-700"
								>
									Fuma
								</label>
								<input
									type="text"
									name="fuma"
									id="fuma"
									autoComplete="fuma"
									value={cliente?.fuma === "n" ? "No" : "Si"}
									readOnly
									className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="flex flex-row justify-around w-full h-full mt-6">
					<button
						className="px-3 py-1 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
						onClick={() => setIrEditar(true)}
					>
						Editar
					</button>
					<Link to={`/dependientes/${cliente.id}/0`}>
						<button
							className="px-3 py-1 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
							onClick={() => setIrEditar(true)}
						>
							Agregar dependientes
						</button>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default DetalleCliente;
