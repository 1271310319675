// get enviroment from .env file
export const enviroment = process.env.NODE_ENV;

export const baseurl =
	enviroment === "development"
		? "https://zeusbackenddesarrollo.freti.mx"
		: "https://zeusbackend.freti.mx";

export const warningHeader = enviroment === "development" ? "Desarrollo" : "";

export const bgColorNames = [
	"blue-600", // -- Zeus oficial
	"cyan-300", // -- Zeus oficial
	"sky-600", // -- Zeus oficial
	"red-700",
	"lime-400",
	"amber-500",
	"green-600",
	"fuchsia-500",
	"orange-600",
	"yellow-300",
	"bg-violet-700",
	"bg-indigo-500",
];
export const bgColor = [
	"#2563eb", // blue-600 -- Zeus oficial -- rgb(37, 99, 235);
	"#67e8f9", // cyan-300 -- Zeus oficial
	"#0ea5e9", // sky-600 -- Zeus oficial
	"#b91c1c", // red-700
	"#a3e635", // lime-600
	"#f59e0b", // amber-500
	"#16a34a", // green-600t
	"#d946ef", // fuchsia-500
	"#ea580c", // orange-600
	"#fde047", // yellow-300
	"#dc2626", // red-600
	"#6c28d9", // bg-violet-700
	"#6365f1", // bg-indigo-600
];
export const borderColor = [
	"rgba(196, 201, 215, 0.8)",
	"rgba(196, 201, 215, 0.8)",
	"rgba(196, 201, 215, 0.8)",
	"rgba(196, 201, 215, 0.8)",
	"rgba(196, 201, 215, 0.8)",
	"rgba(196, 201, 215, 0.8)",
	"rgba(196, 201, 215, 0.8)",
	"rgba(196, 201, 215, 0.8)",
	"rgba(196, 201, 215, 0.8)",
	"rgba(196, 201, 215, 0.8)",
	"rgba(196, 201, 215, 0.8)",
	"rgba(196, 201, 215, 0.8)",
];

export const gastosMensualesArr = [
	"Vivienda y Servicios",
	"Alimentación",
	"Educación",
	"Transporte",
	"Entretenimiento",
	"Compras",
	"Salud y Belleza",
	"Seguros y Planes",
	"Otros",
];

export const arrInformacionFinancieraPermisos = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
export const arrNecesidades = [21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40];
export const arrGraficas = [41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60];
export const arrSoluciones = [61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80];
export const arrDescargas = [81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100];
export const arrPermisos = [121, 122];

