import { baseurl } from "./constants";

export const gastoPorTipo = async (tipo, callback) => {
	let raw = JSON.stringify({
		tipo: `${tipo}`,
	});
	let myHeaders = new Headers();
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	let requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/conceptos_mensuales/gastoPorTipo`;
	await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
		})
		.catch((error) => {
			console.error(error);
		});
};

export const gastoPorTipoSubtipoFindOrCreate = async (
	cliente_id,
	tipo,
	subtipo
) => {
	let raw = JSON.stringify({
		cliente_id: `${cliente_id}`,
		tipo: `${tipo}`,
		subtipo: `${subtipo}`,
	});
	let myHeaders = new Headers();
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	let requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/conceptos_mensuales/gastoPorTipoSubtipoFindOrCreate`;
	await fetch(url, requestOptions)
		.then((response) => response.json())
		// .then((responseJSON) => callback(responseJSON))
		.catch((error) => {
			console.error(error);
		});
};

export const gastoPorTipoSubtipo = async (
	cliente_id,
	tipo,
	subtipo,
	callback
) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/conceptos_mensuales/gastoPorTipoSubtipo/${cliente_id}/${tipo}/${subtipo}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
		})
		.catch((error) => console.error(error));
};

export const conceptosPrincipalesFindOrCreate = async (cliente_id) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/conceptos_mensuales/conceptosPrincipalesFindOrCreate/${cliente_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.catch((error) => console.error(error));
};

export const gastoPorTipoLista = async (cliente_id, tipo, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/conceptos_mensuales/gastoPorTipoLista/${cliente_id}/${tipo}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON);
		})
		.catch((error) => console.error(error));
};

export const obtenerDatosTablaSecundaria = async (
	cliente_id,
	tipo,
	subtipo,
	callback
) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/conceptos_mensuales/obtenerDatosTablaSecundaria/${cliente_id}/${tipo}/${subtipo}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
		})
		.catch((error) => console.error(error));
};

export const actualizarValoresTabla = async (id, monto) => {
	let raw = JSON.stringify({
		id: `${id}`,
		monto: `${monto}`,
	});
	let myHeaders = new Headers();
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	let requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/conceptos_mensuales/actualizarValoresTabla`;
	await fetch(url, requestOptions)
		.then((response) => response.json())
		// .then((responseJSON) => callback(responseJSON))
		.catch((error) => {
			console.error(error);
		});
};

export const actualizarSumatorias = async (cliente_id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/conceptos_mensuales/actualizarSumatorias/${cliente_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			responseJSON.data ? callback(responseJSON.data) : callback(0);
		})
		.catch((error) => console.error(error));
};
