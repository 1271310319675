import React, { useState, useEffect, useRef } from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {
	obtenerDatos,
	obtenerDatosLimitadoEdad,
} from "../../../functions/Datos";
import { bgColor } from "../../../functions/constants";
import { getClientDependants } from "../../../functions/DependantsQueries";
import { showClient } from "../../../functions/ClientsQueries";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

export default function Grafica({ cliente_id }) {
	const [datos, setDatos] = useState();
	const [datosLimitados, setDatosLimitados] = useState();
	const [edadTitular, setEdadTitular] = useState(65);
	const [dependientes, setDependientes] = useState([]);
	const [cliente, setCliente] = useState({});
	const titleRef = useRef(null);

	let gasto_fijo = [];
	let educacion = [];
	let apoyo_padres = [];
	let anos = [];
	let texto = [];
	let contador = 0;

	if (datosLimitados !== undefined) {

		// Ciclo nombres
		datosLimitados.forEach((element) => {
			gasto_fijo.push(element.gasto_fijo_anual_descendende);
			educacion.push(element.educacion_acumulada_descendente);
			apoyo_padres.push(element.apoyo_padres);
			// anos.push(element.ano);
			if (contador === 0) {
				texto.push(`${cliente.nombre}`);
				dependientes?.forEach(e => {
					texto.push(`${e.nombre}`);
				});
				contador++;
			}
		});

		// Ciclo años y edades
		let hijo_1 = datosLimitados[0].edad_hijo_1 ? datosLimitados[0].edad_hijo_1 : '';
		let hijo_2 = datosLimitados[0].edad_hijo_2 ? datosLimitados[0].edad_hijo_2 : '';
		let hijo_3 = datosLimitados[0].edad_hijo_3 ? datosLimitados[0].edad_hijo_3 : '';
		let hijo_4 = datosLimitados[0].edad_hijo_4 ? datosLimitados[0].edad_hijo_4 : '';
		datosLimitados.forEach((element) => {
			let arr = [element.ano];
			element.edad_titular && arr.push(`${element.edad_titular}`);
			element.edad_conyuge && arr.push(`${element.edad_conyuge}`);
			anos.push(arr);
			hijo_4 && arr.push(hijo_4++);
			hijo_3 && arr.push(hijo_3++);
			hijo_2 && arr.push(hijo_2++);
			hijo_1 && arr.push(hijo_1++);
		});

	}

	const scrollToTitle = () => {
		setTimeout(() => {
			titleRef.current?.scrollIntoView({ behavior: "smooth" });
		}, 3000);
	};

	useEffect(() => {
		obtenerDatos(cliente_id, setDatos);
		setEdadTitular(datos?.edad_retiro_titular);
		getClientDependants(cliente_id, setDependientes);
		showClient(cliente_id, setCliente);
		scrollToTitle();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (edadTitular) {
			obtenerDatosLimitadoEdad(cliente_id, edadTitular, setDatosLimitados);
		}
	}, [cliente_id, edadTitular]);

	console.log({ datosLimitados })
	console.log({ datos })

	const options = {
		plugins: {
			legend: {
				position: "bottom",
			},
			title: {
				display: false,
				text: "Stacked 3",
			},
			datalabels: {
				display: false,
			},
		},
		responsive: true,
		scales: {
			x: {
				stacked: true,
				grid: {
					display: false,
				},
				title: {
					display: true,
					text: texto,
				}
			},
			y: {
				stacked: true,
			},
		},
	};

	const labels = anos;

	const data = {
		labels,
		datasets: [
			{
				label: "Gasto Fijo Anual",
				data: gasto_fijo,
				backgroundColor: bgColor[11],
			},
			{
				label: "Educación",
				data: educacion,
				backgroundColor: bgColor[12],
			},
			{
				label: "Apoyo a padres",
				data: apoyo_padres,
				backgroundColor: bgColor[5],
			},
		],
	};

	console.log({ datosLimitados })
	return (
		<div className="px-4 py-5 mx-4 bg-white shadow sm:rounded-lg sm:p-6">
			<div className="text-sm font-bold text-center sm:text-2xl">
				Fondos necesarios acumulados
			</div>
			<div className="overflow-hidden">
				<div className="flex flex-col">
					<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<div className="flex flex-col items-center mt-6">
								<div className="flex justify-center w-11/12 p-4 mb-8 rounded-lg shadow-xl">
									{typeof datosLimitados === "undefined" ||
										datosLimitados === null ||
										datosLimitados.length === 0 ? null : (
										<Bar data={data} options={options} />
									)}
								</div>
								<br />
							</div>
						</div>
					</div>
				</div>
				<div ref={titleRef} />
			</div>
		</div>
	);
}
