import { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from "react-router-dom";
import { getUserDetails, updateUserPwd } from '../functions/UsuariosQueries';
const CambiarPassword = () => {

	const { id } = useParams();
	let navigate = useNavigate();

	const [data, setData] = useState({
		id,
		password: "",
		password2: "",
	})
	const [userDetails, setuserDetails] = useState('')

	useEffect(() => {
		getUserDetails(id, setuserDetails)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const submitForm = (e) => {
		console.log("en el submit")
		e.preventDefault();
		updateUserPwd(data)
		navigate(`/Usuario/${id}`);
	}

	const updateField = (e) => {
		setData({ ...data, [e.target.name]: e.target.value });
	}

	let contrasenasIguales = data.password === data.password2;

	return (
		<div className="bg-gray-100">
			<div className="flex flex-col items-center ">
				<div className="my-12 text-center">
					<div>
						Actualización de contraseña para el usuario: {`${userDetails.name}`}
					</div>
					<form onSubmit={submitForm}>

						<div className="flex flex-col items-center min-w-full ">
							<div className="mt-6 mb-2 text-center">
								<span>Nueva Contraseña</span>
							</div>
							<input
								type="password"
								name="password"
								id="password"
								onChange={updateField}
								className="w-5/6 border-white rounded-xl hover:border-blue-400 hover:duration-500"
							/>
						</div>
						<div className="mt-6 mb-2 text-center">
							<span>Repita la nueva Contraseña</span>
						</div>
						<input
							type="password"
							name="password2"
							id="password2"
							onChange={updateField}
							className="w-5/6 p-2 border-white rounded-xl hover:border-blue-400 hover:duration-500"
						/>
						{!contrasenasIguales && <><br /><span className="text-red-500">Las contraseñas no coinciden</span></>}
						<div className="mt-6 mb-2 text-center">
							<Link to={`/Usuario/${id}`}>
								<button className="px-3 py-1 mx-2 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-xl text-light">
									Cancelar
								</button>
							</Link>
							<button
								type="submit"
								className={contrasenasIguales && data.password.length ?
									"px-3 py-1 mx-2 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-xl text-light" :
									"px-3 py-1 ,x-2 text-gray-100 bg-gray-500 shadow-lg cursor-not-allowed hover:duration-700 rounded-xl text-light"
								}
								disabled={!contrasenasIguales}
							>
								Aplicar
							</button>
						</div>
					</form>
				</div>
			</div>
		</div >
	);
}

export default CambiarPassword