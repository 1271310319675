import React, { useState, useEffect, useRef } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { calculate_age } from "../../../functions/DependantsQueries";
import { showClient } from "../../../functions/ClientsQueries";
import {
	obtenerHijoMenor,
	obtenerEdadRetiro,
} from "../../../functions/NecesidadesQueries";
import { bgColor, borderColor } from "../../../functions/constants";
import fondo from "../../../assets/images/fondo_numeros.png";
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

export default function Grafica2({ cliente_id }) {
	const [cliente, setCliente] = useState({});
	const [hijoMenor, setHijoMenor] = useState("");
	const [edadRetiro, setEdadRetiro] = useState();
	const titleRef = useRef(null);

	let tiempo_vivido = 10;
	let tiempo_restante_productivo = 10;
	let tiempo_de_vida_en_estado_de_retiro = 10;

	let edad_independencia_hijos,
		anos_para_independencia,
		edad_hijo_menor,
		edad_de_retiro,
		edad_a_proyectar,
		percentage;

	const scrollToTitle = () => {
		setTimeout(() => {
			titleRef.current?.scrollIntoView({ behavior: "smooth" });
		}, 2000);
	};

	useEffect(() => {
		showClient(cliente_id, setCliente);
		obtenerHijoMenor(cliente_id, setHijoMenor);
		obtenerEdadRetiro(cliente_id, setEdadRetiro);
		scrollToTitle();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (
		typeof cliente === "undefined" ||
		cliente === null ||
		cliente.length === 0 ||
		typeof edadRetiro === "undefined" ||
		edadRetiro === null ||
		edadRetiro.length === 0
	) {
		console.log();
	} else {
		tiempo_vivido = calculate_age(cliente.fecha_nacimiento);
		edad_de_retiro = edadRetiro[0].titular;
		tiempo_restante_productivo = edad_de_retiro - tiempo_vivido;
		edad_a_proyectar = cliente.esperanza_vida;
		tiempo_de_vida_en_estado_de_retiro = edad_a_proyectar - edad_de_retiro;
		edad_independencia_hijos = cliente.edad_independencia_hijos;
		edad_hijo_menor = calculate_age(hijoMenor.fecha_nacimiento);
		anos_para_independencia = edad_independencia_hijos - edad_hijo_menor;
	}

	let sumaPorcentaje =
		tiempo_vivido +
		tiempo_restante_productivo +
		tiempo_de_vida_en_estado_de_retiro;

	const data = {
		labels: [
			"Tiempo vivido",
			"Tiempo restante productivo",
			"Tiempo de vida en estado de retiro",
		],
		datasets: [
			{
				data: [
					tiempo_vivido,
					tiempo_restante_productivo,
					tiempo_de_vida_en_estado_de_retiro,
				],
				backgroundColor: bgColor,
				borderColor: borderColor,
				borderWidth: 1,
			},
		],
	};

	const options = {
		plugins: {
			legend: {
				position: "bottom",
			},
			datalabels: {
				formatter: (arrProcentaje, ctx) => {
					let datasets = ctx.chart.data.datasets;
					if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
						percentage =
							Math.round((arrProcentaje / sumaPorcentaje) * 100, 2) + "%";
						return percentage;
					} else {
						return percentage;
					}
				},
				color: "#1f2937", // Gray 800
				font: {
					weight: "bold",
					size: 18,
				},
				align: "end",
			},
		},
	};

	return (
		<div className="px-4 pt-5 mx-4 bg-white shadow sm:rounded-lg sm:p-6">
			<div className="text-sm font-bold text-center sm:text-2xl">
				<div ref={titleRef} />
				Situación actual de vida
			</div>
			<div className="overflow-hidden">
				<div className="flex flex-col">
					<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<div className="flex flex-col items-center mt-4">
								<div className="flex justify-center w-1/2 p-4 rounded-lg shadow-xl">
									{typeof cliente === "undefined" ||
										cliente === null ||
										cliente.length === 0 ||
										typeof edadRetiro === "undefined" ||
										edadRetiro === null ||
										edadRetiro.length === 0 ? null : (
										<div
											style={{
												backgroundImage: `url(${fondo})`,
												width: "100%",
												backgroundRepeat: "no-repeat",
												backgroundPosition: "center",
												backgroundSize: "cover",
											}}
										>
											<Pie data={data} options={options} className="p-8" />
										</div>
									)}
								</div>
								<br />
								<div className="flex flex-col items-center mt-3">
									<p className="w-2/3 text-center">
										De acuerdo a su edad,{" "}
										<span className="font-bold">
											le faltan {tiempo_restante_productivo} años
										</span>{" "}
										para llegar a su etapa de retiro.{" "}
									</p>
									<br />
									<p className="w-2/3 text-center">
										A{" "}
										<span className="font-bold">
											{hijoMenor?.nombre} le faltan {anos_para_independencia}{" "}
											años
										</span>{" "}
										para llegar a la independencia económica.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
