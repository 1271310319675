/** React dependencies */
import { useRef } from "react";
import { Link, useParams } from "react-router-dom";

/** PDF export dependencies */
import html2canvas from "html2canvas";
import jspdf from "jspdf";

/** Internal components */
import Grafica1 from "./cards/graficas/Grafica1";
import Grafica2 from "./cards/graficas/Grafica2";
import Grafica3 from "./cards/graficas/Grafica3";
import Grafica4 from "./cards/graficas/Grafica4";
import Grafica5 from "./cards/graficas/Grafica5";
import Grafica6 from "./cards/graficas/Grafica6";
import Grafica7 from "./cards/graficas/Grafica7";
import Grafica8 from "./cards/graficas/Grafica8";
import Grafica9 from "./cards/graficas/Grafica9";
let responsive = false;
const Descargas = () => {
	let params = useParams();
	const cliente_id = params.cliente_id;

	// const [images, setImages] = useState([]);
	// const [mostrarBoton, setMostrarBoton] = useState(true);
	const inputRef = useRef(null);
	let imagesArray = [];

	const div2PDF = (e) => {
		console.log("generando documento");
		// imagesArray = [];
		// setImages([]);
		const but = e.target;
		but.style.display = "none";
		const arr = ["gr1", "gr3", "gr4", "gr5", "gr6", "gr7", "gr8", "gr9"];
		// setTimeout(() => {
		for (let index = 0; index < arr.length; index++) {
			let input = window.document.getElementById(arr[index]);
			html2canvas(input).then((canvas) => {
				let img = canvas.toDataURL("image/png");
				// setImages((images) => [...images, img]);
				imagesArray.push(img);
			});
		}
		// }, 0);

		// console.log(imagesArray);
		let a = 0;
		// console.log("images", images.length);
		// console.log("imagesArray", imagesArray.length);
		// if (images.length === arr.length) {
		if (imagesArray.length === arr.length) {
			const pdf = new jspdf("l", "pt");

			// images.forEach((el) => {
			imagesArray.forEach((el) => {
				// console.log("el", el);
				if (a > 0) pdf.addPage();
				// if (a === 0) {
				// 	pdf.addImage(el, "png", 160, 20, 450, 550);
				// } else if (a === 7) {
				// 	pdf.addImage(el, "png", 120, 40, 600, 450);
				// } else {
				pdf.addImage(el, "png", 20, 40, 792, 450);
				// }
				a++;
			});
			pdf.save("chart.pdf");
		} else {
			// console.log("no hay imagenes");
			setTimeout(() => {
				// console.log("en cuatro segundos");
				inputRef.current.click();
			}, 4000);
		}
		but.style.display = "block";
		// setImages([]);
	};

	return (
		<div className="p-3 mb-8 bg-gray-100">
			<h3 className="mb-6 text-3xl text-center">Descargas</h3>

			{/* <div className="flex flex-row justify-around my-8">
				<Link to={`/client/${cliente_id}`}>
					<button className="px-3 py-1 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-xl text-light">
						Regresar
					</button>
				</Link>
			</div> */}
			<div className="flex flex-row justify-around my-8">
				<button
					className={
						"px-3 py-1 mb-2 text-gray-100 bg-blue-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-gray-700 rounded-xl text-light"
					}
					onClick={(e) => div2PDF(e)}
				>
					Exportar a PDF
				</button>
				<Link to={`/client/${cliente_id}`}>
					<button className="px-3 py-1 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-xl text-light">
						Regresar
					</button>
				</Link>
			</div>
			<div className="italic text-center">
				La generación del documento puede tardar hasta un minuto, por favor
				espere.
			</div>
			<br />
			<div>
				<>
					<div className="div2PDF">
						<>
							<div id="gr1">
								<Grafica1 cliente_id={cliente_id} responsive={responsive} />
							</div>
							<br />
							<div id="gr2">
								<Grafica2 cliente_id={cliente_id} />
							</div>
							<br />
							<div id="gr3">
								<Grafica3 cliente_id={cliente_id} />
							</div>
							<br />
							<div id="gr4">
								<Grafica4 cliente_id={cliente_id} />
							</div>
							<br />
							<div id="gr5">
								<Grafica5 cliente_id={cliente_id} />
							</div>
							<br />
							<div id="gr6">
								<Grafica6 cliente_id={cliente_id} />
							</div>
							<br />
							<div id="gr6">
								<Grafica6 cliente_id={cliente_id} />
							</div>
							<br />
							<div id="gr7">
								<Grafica7 cliente_id={cliente_id} />
							</div>
							<br />
							<div id="gr8">
								<Grafica8 cliente_id={cliente_id} />
							</div>
							<br />
							<div id="gr9">
								<Grafica9 cliente_id={cliente_id} />
							</div>
						</>
					</div>
					<br />
					<div className="flex flex-row justify-around my-8">
						<button
							className={
								"px-3 py-1 mb-2 text-gray-100 bg-blue-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-gray-700 rounded-xl text-light"
							}
							onClick={(e) => div2PDF(e)}
						>
							Exportar a PDF
						</button>
						<Link to={`/client/${cliente_id}`}>
							<button className="px-3 py-1 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-xl text-light">
								Regresar
							</button>
						</Link>
					</div>
				</>
				<div className="italic text-center">
					La generación del documento puede tardar hasta un minuto, por favor
					espere.
				</div>
				<br />
				<button
					className={"invisible"}
					ref={inputRef}
					onClick={(e) => div2PDF(e)}
				>
					Exportar a PDF
				</button>
			</div>
		</div>
	);
};

export default Descargas;
