import React, { useState, useEffect } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";

import { updateCliente } from "../../functions/ClientsQueries";
registerLocale("es", es);
let fechaNac = new Date("01/01/1980")

const EditarCliente = ({ cliente, setCliente, setIrEditar }) => {
	const [data, setData] = useState({
		user_id: cliente.user_id,
		nombre: cliente.nombre,
		apellidos: cliente.apellidos,
		email: cliente.email,
		cp: cliente.cp,
		celular: cliente.celular,
		fecha_nacimiento: cliente.fecha_nacimiento || fechaNac,
		fuma: cliente.fuma,
		fuentes: cliente.fuentes,
	});

	const updateData = (e) => {
		setData({
			...data,
			[e.target.name]: e.target.value,
		});
	};

	const updateFecha = (value) => {
		setData({
			...data,
			fecha_nacimiento: moment(value).format("YYYY-MM-DD"),
		});
	};

	const submitForm = (e) => {
		e.preventDefault();
		updateCliente(data, cliente.id, setCliente);
		setIrEditar(false);
	};

	useEffect(() => {
		const listener = (event) => {
			if (event.code === "Enter" || event.code === "NumpadEnter") {
				alert(
					"Asegúrese de llenar los datos marcados con asterisco y después de click en el botón Guardar"
				);
				event.preventDefault();
			}
		};
		document.addEventListener("keydown", listener);
		return () => {
			document.removeEventListener("keydown", listener);
		};
	}, []);

	console.log({ data })

	return (
		<div className="px-4 py-5 mx-4 mb-8 bg-white shadow sm:rounded-lg sm:p-6">
			<form method="POST" onSubmit={submitForm}>
				<div className="md:grid md:grid-cols-3 md:gap-6">
					<div className="md:col-span-1">
						<h3 className="text-lg font-medium leading-6 text-gray-900">
							Información del Titular{" "}
							<span className="text-red-500">Edición</span>
						</h3>
						<p className="mt-1 text-sm text-justify text-gray-500">
							Toda la información será tratada de manera confidencial y de
							conformidad con con lo dispuesto en la Ley Federal de Protección
							de Datos Personales en Posesión de los Particulares y a los
							requisitos que dicha legislación establece.
						</p>
						<p className="mt-1 text-sm text-justify text-gray-500">
							Puede consultar la política de privacidad{" "}
							<Link to={`/politicaPrivacidad`}>Aquí</Link>
						</p>
					</div>
					<div className="mt-5 md:mt-0 md:col-span-2">
						<div className="grid grid-cols-6 gap-6">
							<div className="col-span-6 sm:col-span-3">
								<label
									htmlFor="nombre"
									className="block text-sm font-medium text-gray-700"
								>
									Nombre *
								</label>
								<input
									type="text"
									name="nombre"
									id="nombre"
									defaultValue={cliente.nombre}
									onChange={updateData}
									autoComplete="nombre"
									className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
									required
								/>
							</div>

							<div className="col-span-6 sm:col-span-3">
								<label
									htmlFor="apellidos"
									className="block text-sm font-medium text-gray-700"
								>
									Apellidos *
								</label>
								<input
									type="text"
									name="apellidos"
									id="apellidos"
									defaultValue={cliente.apellidos}
									onChange={updateData}
									autoComplete="apellidos"
									className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
									required
								/>
							</div>

							<div className="col-span-6 sm:col-span-3">
								<label
									htmlFor="email"
									className="block text-sm font-medium text-gray-700"
								>
									Correo electrónico
								</label>
								<input
									type="text"
									name="email"
									id="email"
									defaultValue={cliente.email}
									onChange={updateData}
									autoComplete="email"
									className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								/>
							</div>

							<div className="col-span-6 sm:col-span-3">
								<label
									htmlFor="cp"
									className="block text-sm font-medium text-gray-700"
								>
									Código postal
								</label>
								<input
									type="text"
									name="cp"
									id="cp"
									defaultValue={cliente.cp}
									onChange={updateData}
									autoComplete="cp"
									className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								/>
							</div>

							<div className="col-span-6 sm:col-span-3">
								<label
									htmlFor="celular"
									className="block text-sm font-medium text-gray-700"
								>
									Celular
								</label>
								<input
									type="tel"
									name="celular"
									id="celular"
									defaultValue={cliente.celular}
									onChange={updateData}
									autoComplete="celular"
									className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								/>
							</div>

							<div className="col-span-6 sm:col-span-3">
								<label
									htmlFor="fecha_nacimiento"
									className="block text-sm font-medium text-gray-700"
								>
									Fecha de nacimiento (AAAA-MM-DD)*
								</label>
								<DatePicker
									dateFormat="yyyy-MM-dd"
									name="fecha_nacimiento"
									locale="es"
									showMonthDropdown
									showYearDropdown
									popperPlacement="bottom"
									scrollableYearDropdown
									selected={
										data?.fecha_nacimiento
											? new Date(moment(data?.fecha_nacimiento))
											: new Date(moment(fechaNac))
									}
									onChange={(fecha_nacimiento) => updateFecha(fecha_nacimiento)}
									className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								/>
							</div>

							<div className="col-span-6 sm:col-span-3">
								<label
									htmlFor="fuentes"
									className="block text-sm font-medium text-gray-700"
								>
									Fuente *
								</label>
								<select
									name="fuentes"
									className="block w-full mx-1 mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
									onChange={updateData}
									required
								>
									{[
										"Familia",
										"Amigos",
										"Conocidos laborales",
										"Conocidos estudios",
										"Recomendados",
										"Fuentes frías",
									].map((item) => {
										return cliente.fuentes === item ? (
											<option key={item} value={item} selected>
												{item}
											</option>
										) : (
											<option key={item} value={item}>
												{item}
											</option>
										);
									})}
								</select>
							</div>

							<div className="col-span-6 sm:col-span-3">
								<label
									htmlFor="fuma"
									className="block text-sm font-medium text-gray-700"
								>
									Fuma *
								</label>
								<select
									name="fuma"
									className="block w-full mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
									onChange={updateData}
									defaultValue="n"
								>
									{["s", "n"].map((item) => {
										return cliente.fuma === item ? (
											<option key={item} value={item} selected>
												{item === "n" ? "No" : "Si"}
											</option>
										) : (
											<option key={item} value={item}>
												{item === "n" ? "No" : "Si"}
											</option>
										);
									})}
								</select>
							</div>
						</div>
					</div>
				</div>
				<div className="flex flex-row justify-around w-full h-full mt-6">
					<button
						className="px-3 py-1 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
						onClick={() => setIrEditar(false)}
					>
						Cancelar
					</button>
					<button
						type="submit"
						className="px-3 py-1 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
					>
						Guardar
					</button>
				</div>
			</form>
		</div>
	);
};

export default EditarCliente;
