import React, { useState, useEffect, useRef } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

import NumberFormat from "react-number-format";
import {
	gastoPorTipoLista,
	conceptosPrincipalesFindOrCreate,
	obtenerDatosTablaSecundaria,
	actualizarSumatorias,
} from "../../../functions/InfoFinancieraQueries";
import { calculate_age } from "../../../functions/DependantsQueries";
import {
	obtenerDatosDependientes,
	activosSuma,
	gastosMensualesSuma,
	ingresosMensualesSuma,
	segurosSuma,
} from "../../../functions/NecesidadesQueries";
import {
	showClient,
	countClientDependants,
} from "../../../functions/ClientsQueries";
import { bgColor, borderColor } from "../../../functions/constants";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

export default function Grafica({ cliente_id, responsive }) {
	const [gastos, setGastos] = useState([]);
	const [cliente, setCliente] = useState({});
	const [datosActivos, setDatosActivos] = useState([]);
	const [datoIngresosMensualesSuma, setDatoIngresosMensualesSuma] = useState(
		[]
	);
	const [datosSeguros, setDatosSeguros] = useState([]);
	const [gastosActivos, setGastosActivos] = useState();

	const [arregloConceptos, setArregloConceptos] = useState();
	const [valoresConceptos, setValoresConceptos] = useState();
	const [bgColores, setBgColores] = useState();
	const [arregloConceptos2, setArregloConceptos2] = useState();
	const [valoresConceptos2, setValoresConceptos2] = useState();
	const [bgColores2, setBgColores2] = useState();
	const [mostrarDatosCompletos, setMostrarDatosCompletos] = useState(false);

	const [arregloActivos, setArregloActivos] = useState();
	const [valoresActivos, setValoresActivos] = useState();
	const [tablaSecundaria, setTablaSecundaria] = useState([]);
	const [cuantosDependientes, setCuantosDependientes] = useState(0);
	const [datosConyuge, setDatosConyuge] = useState("");

	const [goResponsive, setGoResponsive] = useState();

	const titleRef = useRef(null);

	let arrConceptos = [];
	let montoConceptos = [];
	let arrColores = [];
	let arrConceptos2 = [];
	let montoConceptos2 = [];
	let arrColores2 = [];
	let arrActivos = [];
	let montoActivos = [];
	let arregloConceptosFinal;
	let valoresConceptosFinal;
	let bgColoresFinal;
	let pre_percentage;
	let percentage;

	const scrollToTitle = () => {
		setTimeout(() => {
			titleRef.current?.scrollIntoView({ behavior: "smooth" });
		}, 1500);
	};

	useEffect(() => {
		actualizarSumatorias(cliente_id, console.log);
		showClient(cliente_id, setCliente);
		countClientDependants(cliente_id, setCuantosDependientes);
		obtenerDatosDependientes(cliente_id, "Conyuge", setDatosConyuge);
		conceptosPrincipalesFindOrCreate(cliente_id);
		activosSuma(cliente_id, setDatosActivos);
		gastosMensualesSuma(cliente_id, setGastosActivos);
		ingresosMensualesSuma(cliente_id, setDatoIngresosMensualesSuma);
		segurosSuma(cliente_id, setDatosSeguros);
		obtenerDatosTablaSecundaria(
			cliente_id,
			"activos",
			"activos",
			setTablaSecundaria
		);
		gastoPorTipoLista(cliente_id, "gasto", setGastos);
		arreglo_conceptos();
		scrollToTitle();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		arreglo_conceptos();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gastos]);

	useEffect(() => {
		arrelgo_activos();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tablaSecundaria]);

	useEffect(() => {
		if (
			typeof responsive === "undefined" ||
			responsive === null ||
			responsive.length === 0
		) {
			setGoResponsive(true);
		} else {
			setGoResponsive(responsive);
		}
	}, [responsive]);

	const arreglo_conceptos = () => {
		setTimeout(() => {
			if (
				typeof gastos !== "undefined" ||
				gastos !== null ||
				gastos.length !== 0
			) {
				// Arreglo completo
				let i = 1;
				gastos.forEach((element) => {
					arrConceptos[i] = element.concepto;
					montoConceptos[i] = element.monto.toFixed(2);
					arrColores[i] = bgColor[element.color];
					i++;
				});
				// Arreglo solo con los que tienen monto
				i = 1;
				gastos.forEach((element) => {
					if (element.monto > 0) {
						arrConceptos2[i] = element.concepto;
						montoConceptos2[i] = element.monto.toFixed(2);
						arrColores2[i] = bgColor[element.color];
					}
					i++;
				});
				// Set versión larga
				arrConceptos.shift();
				montoConceptos.shift();
				arrColores.shift();
				setArregloConceptos(arrConceptos);
				setValoresConceptos(montoConceptos);
				// setBgColores(arrColores);
				setBgColores(bgColor);

				// Set versión corta
				arrConceptos2 = arrConceptos2.filter(function () {
					return true;
				});
				montoConceptos2 = montoConceptos2.filter(function () {
					return true;
				});
				arrColores2 = arrColores2.filter(function () {
					return true;
				});

				setArregloConceptos2(arrConceptos2);
				setValoresConceptos2(montoConceptos2);
				// setBgColores2(arrColores2);
				setBgColores2(bgColor);
			}
		}, 500);
	};

	const arrelgo_activos = () => {
		if (
			typeof tablaSecundaria !== "undefined" ||
			tablaSecundaria !== null ||
			tablaSecundaria.length !== 0
		) {
			let i = 0;
			Object.values(tablaSecundaria).forEach((element) => {
				arrActivos[i] = element.concepto;
				montoActivos[i] = element.monto;
				i++;
			});
			setArregloActivos(arrActivos);
			setValoresActivos(montoActivos);
		}
	};

	if (mostrarDatosCompletos) {
		arregloConceptosFinal = arregloConceptos;
		valoresConceptosFinal = valoresConceptos;
		bgColoresFinal = bgColores;
	} else {
		arregloConceptosFinal = arregloConceptos2;
		valoresConceptosFinal = valoresConceptos2;
		bgColoresFinal = bgColores2;
	}

	const data = {
		labels: arregloConceptosFinal,
		datasets: [
			{
				label: arregloConceptosFinal,
				data: valoresConceptosFinal,
				backgroundColor: bgColoresFinal,
				borderColor: borderColor,
				borderWidth: 1,
			},
		],
	};

	const options = {
		plugins: {
			legend: {
				position: "bottom",
			},
			datalabels: {
				formatter: (valoresConceptosFinal, ctx) => {
					let datasets = ctx.chart.data.datasets;
					if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
						pre_percentage = Math.round(
							(valoresConceptosFinal / gastosActivos) * 100,
							2
						);
						percentage = pre_percentage > 0 ? pre_percentage + "%" : "";
						return percentage;
					} else {
						return percentage;
					}
				},
				color: "#1f2937", // Gray 800
				font: {
					weight: "bold",
					size: 14,
				},
				align: "end",
			},
		},
	};

	const TablaActivos = ({ tablaSecundaria, arregloActivos, valoresActivos, datosActivos }) => {
		return (
			<table className="w-10/12 border divide-y divide-gray-200 shadow-xl md:w-3/4 lg:w-11/12">
				<thead className="bg-gray-50">
					<tr>
						<th
							scope="col"
							className="py-1 font-bold tracking-wider text-center text-gray-700 uppercase"
							colSpan={2}
						>
							Sus activos son
						</th>
					</tr>
				</thead>
				<tbody>
					{typeof tablaSecundaria === "undefined" ||
						tablaSecundaria === null ||
						tablaSecundaria.length === 0 ||
						typeof arregloActivos === "undefined" ||
						arregloActivos === null ||
						arregloActivos.length === 0 ||
						typeof valoresActivos === "undefined" ||
						valoresActivos === null ||
						valoresActivos.length === 0 ? null : (
						<>
							{Object.entries(tablaSecundaria).map((item, index) =>
								valoresActivos[index] > 0 ? (
									<tr
										key={index}
										className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}
									>
										<td className="px-6 py-1 text-sm font-medium text-gray-900 whitespace-nowrap">
											{arregloActivos[index]}
										</td>
										<td className="px-6 py-1 text-sm text-right text-gray-500 whitespace-nowrap">
											<NumberFormat
												thousandSeparator={true}
												displayType={"text"}
												prefix={"$ "}
												value={valoresActivos[index]}
											/>
										</td>
									</tr>
								) : null
							)}
						</>
					)}
					{typeof datosActivos === "undefined" ||
						datosActivos === null ||
						datosActivos.length === 0 ? null : (
						<>
							<tr>
								<td className="px-6 py-2 font-bold text-gray-900 whitespace-nowrap">
									TOTAL
								</td>
								<td className="px-6 py-2 font-bold text-right text-gray-500 whitespace-nowrap">
									<NumberFormat
										thousandSeparator={true}
										displayType={"text"}
										prefix={"$ "}
										value={datosActivos}
									/>
								</td>
							</tr>
						</>
					)}
				</tbody>
			</table>
		)
	}

	return (
		<div className="px-4 pb-3 mx-4 bg-white shadow sm:rounded-lg sm:p-6">
			<div className="text-sm font-bold text-center sm:text-2xl">
				<div ref={titleRef} />
				Situación actual
			</div>
			<div
				className={
					goResponsive === true
						? "grid grid-cols-1 gap-4 lg:grid-cols-2"
						: "grid gap-4 grid-cols-2"
				}
			>
				<div className="flex flex-col items-center mt-6">

					{
						typeof datosActivos === "undefined" ||
							datosActivos === null ||
							datosActivos.length === 0 ||
							datosActivos <= 0
							? null :
							<TablaActivos
								tablaSecundaria={tablaSecundaria}
								arregloActivos={arregloActivos}
								valoresActivos={valoresActivos}
								datosActivos={datosActivos}
							/>
					}

					<br />
					<table className="w-10/12 border divide-y divide-gray-200 shadow-xl md:w-3/4 lg:w-11/12">
						<thead className="bg-gray-50">
							<tr>
								<th
									scope="col"
									className="py-1 font-bold tracking-wider text-center text-gray-700 uppercase"
									colSpan={2}
								>
									Sus gastos mensuales son
								</th>
							</tr>
						</thead>
						<tbody>
							{typeof arregloConceptosFinal === "undefined" ||
								arregloConceptosFinal === null ||
								arregloConceptosFinal.length === 0
								? null
								: arregloConceptosFinal.map((item, ind) => {
									return (
										<tr
											key={ind}
											className={ind % 2 === 0 ? "bg-white" : "bg-gray-100"}
										>
											<td
												className={`px-3 pt-2 text-sm font-medium whitespace-nowrap text-gray-900`}
											>
												{ind === 0 ? (
													<button
														className={`mx-2 mb-1 py-3 px-3 text-blue-600 font-medium  bg-blue-600 rounded-2xl`}
													>
														{" "}
													</button>
												) : ind === 1 ? (
													<button
														className={`mx-2 mb-1 py-3 px-3 text-cyan-300 font-medium  bg-cyan-300 rounded-2xl`}
													>
														{" "}
													</button>
												) : ind === 2 ? (
													<button
														className={`mx-2 mb-1 py-3 px-3 text-sky-600 font-medium  bg-sky-600 rounded-2xl`}
													>
														{" "}
													</button>
												) : ind === 3 ? (
													<button
														className={`mx-2 mb-1 py-3 px-3 text-red-700 font-medium  bg-red-700 rounded-2xl`}
													>
														{" "}
													</button>
												) : ind === 4 ? (
													<button
														className={`mx-2 mb-1 py-3 px-3 text-lime-600 font-medium  bg-lime-600 rounded-2xl`}
													>
														{" "}
													</button>
												) : ind === 5 ? (
													<button
														className={`mx-2 mb-1 py-3 px-3 text-amber-500 font-medium  bg-amber-500 rounded-2xl`}
													>
														{" "}
													</button>
												) : ind === 6 ? (
													<button
														className={`mx-2 mb-1 py-3 px-3 text-green-600 font-medium  bg-green-600 rounded-2xl`}
													>
														{" "}
													</button>
												) : ind === 7 ? (
													<button
														className={`mx-2 mb-1 py-3 px-3 text-fuchsia-500 font-medium  bg-fuchsia-500 rounded-2xl`}
													>
														{" "}
													</button>
												) : ind === 8 ? (
													<button
														className={`mx-2 mb-1 py-3 px-3 text-orange-600 font-medium  bg-orange-600 rounded-2xl`}
													>
														{" "}
													</button>
												) : null}
												{item}
											</td>
											<td className="px-6 py-2 text-sm text-right text-gray-500 whitespace-nowrap">
												<NumberFormat
													thousandSeparator={true}
													displayType={"text"}
													prefix={"$ "}
													value={valoresConceptosFinal[ind]}
												/>
											</td>
										</tr>
									);
								})}
							{typeof gastosActivos === "undefined" ||
								gastosActivos === null ||
								gastosActivos.length === 0 ? null : (
								<>
									<tr key={"total"}>
										<td className="px-6 py-2 font-bold text-gray-900 whitespace-nowrap">
											TOTAL
										</td>
										<td className="px-6 py-2 font-bold text-right text-gray-500 whitespace-nowrap">
											<NumberFormat
												thousandSeparator={true}
												displayType={"text"}
												prefix={"$ "}
												value={gastosActivos}
											/>
										</td>
									</tr>
								</>
							)}
						</tbody>
					</table>
				</div>
				<div className="flex flex-col items-center mt-6">
					<div className="text-center">
						Su edad actual es:{" "}
						<span className="font-bold">
							{" "}
							{calculate_age(cliente.fecha_nacimiento)} años
						</span>{" "}
						y
						<span className="font-bold">
							{cliente.fuma === "s" ? " si" : " no"} fuma
						</span>
						.
						<br />
						{datosConyuge.length ? (
							<>
								Su cónyuge es:{" "}
								<span className="font-bold"> {datosConyuge[0].nombre}</span> de
								<span className="font-bold">
									{" "}
									{calculate_age(datosConyuge[0].fecha_nacimiento)}
								</span>
								<br />
							</>
						) : null}
						Tiene <span className="font-bold">{cuantosDependientes} </span>
						dependientes
					</div>
					<div className="flex justify-center w-9/12 mt-3 mb-5">
						{typeof arregloConceptos === "undefined" ||
							arregloConceptos === null ||
							arregloConceptos.length === 0 ||
							typeof valoresConceptos === "undefined" ||
							valoresConceptos === null ||
							valoresConceptos.length === 0 ? null : (
							<>
								<Pie data={data} options={options} />
							</>
						)}
					</div>
					<table className="w-10/12 border divide-y divide-gray-200 shadow-xl md:w-3/4 lg:w-11/12">
						<tbody>
							<tr key={0} className={0 % 2 === 0 ? "bg-white" : "bg-gray-100"}>
								<td className="px-6 py-1 text-sm font-medium text-gray-900 whitespace-nowrap">
									El ingreso mensual familiar es de:
								</td>
								<td className="px-6 py-1 text-sm text-right text-gray-500 whitespace-nowrap">
									{typeof datoIngresosMensualesSuma === "undefined" ||
										datoIngresosMensualesSuma === null ||
										datoIngresosMensualesSuma.length === 0 ? (
										<NumberFormat
											thousandSeparator={true}
											displayType={"text"}
											prefix={"$ "}
											value={0}
										/>
									) : (
										<NumberFormat
											thousandSeparator={true}
											displayType={"text"}
											prefix={"$ "}
											value={datoIngresosMensualesSuma}
										/>
									)}
								</td>
							</tr>
							<tr key={1} className={1 % 2 === 0 ? "bg-white" : "bg-gray-100"}>
								<td className="px-6 py-1 text-sm font-medium text-gray-900 whitespace-nowrap">
									Sus gastos fijos mensuales son:
								</td>

								<td className="px-6 py-1 text-sm text-right text-gray-500 whitespace-nowrap">
									{typeof gastosActivos === "undefined" ||
										gastosActivos === null ||
										gastosActivos.length === 0 ? (
										<>
											<NumberFormat
												thousandSeparator={true}
												displayType={"text"}
												prefix={"$ "}
												value={0}
											/>
										</>
									) : (
										<>
											<NumberFormat
												thousandSeparator={true}
												displayType={"text"}
												prefix={"$ "}
												value={gastosActivos}
											/>
										</>
									)}
								</td>
							</tr>
							<tr key={2} className={2 % 2 === 0 ? "bg-white" : "bg-gray-100"}>
								<td className="px-6 py-1 font-bold text-gray-900 whitespace-nowrap">
									Su capacidad de ahorro mensual es:
								</td>
								<td className="px-6 py-1 font-bold text-right text-gray-900 whitespace-nowrap">
									{typeof gastosActivos === "undefined" ||
										gastosActivos === null ||
										gastosActivos.length === 0 ||
										typeof datoIngresosMensualesSuma === "undefined" ||
										datoIngresosMensualesSuma === null ||
										datoIngresosMensualesSuma.length === 0 ? (
										<>
											<NumberFormat
												thousandSeparator={true}
												displayType={"text"}
												prefix={"$ "}
												value={0}
											/>
										</>
									) : (
										<>
											<NumberFormat
												thousandSeparator={true}
												displayType={"text"}
												prefix={"$ "}
												value={datoIngresosMensualesSuma - gastosActivos}
											/>
										</>
									)}
								</td>
							</tr>
							<tr key={3} className={3 % 2 === 0 ? "bg-white" : "bg-gray-100"}>
								<td className="px-6 py-1 text-sm font-medium text-gray-900 whitespace-nowrap">
									Tiene seguros por:
								</td>
								<td className="px-6 py-1 text-sm text-right text-gray-500 whitespace-nowrap">
									{typeof datosSeguros === "undefined" ||
										datosSeguros === null ||
										datosSeguros.length === 0 ? (
										<>
											<NumberFormat
												thousandSeparator={true}
												displayType={"text"}
												prefix={"$ "}
												value={0}
											/>
										</>
									) : (
										<>
											<NumberFormat
												thousandSeparator={true}
												displayType={"text"}
												prefix={"$ "}
												value={datosSeguros}
											/>
										</>
									)}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div className="text-center">
				{/* <button
					className="p-2 mt-8 mb-4 bg-gray-300 rounded"
					onClick={() => setMostrarDatosCompletos(!mostrarDatosCompletos)}
				>
					{mostrarDatosCompletos
						? "Mostrar versión corta"
						: "Mostrar versión larga"}
				</button> */}
			</div>
		</div>
	);
}
