import { Link } from "react-router-dom";

import {
	arrDescargas,
	arrGraficas,
	arrInformacionFinancieraPermisos,
	arrNecesidades,
	arrSoluciones,
} from "../functions/constants";
import { hasAny } from "../functions/Genericas";

export const ClientButtonsBar = ({ userPermissions, cliente }) => {
	return (
		<div className="flex flex-col justify-center pb-12 mt-6 md:justify-around md:flex-row">
			{hasAny(userPermissions, arrInformacionFinancieraPermisos) ? (
				<Link
					to={`/InfoFinanciera/${cliente.id}`}
					className="px-3 py-1 mx-3 mb-2 text-center text-gray-100 bg-gray-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
				>
					<button data-tooltip-target="tooltip-default">
						Información financiera
					</button>
				</Link>
			) : null}

			{hasAny(userPermissions, arrNecesidades) ? (
				<Link
					to={`/Necesidades/${cliente.id}`}
					className="px-3 py-1 mx-3 mb-2 text-center text-gray-100 bg-gray-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
				>
					<button data-tooltip-target="tooltip-default">Necesidades</button>
				</Link>
			) : null}

			{hasAny(userPermissions, arrGraficas) ? (
				<Link
					to={`/Graficas/${cliente.id}`}
					className="px-3 py-1 mx-3 mb-2 text-center text-gray-100 bg-gray-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
				>
					<button data-tooltip-target="tooltip-default">Gráficas</button>
				</Link>
			) : null}

			{hasAny(userPermissions, arrSoluciones) ? (
				<Link
					to={`/Soluciones/${cliente.id}`}
					className="px-3 py-1 mx-3 mb-2 text-center text-gray-100 bg-gray-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
				>
					<button data-tooltip-target="tooltip-default">Soluciones</button>
				</Link>
			) : null}

			{hasAny(userPermissions, arrDescargas) ? (
				userPermissions.includes(61) ? (
					<Link
						to={`/Descargas/${cliente.id}`}
						className="px-3 py-1 mx-3 mb-2 text-center text-gray-100 bg-gray-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
					>
						<button data-tooltip-target="tooltip-default">Descargas</button>
					</Link>
				) : null
			) : null}
		</div>
	);
};
