import React from "react";
import gph1 from "../assets/images/graph1.png";

const Results = () => {
  const table1 = [
    {
      name: "Bancos",
      title: "200,000",
    },
    {
      name: "Acciones",
      title: "200,000",
    },
    {
      name: "Inversiones",
      title: "100,000",
    },
    {
      name: "Inmuebles",
      title: "4,000,000",
    },
    // More people...
  ];
  const table2 = [
    {
      name: "Vivienda",
      title: "19,300",
    },
    {
      name: "Transporte",
      title: "6,800",
    },
    {
      name: "Alimentación",
      title: "14,000",
    },
    {
      name: "Salud y belleza",
      title: "5,700",
    },
    {
      name: "Entretenimiento",
      title: "6,000",
    },
    {
      name: "Compras",
      title: "3,000",
    },
    // More people...
  ];
  return (
    <div className="h-screen p-3 bg-gray-100">
      <div className="bg-white rounded-2xl">
        <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:px-8 lg:py-24">
          <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
            <div className="space-y-5 sm:space-y-4">
              <div className="text-sm font-bold sm:text-2xl">
                Situación Actual
              </div>

              <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                            >
                              Activos
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                            >
                              Valor
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {table1.map((person, personIdx) => (
                            <tr
                              key={person.name}
                              className={
                                personIdx % 2 === 0 ? "bg-white" : "bg-gray-100"
                              }
                            >
                              <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">
                                {person.name}
                              </td>
                              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                                {person.title}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="space-y-5 sm:space-y-4">
              <div className="text-sm font-bold sm:text-2xl">
                Gastos mensuales
              </div>

              <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                            >
                              Activos
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                            >
                              Valor
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {table2.map((person, personIdx) => (
                            <tr
                              key={person.name}
                              className={
                                personIdx % 2 === 0 ? "bg-white" : "bg-gray-100"
                              }
                            >
                              <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">
                                {person.name}
                              </td>
                              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                                {person.title}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="space-y-5 sm:space-y-4">
              <div className="text-sm font-bold sm:text-2xl">Gráfica</div>
              <img src={gph1} alt="graph1" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Results;
