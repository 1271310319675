import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import Grafica1 from "./cards/graficas/Grafica1";
import Grafica2 from "./cards/graficas/Grafica2";
import Grafica3 from "./cards/graficas/Grafica3";
import Grafica4 from "./cards/graficas/Grafica4";
import Grafica5 from "./cards/graficas/Grafica5";
import Grafica6 from "./cards/graficas/Grafica6";
import Grafica7 from "./cards/graficas/Grafica7";
import Grafica8 from "./cards/graficas/Grafica8";
import Grafica9 from "./cards/graficas/Grafica9";
import { LlenadoTablaDatos } from "../functions/Datos";
import {
	arregloPadresFindOrCreate,
	arregloProyectos10MasFindOrCreate,
	retiroFindOrCreate,
} from "../functions/NecesidadesQueries";
import { conceptosPrincipalesFindOrCreate } from "../functions/InfoFinancieraQueries";
import { getUserPermissions } from "../functions/AuthQueries";
import Spinner from "../Spinner";

const Graficas = () => {
	let params = useParams();

	const cliente_id = params.cliente_id;
	const [userPermissions, setUserPermissions] = useState([])

	const [isLoading, setIsLoading] = useState(true);
	const [hasError, setHasError] = useState(false);
	const { loading, error } = LlenadoTablaDatos(cliente_id);
	useEffect(() => {
		setIsLoading(loading);
		setHasError(error);
	}, [loading, error]);

	useEffect(() => {
		const user_id = JSON.parse(localStorage.getItem("user_id"));
		getUserPermissions(user_id, setUserPermissions)
	}, [])

	const [seccionActiva, setSeccionActiva] = useState(1);
	const buttonSeccion = (num) => {
		if (seccionActiva === num) {
			setSeccionActiva("");
		} else {
			setSeccionActiva(num);
		}
	};

	useEffect(() => {
		arregloPadresFindOrCreate(cliente_id, console.log);
		arregloProyectos10MasFindOrCreate(cliente_id, console.log);
		retiroFindOrCreate(cliente_id, console.log);
		conceptosPrincipalesFindOrCreate(cliente_id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="pb-3 bg-gray-100 ">
			<h3 className="pb-2 text-sm font-bold text-center sm:text-2xl">
				Gráficas
			</h3>

			<div className="bg-gray-100">
				{isLoading ?
					(
						<>
							<Spinner />
							<div className="flex flex-col justify-around mt-3 md:flex-row">
								Cargado ...
							</div>
						</>
					) :
					<>
						<div className="flex flex-col justify-around mt-3 md:flex-row">

							{userPermissions.includes(41) ? (
								<button
									className={
										seccionActiva === 1
											? "btn-gray-blue-active"
											: "btn-gray-blue"
									}
									onClick={() => buttonSeccion(1)}
								>
									Situación actual
								</button>
							) : null}

							{userPermissions.includes(42) ? (
								<button
									className={
										seccionActiva === 2
											? "btn-gray-blue-active"
											: "btn-gray-blue"
									}
									onClick={() => buttonSeccion(2)}
								>
									Situación actual de vida
								</button>
							) : null}

							{userPermissions.includes(43) ? (
								<button
									className={
										seccionActiva === 3
											? "btn-gray-blue-active"
											: "btn-gray-blue"
									}
									onClick={() => buttonSeccion(3)}
								>
									Necesidades por año
								</button>
							) : null}

							{userPermissions.includes(44) ? (
								<button
									className={
										seccionActiva === 4
											? "btn-gray-blue-active"
											: "btn-gray-blue"
									}
									onClick={() => buttonSeccion(4)}
								>
									Necesidades acumuladas
								</button>
							) : null}

							{userPermissions.includes(45) ? (
								<button
									className={
										seccionActiva === 5
											? "btn-gray-blue-active"
											: "btn-gray-blue"
									}
									onClick={() => buttonSeccion(5)}
								>
									Necesidades previstas
								</button>
							) : null}

						</div>

						<div className="flex flex-col justify-around mt-3 md:flex-row">
							{userPermissions.includes(46) ? (
								<button
									className={
										seccionActiva === 6
											? "btn-gray-blue-active"
											: "btn-gray-blue"
									}
									onClick={() => buttonSeccion(6)}
								>
									Protección educativa
								</button>
							) : null}

							{userPermissions.includes(47) ? (
								<button
									className={
										seccionActiva === 7
											? "btn-gray-blue-active"
											: "btn-gray-blue"
									}
									onClick={() => buttonSeccion(7)}
								>
									Fondos universitarios
								</button>
							) : null}

							{userPermissions.includes(48) ? (
								<button
									className={
										seccionActiva === 8
											? "btn-gray-blue-active"
											: "btn-gray-blue"
									}
									onClick={() => buttonSeccion(8)}
								>
									Planeación retiro
								</button>
							) : null}

							{userPermissions.includes(49) ? (
								<button
									className={
										seccionActiva === 9
											? "btn-gray-blue-active"
											: "btn-gray-blue"
									}
									onClick={() => buttonSeccion(9)}
								>
									Resumen
								</button>
							) : null}

							<Link
								to={`/client/${cliente_id}`}
								className="btn-dark-gray-blue"
							>
								<button>Regresar</button>
							</Link>{" "}
						</div>
						<div className="flex flex-row justify-around mt-3"></div>
						<br />
						{seccionActiva === 1 ? <Grafica1 cliente_id={cliente_id} /> : null}
						{seccionActiva === 2 ? <Grafica2 cliente_id={cliente_id} /> : null}
						{seccionActiva === 3 ? <Grafica3 cliente_id={cliente_id} /> : null}
						{seccionActiva === 4 ? <Grafica4 cliente_id={cliente_id} /> : null}
						{seccionActiva === 5 ? <Grafica5 cliente_id={cliente_id} /> : null}
						{seccionActiva === 6 ? <Grafica6 cliente_id={cliente_id} /> : null}
						{seccionActiva === 7 ? <Grafica7 cliente_id={cliente_id} /> : null}
						{seccionActiva === 8 ? <Grafica8 cliente_id={cliente_id} /> : null}
						{seccionActiva === 9 ? <Grafica9 cliente_id={cliente_id} /> : null}
					</>
				}
				{hasError ?
					<div className="flex flex-col justify-around mt-3 md:flex-row">Ha ocurrido un error</div>
					: null}
			</div>
		</div>
	);
};

export default Graficas;
