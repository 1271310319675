import React from "react";

const UsuarioDetalles = ({ user, permissions, userPermissions }) => {
	return (
		<div>
			<table className="min-w-full border divide-y divide-gray-200 rounded-lg ">
				<tbody>
					<tr
						key={1}
						className={
							1 % 2 === 0
								? "bg-white px-6 py-3 text-s font-medium"
								: "bg-gray-50 px-6 py-3 text-s font-medium"
						}
					>
						<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
							Nombre
						</td>
						<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
							{user?.name}
						</td>
					</tr>
					<tr
						key={2}
						className={
							2 % 2 === 0
								? "bg-white px-6 py-3 text-s font-medium"
								: "bg-gray-50 px-6 py-3 text-s font-medium"
						}
					>
						<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
							Nombre de usuario
						</td>
						<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
							{user?.username}
						</td>
					</tr>
					<tr
						key={3}
						className={
							3 % 2 === 0
								? "bg-white px-6 py-3 text-s font-medium"
								: "bg-gray-50 px-6 py-3 text-s font-medium"
						}
					>
						<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
							Email
						</td>
						<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
							{user?.email}
						</td>
					</tr>
					<tr
						key={4}
						className={
							4 % 2 === 0
								? "bg-white px-6 py-3 text-s font-medium"
								: "bg-gray-50 px-6 py-3 text-s font-medium"
						}
					>
						<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
							Status (1 activo / 0 inactivo)
						</td>
						<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
							{user?.activo}
						</td>
					</tr>
					<tr
						key={5}
						className={
							5 % 2 === 0
								? "bg-white px-6 py-3 text-s font-medium"
								: "bg-gray-50 px-6 py-3 text-s font-medium"
						}
					>
						<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
							Rol
						</td>
						<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
							{user?.role}
						</td>
					</tr>
					<tr
						key={55}
						className={
							5 % 2 === 0
								? "bg-white px-6 py-3 text-s font-medium"
								: "bg-gray-50 px-6 py-3 text-s font-medium"
						}
					>
						<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
							Unidad
						</td>
						<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
							{user?.unidad}
						</td>
					</tr>
					<tr
						key={6}
						className={
							6 % 2 === 0
								? "bg-white px-6 py-3 text-s font-medium"
								: "bg-gray-50 px-6 py-3 text-s font-medium"
						}
					>
						<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
							Promotoría
						</td>
						<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
							{user?.promotoria}
						</td>
					</tr>
					<tr
						key={71}
						className={
							71 % 2 === 0
								? "bg-white px-6 py-3 text-s font-medium"
								: "bg-gray-50 px-6 py-3 text-s font-medium"
						}
					>
						<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
							Líder
						</td>
						<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
							{user?.lider}
						</td>
					</tr>
					<tr
						key={72}
						className={
							72 % 2 === 0
								? "bg-white px-6 py-3 text-s font-medium"
								: "bg-gray-50 px-6 py-3 text-s font-medium"
						}
					>
						<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
							Promotor
						</td>
						<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
							{user?.promotor}
						</td>
					</tr>
					<tr
						key={7}
						className={
							7 % 2 === 0
								? "bg-white px-6 py-3 text-s font-medium"
								: "bg-gray-50 px-6 py-3 text-s font-medium"
						}
					>
						<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
							Ciudad
						</td>
						<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
							{user?.ciudad}
						</td>
					</tr>
					<tr
						key={8}
						className={
							8 % 2 === 0
								? "bg-white px-6 py-3 text-s font-medium"
								: "bg-gray-50 px-6 py-3 text-s font-medium"
						}
					>
						<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
							Generación
						</td>
						<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
							{user?.generacion}
						</td>
					</tr>
					<tr
						key={9}
						className={
							9 % 2 === 0
								? "bg-white px-6 py-3 text-s font-medium"
								: "bg-gray-50 px-6 py-3 text-s font-medium"
						}
					>
						<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
							Tipo de origen
						</td>
						<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
							{user?.tipo_origen_agente}
						</td>
					</tr>
					<tr
						key={10}
						className={
							9 % 2 === 0
								? "bg-white px-6 py-3 text-s font-medium"
								: "bg-gray-50 px-6 py-3 text-s font-medium"
						}
					>
						<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
							Logo
						</td>
						<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
							{user?.logo}
						</td>
					</tr>
				</tbody>
			</table>

			<br />

			<div className="py-4 text-center text-md">
				Permisos del usuario
			</div>

			<table className="min-w-full border divide-y divide-gray-200 rounded-lg ">
				<thead>
					<tr>
						<th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-center text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
							Tipo
						</th>
						<th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-center text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
							Permiso
						</th>
					</tr>
				</thead>
				<tbody>
					{permissions.map((permission, index) => (
						<tr
							key={index}
							className={
								index % 2 === 0
									? "bg-white px-6 py-3 text-s font-medium"
									: "bg-gray-50 px-6 py-3 text-s font-medium"
							}
						>
							<td className="px-6 py-2 text-sm text-left text-gray-600 md:table-cell">
								{permission.id} - {permission.permiso}
							</td>
							<td className="px-6 py-2 text-sm text-center text-gray-600 md:table-cell">
								{userPermissions.includes(permission.id) ? "Si" : ""}
							</td>
						</tr>
					)
					)}
				</tbody>
			</table>

		</div>
	);
};

export default UsuarioDetalles;
