import React from "react";

const PoliticaPrivacidad = () => {
	return (
		<div className="h-screen py-3 bg-gray-100">
			<div className="p-3 bg-gray-100">
				<div className="flex flex-col px-8">
					<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
								<div className="py-2 text-2xl text-center">
									Política de privacidad
								</div>

								<div className="pb-4 text-lg text-center">
									Lorem ipsum dolor sit amet consectetur adipisicing elit. Et
									repudiandae magnam consequuntur perferendis libero recusandae
									iste esse error vel quam repellendus iure animi facilis,
									dolores dignissimos doloremque illo nostrum fugit!
								</div>
								<div className="pb-4 text-lg text-center">
									Lorem ipsum dolor sit amet consectetur adipisicing elit. Et
									repudiandae magnam consequuntur perferendis libero recusandae
									iste esse error vel quam repellendus iure animi facilis,
									dolores dignissimos doloremque illo nostrum fugit!
								</div>
								<div className="pb-4 text-lg text-center">
									Lorem ipsum dolor sit amet consectetur adipisicing elit. Et
									repudiandae magnam consequuntur perferendis libero recusandae
									iste esse error vel quam repellendus iure animi facilis,
									dolores dignissimos doloremque illo nostrum fugit!
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PoliticaPrivacidad;
