import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getAllPermissions, getUserDetails, activarDesactivarUser } from "../functions/UsuariosQueries";

import UsuarioEditar from "./UsuarioEditar";
import UsuarioDetalles from "./UsuarioDetalles";
import UsuarioNuevo from "./UsuarioNuevo";
import { getUserPermissions } from "../functions/AuthQueries";

const Usuario = () => {
	let params = useParams();
	const id = params.id;
	const [user, setUser] = useState();
	const [editarUsuario, setEditarUsuario] = useState(false);
	const [nuevoUsuario, setNuevoUsuario] = useState(false);
	const [recargarUsuario, setRecargarUsuario] = useState(false);
	const [permissions, setPermissions] = useState([]);
	const [userPermissions, setUserPermissions] = useState('')

	const setActivarDesactivarUser = id => {
		activarDesactivarUser(id, setUser);
		setTimeout(() => {
			getUserDetails(id, setUser);
		}, 1000);
	}

	useEffect(() => {
		if (id === "0") {
			setNuevoUsuario(true);
		} else {
			getUserDetails(id, setUser);
		}
	}, [id]);

	useEffect(() => {
		getUserDetails(id, setUser);
		getUserPermissions(id, setUserPermissions)
		setRecargarUsuario(false);
	}, [id, recargarUsuario]);

	useEffect(() => {
		getAllPermissions(setPermissions)
	}, [])

	useEffect(() => {
		getUserPermissions(id, setUserPermissions)
	}, [id])

	return (
		<div className="px-4 py-5 mx-4 bg-white shadow sm:rounded-lg sm:p-6">
			<div className="pb-4 text-lg font-medium leading-6 text-center text-gray-900">
				<div className="py-4 text-center text-large">
					{editarUsuario ? "Editar " : "Detalle del "} usuario {user?.name}
				</div>
				{editarUsuario ? (
					<>
						<UsuarioEditar
							user={user}
							setUser={setUser}
							setEditarUsuario={setEditarUsuario}
							setRecargarUsuario={setRecargarUsuario}
							permissions={permissions}
							userPermissions={userPermissions}
						/>
					</>
				) : nuevoUsuario ? (
					<UsuarioNuevo />
				) : (
					<>
						<UsuarioDetalles user={user} permissions={permissions} userPermissions={userPermissions} />
						<div className="pt-4">
							<button
								className="px-3 py-1 mx-3 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
								onClick={() => setEditarUsuario(!editarUsuario)}
							>
								Editar
							</button>
							<button
								className="px-3 py-1 mx-3 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
								onClick={() => {
									if (
										window.confirm(
											"Esta seguro?"
										)
									) setActivarDesactivarUser(user?.id)
								}}
							>
								{user?.activo === "1" ? "Desactivar" : "Activar"}
							</button>
							<Link to={`/CambiarPassword/${user?.id}`}>
								<button
									className="px-3 py-1 mx-3 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
								>
									Cambiar contraseña
								</button>
							</Link>
							<Link to="/Usuarios">
								<button className="px-3 py-1 mx-3 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-xl text-light">
									Listado de usuarios
								</button>
							</Link>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default Usuario;
