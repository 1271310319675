import { useState, useEffect } from 'react';

const useAxios = (configObj) => {

	const { axiosInstance, method, url, requestConfig = {} } = configObj;

	const [response, setResponse] = useState([])
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');

	useEffect(() => {
		const controller = new AbortController();

		const fetchData = async () => {
			try {
				const res = await axiosInstance[method.toLowerCase()](url, {
					...requestConfig,
					signal: controller.signal
				});
				setResponse(res.data);
			} catch (error) {
				console.log('error', error.message);
				setError(error.message);
			} finally {
				setLoading(false);
				controller.abort();
			}
		}
		fetchData();

		// useEffect cleanup function
		// If the component unmounts, cancel the request
		return () => controller.abort();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return { response, loading, error };
}

export default useAxios