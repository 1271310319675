import React, { useState, useEffect } from "react";

import { validateUser, logout, checkIfLogged } from "../functions/AuthQueries";
import Spinner from "../Spinner";

const Login = ({ logged, setLogged }) => {
	const [username, setUsername] = useState("");
	const [passwd, setPasswd] = useState("");
	const [error, setError] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");
	const [consultado, setConsultado] = useState(false);
	const [loading, setLoading] = useState(false);

	let actualizaUsername = (e) => {
		setUsername(e.target.value);
	};
	let actualizaPwd = (e) => {
		setPasswd(e.target.value);
	};

	const submitForm = (e) => {
		e.preventDefault();
		if (username.trim() === "") {
			setError(true);
			setErrorMsg("El nombre de usuario es obligatorio");
			return;
		}
		if (passwd.trim() === "") {
			setError(true);
			setErrorMsg("La contraseña es obligatoria");
			return;
		}
		setError(false);
		setErrorMsg("");
		setConsultado(true);
		setLoading(true);
		validateUser(
			username,
			passwd,
			setLogged,
			setError,
			setErrorMsg,
			setConsultado,
			setLoading
		);
	};

	const salir = () => {
		logout(setLogged);
	};

	useEffect(() => {
		checkIfLogged(setLogged);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let logoHeader = "Zeus";
	let logoImage = require("../assets/images/Zeus-F.png");
	useEffect(() => {
		const logo = JSON.parse(localStorage.getItem("logo"));
		if (logo === "Zeus Full") {
			logoHeader = "Zeus";
			logoImage = require("../assets/images/Zeus-F.png");
		} else if (logo === "Zeus Retiro") {
			logoHeader = "Zeus-R";
			logoImage = require("../assets/images/Zeus-R.png");
		} else if (logo === "Zeus Short") {
			logoHeader = "Zeus-S";
			logoImage = require("../assets/images/Zeus-S.png");
		}
	}, [])
	return (
		<div className="bg-gray-100">
			<div className="flex flex-col items-center">
				<div className="mb-16 bg-gray-300 shadow-2xl w-full-min md:w-1/2 mt-28 rounded-3xl">
					<br />
					<div className="flex flex-col items-center justify-items-center">
						<div className="text-3xl text-gray-700">{logoHeader}</div>
					</div>
					<div className="mt-6 mb-10 text-center">
						{loading ? <Spinner /> : null}
						<form onSubmit={submitForm}>
							<div className="mt-6 mb-2 ml-12 text-left">
								<span>Nombre de usuario</span>
							</div>
							<input
								type="text"
								name="username"
								id="username"
								onChange={actualizaUsername}
								className="w-5/6 p-2 border-white rounded-xl hover:border-blue-400 hover:duration-500"
							/>
							<div className="mt-6 mb-2 ml-12 text-left">
								<span>Contraseña</span>
							</div>
							<input
								type="password"
								name="password"
								id="password"
								onChange={actualizaPwd}
								className="w-5/6 p-2 border-white rounded-xl hover:border-blue-400 hover:duration-500"
							/>
							<div className="my-10">
								{!consultado ? (
									<button
										type="submit"
										className="px-3 py-1 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
									>
										Ingresar
									</button>
								) : (
									<button
										type="submit"
										className="px-6 py-2 text-gray-100 bg-gray-400 shadow-lg rounded-2xl text-light"
										disabled
									>
										Espere ...
									</button>
								)}
							</div>
							{error && (
								<div className="m-4 font-light text-center bg-red-500 rounded-xl">
									{errorMsg}
								</div>
							)}
						</form>
					</div>
				</div>
				{logged && (
					<button
						type="submit"
						className="px-3 py-1 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
						onClick={() => salir()}
					>
						logout
					</button>
				)}
			</div>
		</div>
	);
};

export default Login;
