import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import CurrencyInput from "react-currency-input-field";

import {
	gastoPorTipo,
	gastoPorTipoLista,
	conceptosPrincipalesFindOrCreate,
	obtenerDatosTablaSecundaria,
	actualizarValoresTabla,
	actualizarSumatorias,
} from "../../functions/InfoFinancieraQueries";

const GastosFijosMensuales = ({ cliente_id }) => {
	const [gastosFijos, setGastosFijos] = useState([]);
	const [gastos, setGastos] = useState("");
	const [tablaSecundaria, setTablaSecundaria] = useState([]);
	const [mostrarTablaSecundaria, setMostrarTablaSecundaria] = useState(false);
	const [tituloTablaSecundaria, setTituloTablaSecundaria] = useState("");
	const [sumaActualizada, setSumaActualizada] = useState(false);

	const crearTablaSecundaria = (tipo, subtipo, concepto) => {
		setMostrarTablaSecundaria(false);
		actualizarSumatorias(cliente_id, setSumaActualizada);
		obtenerDatosTablaSecundaria(cliente_id, tipo, subtipo, setTablaSecundaria);
		setTituloTablaSecundaria(`Detalle de ${concepto}`);
		setTimeout(() => {
			setMostrarTablaSecundaria(!mostrarTablaSecundaria);
		}, 500);
	};
	const cerrarTablaSecundaria = () => {
		actualizarSumatorias(cliente_id, setSumaActualizada);
		setMostrarTablaSecundaria(false);
	};

	const actualizarState = (e) => {
		let val = e.target.value.replace(/\D+/g, "");
		actualizarValoresTabla(e.target.name, val);
	};

	useEffect(() => {
		conceptosPrincipalesFindOrCreate(cliente_id);
		gastoPorTipo("pasivos", setGastosFijos);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		gastoPorTipoLista(cliente_id, "pasivos", setGastos);
		setSumaActualizada(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gastosFijos, sumaActualizada]);

	const handleFocus = (e) => e.target.select();

	return (
		<div className="flex flex-row justify-around mb-10">
			<div className="mx-4 space-y-2 sm:space-y-4">
				<div className="flex flex-col md:flex-row">
					<div className="space-y-2 sm:space-y-4">
						<div className="text-sm font-bold text-center sm:text-xl">
							Pasivos
						</div>

						<table className="min-w-full divide-y divide-gray-200">
							<thead className="bg-gray-50">
								<tr>
									<th
										scope="col"
										className="px-6 py-2 tracking-wider text-center text-gray-700 uppercase font-small md:font-medium"
									>
										Concepto
									</th>
									<th
										scope="col"
										className="px-6 py-2 tracking-wider text-center text-gray-700 uppercase font-small md:font-medium"
									>
										Valor
									</th>
									<th
										scope="col"
										className="px-6 py-2 tracking-wider text-center text-gray-700 uppercase font-small md:font-medium"
									>
										Detalles
									</th>
								</tr>
							</thead>
							<tbody>
								{typeof gastos === "undefined" ||
									gastos === null ||
									gastos.length === 0 ? null : (
									<>
										{gastos.map((item, index) => (
											<tr
												key={index}
												className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}
											>
												<td className="h-12 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
													{item.concepto}
												</td>
												<td className="h-12 px-6 text-sm text-right text-gray-500 whitespace-nowrap">
													<NumberFormat
														thousandSeparator={true}
														displayType={"text"}
														prefix={"$ "}
														value={item.monto}
													/>
												</td>
												<td className="h-12 px-6 text-sm text-center text-gray-500 whitespace-nowrap">
													<button
														className="px-3 py-1 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-2xl text-light"
														onClick={() => {
															crearTablaSecundaria(
																item.tipo,
																item.subtipo,
																item.concepto
															);
														}}
													>
														Detalles
													</button>
												</td>
											</tr>
										))}
									</>
								)}
							</tbody>
						</table>
					</div>

					{mostrarTablaSecundaria ? (
						<div>
							<div className="flex flex-row justify-around mb-10">
								<div className="mx-4 space-y-2 sm:space-y-4">
									<div className="font-bold text-center text-medium ">
										{tituloTablaSecundaria}
									</div>
									<div className="flex flex-col">
										<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
											<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
												<div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
													<table className="min-w-full divide-y divide-gray-200">
														<thead className="bg-gray-50">
															<tr>
																<th
																	scope="col"
																	className="px-6 py-2 tracking-wider text-center text-gray-700 uppercase font-small md:font-medium"
																>
																	Concepto
																</th>
																<th
																	scope="col"
																	className="px-6 py-2 tracking-wider text-center text-gray-700 uppercase font-small md:font-medium"
																>
																	Valor
																</th>
															</tr>
														</thead>
														<tbody>
															{typeof tablaSecundaria === "undefined" ||
																tablaSecundaria === null ||
																tablaSecundaria.length === 0 ? null : (
																<>
																	{tablaSecundaria.map((item, index) => (
																		<tr
																			key={index}
																			className={
																				index % 2 === 0
																					? "bg-white"
																					: "bg-gray-100"
																			}
																		>
																			<td className="h-12 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
																				{item.concepto}
																			</td>
																			<td className="h-12 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
																				<CurrencyInput
																					id="item.subtipo"
																					name={item.id}
																					defaultValue={item.monto}
																					className="block w-full text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																					onFocus={handleFocus}
																					onChange={actualizarState}
																					decimalsLimit={2}
																					required
																				/>
																			</td>
																		</tr>
																	))}
																</>
															)}
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="min-w-full text-sm font-bold text-center sm:text-xl">
								<p className="text-sm font-light text-center">
									Los datos se guardan cuando de click al botón de cerrar.
								</p>
								<button
									className="px-4 py-1 mt-3 text-sm text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-2xl text-light"
									onClick={() => {
										cerrarTablaSecundaria();
									}}
								>
									Cerrar
								</button>
							</div>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default GastosFijosMensuales;
