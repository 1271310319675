import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { checkIfLogged } from "./functions/AuthQueries";

import Why from "./components/Why";
import Docs from "./components/Docs";
import Home from "./components/Home";
import Login from "./components/Login";
import Empty from "./components/Empty";
import Client from "./components/Client";
import Usuario from "./components/Usuario";
import Clients from "./components/Clients";
import Results from "./components/Results";
import Graficas from "./components/Graficas";
import Usuarios from "./components/Usuarios";
import Descargas from "./components/Descargas";
import Soluciones from "./components/Soluciones";
import Necesidades from "./components/Necesidades";
import InfoFinanciera from "./components/InfoFinanciera";
import Dependientes from "./components/cards/Dependientes";
import NuevoCliente from "./components/cards/NuevoCliente";
import PoliticaPrivacidad from "./components/PoliticaPrivacidad";
import Reporting from "./components/Reporting";
import ReporteLider from "./components/reporting/ReporteLider";
import ReporteDirectivo from "./components/reporting/ReporteDirectivo";
import CambiarPassword from "./components/CambiarPassword";

// Detect information about the browser
import { browserName, browserVersion, osName, osVersion, deviceType, deviceDetect } from 'react-device-detect';

import LogRocket from 'logrocket';
LogRocket.init('dh0kfb/zeus');
LogRocket.identify(JSON.parse(localStorage.getItem("user_id")), {
	name: JSON.parse(localStorage.getItem("name")),
	appName: 'Zeus',
	browserName,
	browserVersion,
	osName,
	osVersion,
	deviceType,
	deviceDetect
});

function App() {
	const [logged, setLogged] = useState(false);
	// checkIfLogged();
	useEffect(() => {
		checkIfLogged(setLogged);
	}, []);
	useEffect(() => {
		checkIfLogged(setLogged);
	}, [logged]);

	return (
		<BrowserRouter>
			<Routes>
				<Route
					path="/"
					element={<Home setLogged={setLogged} logged={logged} />}
				>
					{!logged ? (
						<>
							<Route
								path="/"
								element={<Login logged={logged} setLogged={setLogged} />}
							/>
							<Route
								path="*"
								element={<Login logged={logged} setLogged={setLogged} />}
							/>
						</>
					) : (
						<>
							<Route exact path="/" element={<Navigate to="Why" />} />
							<Route exact path="clients" element={<Clients />} />
							<Route
								exact
								path="politicaPrivacidad"
								element={<PoliticaPrivacidad />}
							/>
							<Route exact path="client" element={<Client />}>
								<Route path=":id" element={<Client />} />
							</Route>
							<Route exact path="Dependientes" element={<Dependientes />}>
								<Route
									path=":cliente_id/:dependiente_id"
									element={<Dependientes />}
								/>
							</Route>
							<Route exact path="results" element={<Results />} />
							<Route exact path="NuevoCliente" element={<NuevoCliente />} />
							<Route exact path="InfoFinanciera" element={<InfoFinanciera />}>
								<Route path=":cliente_id" element={<InfoFinanciera />} />
							</Route>
							<Route exact path="Soluciones" element={<Soluciones />}>
								<Route path=":cliente_id" element={<Soluciones />} />
							</Route>
							<Route exact path="Descargas" element={<Descargas />}>
								<Route path=":cliente_id" element={<Descargas />} />
							</Route>
							<Route exact path="Necesidades" element={<Necesidades />}>
								<Route path=":cliente_id" element={<Necesidades />} />
							</Route>
							<Route exact path="Graficas" element={<Graficas />}>
								<Route path=":cliente_id" element={<Graficas />} />
							</Route>
							<Route exact path="docs" element={<Docs />} />
							<Route exact path="Why" element={<Why />} />
							<Route exact path="Reporting" element={<Reporting />} />
							<Route exact path="ReporteLider" element={<ReporteLider />} />
							<Route
								exact
								path="ReporteDirectivo"
								element={<ReporteDirectivo />}
							/>
							<Route exact path="Usuarios" element={<Usuarios />} />
							<Route exact path="Usuario" element={<Usuario />}>
								<Route path=":id" element={<Usuario />} />
							</Route>
							<Route exact path="CambiarPassword" element={<CambiarPassword />}>
								<Route path=":id" element={<CambiarPassword />} />
							</Route>
						</>
					)}
					{logged ? <Route path="*" element={<Empty />} /> : null}
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
