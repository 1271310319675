import { Link } from "react-router-dom";

export const ButtonListado = () => {
	return (
		<div className="flex flex-row justify-around w-full h-full mt-6 bg-gray-100">
			<Link to="/clients">
				<button className="px-3 py-1 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-xl text-light">
					Listado
				</button>
			</Link>
		</div>
	);
};
