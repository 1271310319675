import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";

const Home = ({ setLogged, logged }) => {
	const name = JSON.parse(localStorage.getItem("name"));
	return (
		<div className="h-screen py-3 bg-gray-100">
			{logged ? (
				<>
					<Navbar setLogged={setLogged} />
					<div className="text-right text-gray-800 mr-7">
						{name ? `Usuario: ${name}` : null}
					</div>
				</>
			) : null}
			<Outlet />
		</div>
	);
};

export default Home;
