import React, { useState, useEffect, useRef } from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
// import { Bar } from "react-chartjs-2";
import {
	obtenerDatos,
	obtenerUltimoDato,
	obtenerTodosDatosMensuales,
	obtenerDatoMensual,
	obtenerPrimerDato,
	obtenerEdadIniciaRetiro,
	obtenerFondoPrevistoRetiroAcumulado,
	obtenerFondoNoPrevistoRetiro,
} from "../../../functions/Datos";
import NumberFormat from "react-number-format";
import { obtenerSumaProyectos10Mas } from "../../../functions/NecesidadesQueries";
// =+Tabla!AS75-'Datos Generales'!F74*12*'Datos Generales'!F75
// =+SI(Y(D6<='Datos Generales'!$E$22,D6>='Datos Generales'!$F$68),'Datos Generales'!$F$73*12,0)

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

export default function Grafica({ cliente_id }) {
	const [datos, setDatos] = useState();
	const [ultimoDato, setUltimoDato] = useState([]);
	const [primerDato, setPrimerDato] = useState([]);
	const [datosMensuales, setDatosMensuales] = useState({});
	const [seguroEducativo, setSeguroEducativo] = useState();
	const [seguroVida, setSeguroVida] = useState();
	const [sumaProyectos, setSumaProyectos] = useState(0);
	const [procentajePreservar, setProcentajePreservar] = useState(0);
	const [edadInicioRetiro, setEdadInicioRetiro] = useState();
	const [fondoPrevistoRetiroAcumulado, setFondoPrevistoRetiroAcumulado] = useState(0);
	const [fondoNoPrevistoRetiro, setFondoNoPrevistoRetiro] = useState(0);
	const titleRef = useRef(null);

	// const p1 = 1 - (6000000 / primerDato[0]?.gasto_fijo_anual_descendende) * 100;
	// setProcentajePreservar(p1);
	let index = 0;
	let gasto_fijo = [];
	let educacion = [];
	let apoyo_padres = [];
	let anos = [];

	if (datos !== undefined) {
		datos.forEach((element) => {
			gasto_fijo.push(element.gasto_fijo_anual_descendende);
			educacion.push(element.educacion);
			apoyo_padres.push(element.apoyo_padres);
			anos.push(element.ano);
		});
	}

	const scrollToTitle = () => {
		setTimeout(() => {
			titleRef.current?.scrollIntoView({ behavior: "smooth" });
		}, 2000);
	};

	useEffect(() => {
		obtenerDatos(cliente_id, setDatos);
		obtenerUltimoDato(cliente_id, setUltimoDato);
		obtenerPrimerDato(cliente_id, setPrimerDato);
		obtenerTodosDatosMensuales(cliente_id, setDatosMensuales);
		obtenerEdadIniciaRetiro(cliente_id, setEdadInicioRetiro);
		obtenerDatoMensual(
			"0",
			"seguros",
			"seguros",
			"Seguro Educativo",
			cliente_id,
			setSeguroEducativo
		);
		obtenerDatoMensual(
			"0",
			"seguros",
			"seguros",
			"Seguro de Vida",
			cliente_id,
			setSeguroVida
		);
		obtenerSumaProyectos10Mas(cliente_id, setSumaProyectos);
		// setTimeout(() => {
		setProcentajePreservar(
			(seguroVida / primerDato[0]?.gasto_fijo_anual_descendende) * 100
		);
		obtenerFondoPrevistoRetiroAcumulado(cliente_id, setFondoPrevistoRetiroAcumulado)
		obtenerFondoNoPrevistoRetiro(cliente_id, setFondoNoPrevistoRetiro)
		scrollToTitle();
		// }, 0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let necesario1 = primerDato[0]?.gasto_fijo_anual_descendende;
	let previsto1 = seguroVida;
	let porcentaje1 =
		necesario1 && previsto1 ? ((previsto1 / necesario1) * 100).toFixed(2) : 0;

	let necesario2 = primerDato[0]?.fondo_no_previsto_educacion;
	let previsto2 = primerDato[0]?.fondo_previsto_educacion;
	let porcentaje2 =
		necesario2 && previsto2 ? ((previsto2 / necesario2) * 100).toFixed(2) : 0;

	let necesario3 = ultimoDato[0]?.apoyo_padres_acumulado;
	let previsto3 = 0;
	let porcentaje3 =
		necesario3 && previsto3 ? ((previsto3 / necesario3) * 100).toFixed(2) : 0;

	let necesario4 = ultimoDato[0]?.educacion_universitaria_acumulada;
	let previsto4 = primerDato[0]?.fondo_previsto_educacion;
	let porcentaje4 =
		necesario4 && previsto4 ? ((previsto4 / necesario4) * 100).toFixed(2) : 0;

	let necesario5 = 0; //sumaProyectos;
	let previsto5 = 0;
	let porcentaje5 =
		necesario5 && previsto5 ? ((previsto5 / necesario5) * 100).toFixed(2) : 0;

	let necesario6 = fondoNoPrevistoRetiro + fondoPrevistoRetiroAcumulado || 0; //Planea tu retiro
	let previsto6 = fondoPrevistoRetiroAcumulado || 0; //Planea tu retiro
	let porcentaje6 =
		necesario6 && previsto6 ? ((previsto6 / necesario6) * 100).toFixed(2) : 0;

	// console.log('datos', datos)
	// console.log('edadInicioRetiro', edadInicioRetiro)
	// console.log('fondoPrevistoRetiroAcumulado', fondoPrevistoRetiroAcumulado)
	// console.log('fondoNoPrevistoRetiro', fondoNoPrevistoRetiro)

	return (
		<div className="px-4 py-5 mx-4 bg-white shadow sm:rounded-lg sm:p-6">
			<div className="text-sm font-bold text-center sm:text-2xl">
				<div ref={titleRef} />
				Resumen
			</div>
			<div className="overflow-hidden">
				<div className="flex flex-col">
					<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<div className="flex flex-col items-center mt-6">
								{necesario1 ||
									previsto1 ||
									necesario2 ||
									previsto2 ||
									necesario3 ||
									previsto3 ? (
									<>
										<table className="min-w-[90%] border divide-y divide-gray-200 shadow-xl">
											<thead className="bg-gray-50">
												<tr
													key={index}
													className={
														index++ % 2 === 0 ? "bg-white" : "bg-gray-100"
													}
												>
													<th
														scope="col"
														colSpan={4}
														className="px-6 py-3 text-sm font-bold tracking-wider text-center text-gray-700 uppercase"
													>
														Necesidades de protección
													</th>
												</tr>
												<tr>
													<td
														className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-900 text-center w-1/2`}
													>
														Concepto
													</td>
													<td
														className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-900 text-center w-1/6`}
													>
														Necesario
													</td>
													<td
														className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-900 text-center w-1/6`}
													>
														Previsto
													</td>
													<td
														className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-900 text-center w-1/6`}
													>
														%
													</td>
												</tr>
											</thead>
											<tbody>
												{necesario1 || previsto1 ? (
													<tr
														key={index}
													// className={
													// 	index++ % 2 === 0 ? "bg-white" : "bg-gray-100"
													// }
													>
														<td
															className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-900 w-1/2`}
														>
															Garantizar el gasto fijo de tu familia
														</td>
														<td
															className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-900 text-center w-1/6`}
														>
															<NumberFormat
																thousandSeparator={true}
																displayType={"text"}
																prefix={"$ "}
																value={necesario1 || 0}
															/>
														</td>
														<td
															className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-900 text-center w-1/6`}
														>
															<NumberFormat
																thousandSeparator={true}
																displayType={"text"}
																prefix={"$ "}
																value={previsto1 || 0}
															/>
														</td>
														<td
															className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-900 text-center w-1/6`}
														>
															<NumberFormat
																thousandSeparator={true}
																displayType={"text"}
																value={porcentaje1 || 0}
															/>
														</td>
													</tr>
												) : null}
												{necesario2 || previsto2 ? (
													<tr
														key={index}
														className={
															index++ % 2 === 0 ? "bg-white" : "bg-gray-100"
														}
													>
														<td
															className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-900 w-1/2`}
														>
															Proteger la educación pendiente de tus hijos
														</td>
														<td
															className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-900 text-center w-1/6`}
														>
															<NumberFormat
																thousandSeparator={true}
																displayType={"text"}
																prefix={"$ "}
																value={necesario2 || 0}
															/>
														</td>
														<td
															className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-900 text-center w-1/6`}
														>
															<NumberFormat
																thousandSeparator={true}
																displayType={"text"}
																prefix={"$ "}
																value={previsto2 || 0}
															/>
														</td>
														<td
															className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-900 text-center w-1/6`}
														>
															<NumberFormat
																thousandSeparator={true}
																displayType={"text"}
																value={porcentaje2 || 0}
															/>
														</td>
													</tr>
												) : null}
												{necesario3 || previsto3 ? (
													<>
														<tr
															key={index}
															className={
																index++ % 2 === 0 ? "bg-white" : "bg-gray-100"
															}
														>
															<td
																className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-900 w-1/2`}
															>
																Resolver el apoyo a tus padres
															</td>
															<td
																className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-900 text-center w-1/6`}
															>
																<NumberFormat
																	thousandSeparator={true}
																	displayType={"text"}
																	prefix={"$ "}
																	value={necesario3 || 0}
																/>
															</td>
															<td
																className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-900 text-center w-1/6`}
															>
																<NumberFormat
																	thousandSeparator={true}
																	displayType={"text"}
																	prefix={"$ "}
																	value={previsto3 || 0}
																/>
															</td>
															<td
																className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-900 text-center w-1/6`}
															>
																<NumberFormat
																	thousandSeparator={true}
																	displayType={"text"}
																	value={porcentaje3 || 0}
																/>
															</td>
														</tr>
													</>
												) : null}
												<tr
													key={index}
													className={
														index++ % 2 === 0 ? "bg-white" : "bg-gray-100"
													}
												>
													<td
														className={`px-6 pt-3 text-sm font-bold whitespace-nowrap text-gray-900 w-1/2`}
													>
														TOTAL
													</td>
													<td
														className={`px-6 pt-3 text-sm font-bold whitespace-nowrap text-gray-900 text-center w-1/6`}
													>
														{necesario1 || necesario2 || necesario3 ? (
															<NumberFormat
																thousandSeparator={true}
																displayType={"text"}
																prefix={"$ "}
																value={necesario1 + necesario2 + necesario3}
															/>
														) : null}
													</td>
													<td
														className={`px-6 pt-3 text-sm font-bold whitespace-nowrap text-gray-900 text-center w-1/6`}
													>
														{previsto1 || previsto2 || previsto3 ? (
															<NumberFormat
																thousandSeparator={true}
																displayType={"text"}
																prefix={"$ "}
																value={previsto1 + previsto2 + previsto3}
															/>
														) : null}
													</td>
													<td
														className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-900 text-center w-1/6`}
													></td>
												</tr>
											</tbody>
										</table>
										<br />
									</>
								) : null}

								{necesario4 ||
									previsto4 ||
									necesario5 ||
									previsto5 ||
									necesario6 ||
									previsto6 ? (
									<>
										<table className="min-w-[90%] border divide-y divide-gray-200 shadow-xl">
											<thead className="bg-gray-50">
												<tr
													key={index}
													className={
														index++ % 2 === 0 ? "bg-white" : "bg-gray-100"
													}
												>
													<th
														scope="col"
														colSpan={4}
														className="px-6 py-3 text-sm font-bold tracking-wider text-center text-gray-700 uppercase"
													>
														Necesidades de ahorro
													</th>
												</tr>
												<tr>
													<td
														className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-900 text-center w-1/2`}
													>
														Concepto
													</td>
													<td
														className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-900 text-center w-1/6`}
													>
														Necesario
													</td>
													<td
														className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-900 text-center w-1/6`}
													>
														Previsto
													</td>
													<td
														className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-900 text-center w-1/6`}
													>
														%
													</td>
												</tr>
											</thead>
											<tbody>
												{necesario4 || previsto4 ? (
													<tr
														key={index}
														className={
															index++ % 2 === 0 ? "bg-white" : "bg-gray-100"
														}
													>
														<td
															className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-900 w-1/2`}
														>
															Fondos universitarios de tus hijos
														</td>
														<td
															className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-900 text-center w-1/6`}
														>
															<NumberFormat
																thousandSeparator={true}
																displayType={"text"}
																prefix={"$ "}
																value={necesario4 || 0}
															/>
														</td>
														<td
															className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-900 text-center w-1/6`}
														>
															<NumberFormat
																thousandSeparator={true}
																displayType={"text"}
																prefix={"$ "}
																value={previsto4 || 0}
															/>
														</td>
														<td
															className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-900 text-center w-1/6`}
														>
															<NumberFormat
																thousandSeparator={true}
																displayType={"text"}
																value={porcentaje4 || 0}
															/>
														</td>
													</tr>
												) : null}
												{necesario5 || previsto5 ? (
													<tr
														key={index}
														className={
															index++ % 2 === 0 ? "bg-white" : "bg-gray-100"
														}
													>
														<td
															className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-900 w-1/2`}
														>
															Tus Proyectos 10+
														</td>
														<td
															className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-900 text-center w-1/6`}
														>
															<NumberFormat
																thousandSeparator={true}
																displayType={"text"}
																prefix={"$ "}
																value={necesario5 || 0}
															/>
														</td>
														<td
															className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-900 text-center w-1/6`}
														>
															<NumberFormat
																thousandSeparator={true}
																displayType={"text"}
																prefix={"$ "}
																value={previsto5 || 0}
															/>
															{/* acá subir ahorro proiyectos 10 + de info financiera / activos */}
														</td>
														<td
															className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-900 text-center w-1/6`}
														>
															<NumberFormat
																thousandSeparator={true}
																displayType={"text"}
																value={porcentaje5 || 0}
															/>
														</td>
													</tr>
												) : null}
												{necesario6 || previsto6 ? (
													<tr
														key={index}
														className={
															index++ % 2 === 0 ? "bg-white" : "bg-gray-100"
														}
													>
														<td
															className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-900 w-1/2`}
														>
															Planea tu retiro
														</td>
														<td
															className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-90 text-center w-1/6`}
														>
															<NumberFormat
																thousandSeparator={true}
																displayType={"text"}
																prefix={"$ "}
																value={necesario6 || 0}
															/>
														</td>
														<td
															className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-900 text-center w-1/6`}
														>
															<NumberFormat
																thousandSeparator={true}
																displayType={"text"}
																prefix={"$ "}
																value={previsto6 || 0}
															/>
															{/* // este se trae de planeación para el retiro, son los de azul */}
														</td>
														<td
															className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-900 text-center w-1/6`}
														>
															<NumberFormat
																thousandSeparator={true}
																displayType={"text"}
																value={porcentaje6 || 0}
															/>
														</td>
													</tr>
												) : null}
												<tr
													key={index}
													className={
														index++ % 2 === 0 ? "bg-white" : "bg-gray-100"
													}
												>
													<td
														className={`px-6 pt-3 text-sm font-bold whitespace-nowrap text-gray-900 w-1/2`}
													>
														TOTAL
													</td>
													<td
														className={`px-6 pt-3 text-sm font-bold whitespace-nowrap text-gray-900 text-center w-1/6`}
													>
														{necesario4 || necesario5 || necesario6 ? (
															<NumberFormat
																thousandSeparator={true}
																displayType={"text"}
																prefix={"$ "}
																value={necesario4 + necesario5 + necesario6}
															/>
														) : null}
													</td>
													<td
														className={`px-6 pt-3 text-sm font-bold whitespace-nowrap text-gray-900 text-center w-1/6`}
													>
														{previsto4 || previsto5 || previsto6 ? (
															<NumberFormat
																thousandSeparator={true}
																displayType={"text"}
																prefix={"$ "}
																value={previsto4 + previsto5 + previsto6}
															/>
														) : null}
													</td>
													<td
														className={`px-6 pt-3 text-sm font-medium whitespace-nowrap text-gray-900 text-center w-1/6`}
													></td>
												</tr>
											</tbody>
										</table>
										<br />
									</>
								) : null}
								<br />
								<br />
								<br />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
