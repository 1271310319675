import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getUserPermissions, logout, validateRole } from "../functions/AuthQueries";
import { arrPermisos, warningHeader } from "../functions/constants";
import { hasAny } from "../functions/Genericas";

const Navbar = ({ setLogged }) => {
	const [role, setRole] = useState("");
	const handleLogout = () => {
		logout(setLogged);
	};

	const user_id = JSON.parse(localStorage.getItem("user_id"));
	const [userPermissions, setUserPermissions] = useState([])

	useEffect(() => {
		getUserPermissions(user_id, setUserPermissions)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		validateRole(user_id, setRole);
	}, [user_id]);

	const logo = JSON.parse(localStorage.getItem("logo"));
	let logoHeader = "Zeus";
	let logoImage = require("../assets/images/Zeus-F.png");
	if (logo === "Zeus Full") {
		logoHeader = "Zeus";
		logoImage = require("../assets/images/Zeus-F.png");
	} else if (logo === "Zeus Retiro") {
		logoHeader = "Zeus-R";
		logoImage = require("../assets/images/Zeus-R.png");
	} else if (logo === "Zeus Short") {
		logoHeader = "Zeus-S";
		logoImage = require("../assets/images/Zeus-S.png");
	}

	console.log(role)
	console.log(logo)

	return (
		// <!-- This example requires Tailwind CSS v2.0+ -->
		<nav className="m-auto mx-6 mb-4 bg-blue-400 shadow-inner rounded-2xl">
			<div className="px-2 mx-auto max-w-7xl sm:px-6 lg:px-8">
				<div className="relative flex justify-between h-16">
					<div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
						{/* <!-- Mobile menu button --> */}
						<button
							type="button"
							className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
							aria-controls="mobile-menu"
							aria-expanded="false"
						>
							<span className="sr-only">Open main menu</span>

							<svg
								className="block w-6 h-6"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M4 6h16M4 12h16M4 18h16"
								/>
							</svg>

							<svg
								className="hidden w-6 h-6"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
					<div className="flex items-center justify-center flex-1 sm:items-stretch sm:justify-start">
						<div className="flex items-center flex-shrink-0">
							<div className="block w-auto h-8 pr-4 text-xl font-bold underline lg:hidden ">
								<div className="flex flex-row justify-self-start">
									<img
										src={logoImage}
										alt="prisma"
										className="h-10"
									/>
								</div>
							</div>
							<div className="hidden w-auto h-8 pr-4 text-2xl font-bold lg:block">
								<div className="flex flex-row justify-self-start">
									<img
										src={logoImage}
										alt="prisma"
										className="mx-4 h-9"
									/>{" "}
									{logoHeader}
								</div>
							</div>
						</div>
						<div className="hidden sm:ml-6 sm:flex sm:space-x-8">
							<Link
								to="Why"
								className="inline-flex items-center px-1 pt-1 font-medium text-gray-900 border-b-2 border-indigo-500 hover:text-gray-700 hover:underline"
							>
								Why
							</Link>
							<Link
								to="/clients"
								className="inline-flex items-center px-1 pt-1 font-medium text-gray-900 border-b-2 border-indigo-500 hover:text-gray-700 hover:underline"
							>
								Clientes
							</Link>
							{userPermissions.includes(101) ? (
								<Link
									to="Usuarios"
									className="inline-flex items-center px-1 pt-1 font-medium text-gray-900 border-b-2 border-indigo-500 hover:text-gray-700 hover:underline"
								>
									Usuarios
								</Link>
							) : null}
							{hasAny(userPermissions, arrPermisos) ? (
								<Link
									to="Reporting"
									className="inline-flex items-center px-1 pt-1 font-medium text-gray-900 border-b-2 border-indigo-500 hover:text-gray-700 hover:underline"
								>
									Reportes
								</Link>
							) : null}
						</div>
					</div>
					<div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
						<div className="mr-4 text-2xl font-medium text-indigo-700 ">
							{warningHeader}
						</div>
						<button
							type="button"
							className="p-1 text-gray-400 bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
							onClick={handleLogout}
						>
							<span className="sr-only">Salir</span>
							<svg
								className="w-6 h-6"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
								/>
							</svg>
						</button>
						{/* <!-- Profile dropdown --> */}
						<div className="relative ml-3"></div>
					</div>
				</div>
			</div>

			{/* <!-- Mobile menu, show/hide based on menu state. --> */}
			<div className="sm:hidden" id="mobile-menu">
				<div className="pt-2 pb-4 space-y-1">
					<Link
						to="Why"
						className="inline-flex items-center px-1 pt-1 font-medium text-gray-900 border-b-2 border-indigo-500 hover:text-gray-700 hover:underline"
					>
						Why
					</Link>
					<Link
						to="/clients"
						className="inline-flex items-center px-1 pt-1 font-medium text-gray-900 border-b-2 border-indigo-500 hover:text-gray-700 hover:underline"
					>
						Clientes
					</Link>
					{userPermissions.includes(101) ? (
						<Link
							to="Usuarios"
							className="inline-flex items-center px-1 pt-1 font-medium text-gray-900 border-b-2 border-indigo-500 hover:text-gray-700 hover:underline"
						>
							Usuarios
						</Link>
					) : null}
					{hasAny(userPermissions, arrPermisos) ? (
						<Link
							to="Reporting"
							className="inline-flex items-center px-1 pt-1 font-medium text-gray-900 border-b-2 border-indigo-500 hover:text-gray-700 hover:underline"
						>
							Reportes
						</Link>
					) : null}
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
