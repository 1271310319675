import { baseurl } from "./constants";

/**
 * LOGIN AND SESSION
 */

export const validateToken = async (token, callback) => {
	let raw = JSON.stringify({
		token: `${token}`,
	});
	let myHeaders = new Headers();
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	let requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		cors: "no-cors",
	};

	const url = `${baseurl}/api/auth/validateToken`;
	await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON);
		})
		.catch((error) => {
			console.error(error);
		});
};

export const validateRole = async (user_id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/validateRole/${user_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON);
		})
		.catch((error) => console.error(error));
};

export const validateUser = async (
	username,
	passwd,
	setLogged,
	setError,
	setErrorMsg,
	setConsultado,
	setLoading
) => {
	let raw = JSON.stringify({
		username: `${username}`,
		password: `${passwd}`,
	});
	let myHeaders = new Headers();
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	let requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		cors: "no-cors",
	};

	const url = `${baseurl}/api/auth/login`;
	await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((result) => {
			setConsultado(false);
			if (result.token) {
				localStorage.setItem("jwt", JSON.stringify(result.token));
				localStorage.setItem("user_id", JSON.stringify(result.user_id));
				localStorage.setItem("name", JSON.stringify(result.name));
				localStorage.setItem("logo", JSON.stringify(result.logo));
				setLogged(true);
				setLoading(false);
			} else if (result.access_token) {
				localStorage.setItem("jwt", JSON.stringify(result.access_token));
				localStorage.setItem("user_id", JSON.stringify(result.user_id));
				localStorage.setItem("name", JSON.stringify(result.name));
				localStorage.setItem("logo", JSON.stringify(result.logo));
				setLogged(true);
				setLoading(false);
			} else {
				setLogged(false);
				setError(true);
				setErrorMsg("Nombre de usuario o contraseña equivocados");
				setLoading(false);
				return;
			}
		})
		.catch((error) => {
			console.error(error);
		});
};

export const logout = (setLogged) => {
	const user_id = JSON.parse(localStorage.getItem("user_id"));
	try {
		registerLogout(user_id);
		setLogged(false);
		localStorage.removeItem("jwt");
		localStorage.removeItem("user_id");
		localStorage.removeItem("name");
		window.location.href = "/";
	} catch (error) {
		console.error(error);
	}
};

export const checkIfLogged = (setLogged) => {
	const jwt = JSON.parse(localStorage.getItem("jwt"));
	if (typeof jwt === "undefined" || jwt === null) {
		setLogged(false);
		localStorage.removeItem("jwt");
		localStorage.removeItem("user_id");
		localStorage.removeItem("name");
	} else {
		setLogged(true);
	}
};

export const registerLogout = async (user_id) => {
	let raw = JSON.stringify({
		user_id: `${user_id}`,
	});
	let myHeaders = new Headers();
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	let requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/clientes/logoutRegister`;
	await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			console.log("");
		})
		.catch((error) => {
			console.error(error);
		});
};

export const getUserPermissions = async (user_id, callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/dsExpO9cDtL8s/${user_id}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON);
		})
		.catch((error) => console.error(error));
};
