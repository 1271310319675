import React, { useState, useEffect, useRef } from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {
	obtenerDatos,
	obtenerUltimoAnoFondosNoPrevistos,
} from "../../../functions/Datos";
import { bgColor } from "../../../functions/constants";
import { getClientDependants } from "../../../functions/DependantsQueries";
import { showClient } from "../../../functions/ClientsQueries";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

export default function Grafica({ cliente_id }) {
	const [datos, setDatos] = useState();
	const [ultimoAnoFondos, setUltimoAnoFondos] = useState();
	const [porcentaje, setPorcentaje] = useState(0);
	const [dependientes, setDependientes] = useState([]);
	const [cliente, setCliente] = useState({});
	const titleRef = useRef(null);

	let fondos_previstos = [];
	let fondos_no_cubiertos = [];
	let anos = [];
	let texto = [];
	let contador = 0;

	if (datos !== undefined && ultimoAnoFondos !== undefined) {

		// Ciclo nombres
		datos.forEach((element) => {
			if (element.ano <= ultimoAnoFondos) {
				fondos_previstos.push(element.fondos_previstos);
				fondos_no_cubiertos.push(element.fondos_no_cubiertos);
				if (contador === 0) {
					texto.push(`${cliente.nombre}`);
					dependientes?.forEach(e => {
						texto.push(`${e.nombre}`);
					});
					contador++;
				}
			}
		});

		// Ciclo años y edades
		let hijo_1 = datos[0].edad_hijo_1 ? datos[0].edad_hijo_1 : '';
		let hijo_2 = datos[0].edad_hijo_2 ? datos[0].edad_hijo_2 : '';
		let hijo_3 = datos[0].edad_hijo_3 ? datos[0].edad_hijo_3 : '';
		let hijo_4 = datos[0].edad_hijo_4 ? datos[0].edad_hijo_4 : '';
		datos.forEach((element) => {
			if (element.ano <= ultimoAnoFondos) {
				let arr = [element.ano];
				element.edad_titular && arr.push(`${element.edad_titular}`);
				element.edad_conyuge && arr.push(`${element.edad_conyuge}`);
				anos.push(arr);
				hijo_4 && arr.push(hijo_4++);
				hijo_3 && arr.push(hijo_3++);
				hijo_2 && arr.push(hijo_2++);
				hijo_1 && arr.push(hijo_1++);
			}
		});

	}

	const scrollToTitle = () => {
		setTimeout(() => {
			titleRef.current?.scrollIntoView({ behavior: "smooth" });
		}, 2000);
	};

	useEffect(() => {
		obtenerDatos(cliente_id, setDatos);
		obtenerUltimoAnoFondosNoPrevistos(cliente_id, setUltimoAnoFondos);
		getClientDependants(cliente_id, setDependientes);
		showClient(cliente_id, setCliente);
		scrollToTitle();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (typeof datos !== "undefined" && datos !== null && datos.length !== 0) {
			let a = datos[0]?.fondos_previstos;
			let b = datos[0]?.fondos_no_cubiertos;
			let porc = (100 - (a / (a + b)) * 100).toFixed(2);
			setPorcentaje(porc);
		}
	}, [datos]);

	const options = {
		plugins: {
			legend: {
				position: "bottom",
			},
			title: {
				display: false,
				text: "Stacked 3",
			},
			datalabels: {
				display: false,
			},
		},
		responsive: true,
		scales: {
			x: {
				stacked: true,
				grid: {
					display: false,
				},
				title: {
					display: true,
					text: texto,
				}
			},
			y: {
				stacked: true,
			},
		},
	};

	const labels = anos;

	const data = {
		labels,
		datasets: [
			{
				label: "Fondos previstos",
				data: fondos_previstos,
				backgroundColor: bgColor[0],
			},
			{
				label: "Fondos no cubiertos",
				data: fondos_no_cubiertos,
				backgroundColor: bgColor[10],
			},
		],
	};

	return (
		<div className="px-4 py-5 mx-4 bg-white shadow sm:rounded-lg sm:p-6">
			<div className="text-sm font-bold text-center sm:text-2xl">
				Necesidades Previstas vs No Previstas
				{porcentaje ? (
					<>
						<div className="mr-20 text-right">
							<button className="p-2 mt-4 -mb-2 text-lg font-bold bg-gray-300 border-2 border-red-500 rounded-xl sm:text-md">
								{porcentaje} % aún no previsto
							</button>
						</div>
					</>
				) : null}
			</div>
			<div className="overflow-hidden">
				<div className="flex flex-col">
					<div className="-my-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<div className="flex flex-col items-center mt-6">
								<div className="flex justify-center w-11/12 p-4 mb-8 rounded-lg shadow-xl">
									{typeof datos === "undefined" ||
										datos === null ||
										datos.length === 0 ? null : (
										<Bar data={data} options={options} />
									)}
								</div>
								<br />
							</div>
						</div>
					</div>
				</div>
				<div ref={titleRef} />
			</div>
		</div>
	);
}
