import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import GastosFijosMensuales from "./cards/GastosFijosMensuales";
import IngresosMensuales from "./cards/IngresosMensuales";
import Activos from "./cards/Activos";
import Pasivos from "./cards/Pasivos";
import SegurosVida from "./cards/SegurosVida";
import { getUserPermissions } from "../functions/AuthQueries";


const InfoFinanciera = () => {
	let params = useParams();

	const cliente_id = params.cliente_id;
	const [userPermissions, setUserPermissions] = useState([])

	const [seccionActiva, setSeccionActiva] = useState(1);
	const buttonSeccion = (num) => {
		if (seccionActiva === num) {
			setSeccionActiva("");
		} else {
			setSeccionActiva(num);
		}
	};

	useEffect(() => {
		const user_id = JSON.parse(localStorage.getItem("user_id"));
		getUserPermissions(user_id, setUserPermissions)
	}, [])

	console.log('userPermissions: ', userPermissions);
	console.log('cliente_id: ', cliente_id);
	console.log('seccionActiva: ', seccionActiva);

	return (
		<div className="p-3 bg-gray-100 ">
			<h3 className="mb-6 text-3xl text-center">
				Información financiera del Cliente
			</h3>

			<div className="flex flex-row justify-around mt-6">
				<Link to={`/client/${cliente_id}`}>
					<button className="px-3 py-1 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-xl text-light">
						Regresar
					</button>
				</Link>
			</div>

			<div className="bg-gray-100">
				<div className="flex flex-col justify-around mt-6 md:flex-row">

					{userPermissions.includes(1) ? (
						<button
							className={
								seccionActiva === 1
									? "px-3 py-1 mb-2 text-gray-100 bg-blue-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-gray-700 rounded-xl text-light"
									: "px-3 py-1 mb-2 text-gray-100 bg-gray-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
							}
							onClick={() => buttonSeccion(1)}
						>
							Gastos fijos mensuales
						</button>
					) : null}

					{userPermissions.includes(2) ? (
						<button
							className={
								seccionActiva === 2
									? "px-3 py-1 mb-2 text-gray-100 bg-blue-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-gray-700 rounded-xl text-light"
									: "px-3 py-1 mb-2 text-gray-100 bg-gray-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
							}
							onClick={() => buttonSeccion(2)}
						>
							Ingresos mensuales
						</button>
					) : null}


					{userPermissions.includes(3) ? (
						<button
							className={
								seccionActiva === 3
									? "px-3 py-1 mb-2 text-gray-100 bg-blue-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-gray-700 rounded-xl text-light"
									: "px-3 py-1 mb-2 text-gray-100 bg-gray-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
							}
							onClick={() => buttonSeccion(3)}
						>
							Activos
						</button>
					) : null}


					{userPermissions.includes(4) ? (
						<button
							className={
								seccionActiva === 4
									? "px-3 py-1 mb-2 text-gray-100 bg-blue-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-gray-700 rounded-xl text-light"
									: "px-3 py-1 mb-2 text-gray-100 bg-gray-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
							}
							onClick={() => buttonSeccion(4)}
						>
							Pasivos
						</button>
					) : null}


					{userPermissions.includes(5) ? (
						<button
							className={
								seccionActiva === 5
									? "px-3 py-1 mb-2 text-gray-100 bg-blue-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-gray-700 rounded-xl text-light"
									: "px-3 py-1 mb-2 text-gray-100 bg-gray-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
							}
							onClick={() => buttonSeccion(5)}
						>
							Seguros de vida
						</button>
					) : null}


				</div>
				<br />
				{seccionActiva === 1 ? (
					<GastosFijosMensuales cliente_id={cliente_id} />
				) : null}
				{seccionActiva === 2 ? (
					<IngresosMensuales cliente_id={cliente_id} />
				) : null}
				{seccionActiva === 3 ? <Activos cliente_id={cliente_id} /> : null}
				{seccionActiva === 4 ? <Pasivos cliente_id={cliente_id} /> : null}
				{seccionActiva === 5 ? <SegurosVida cliente_id={cliente_id} /> : null}
			</div>
		</div>
	);
};

export default InfoFinanciera;
