import React, { useState, useEffect, useRef } from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {
	obtenerDatos,
	obtenerUltimoAnoEducacionUniversitaria,
} from "../../../functions/Datos";
import { bgColor } from "../../../functions/constants";
import { getClientDependants } from "../../../functions/DependantsQueries";
import { showClient } from "../../../functions/ClientsQueries";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

export default function Grafica({ cliente_id }) {
	const [datos, setDatos] = useState();
	const [ultimoAno, setUltimoAno] = useState();
	const [dependientes, setDependientes] = useState([]);
	const [cliente, setCliente] = useState({});
	const titleRef = useRef(null);

	let educacion_universitaria = [];
	let educacion_universitaria_cubierta_seguro = [];
	let educacion_universitaria_no_cubierta_seguro = [];
	let anos = [];
	let texto = [];
	let contador = 0;

	if (datos !== undefined && ultimoAno !== undefined) {

		// Ciclo nombres
		datos.forEach((element) => {
			if (element.ano <= ultimoAno) {
				educacion_universitaria.push(element.educacion_universitaria);
				educacion_universitaria_cubierta_seguro.push(
					element.educacion_universitaria_cubierta_seguro
				);
				educacion_universitaria_no_cubierta_seguro.push(
					element.educacion_universitaria_no_cubierta_seguro
				);
				// anos.push(element.ano);
				if (contador === 0) {
					texto.push(`${cliente.nombre}`);
					dependientes?.forEach(e => {
						texto.push(`${e.nombre}`);
					});
					contador++;
				}
			}
		});

		console.log({ educacion_universitaria })
		console.log({ educacion_universitaria_cubierta_seguro })
		console.log({ educacion_universitaria_no_cubierta_seguro })

		// Ciclo años y edades
		let hijo_1 = datos[0].edad_hijo_1 ? datos[0].edad_hijo_1 : '';
		let hijo_2 = datos[0].edad_hijo_2 ? datos[0].edad_hijo_2 : '';
		let hijo_3 = datos[0].edad_hijo_3 ? datos[0].edad_hijo_3 : '';
		let hijo_4 = datos[0].edad_hijo_4 ? datos[0].edad_hijo_4 : '';
		datos.forEach((element) => {
			if (element.ano <= ultimoAno) {
				let arr = [element.ano];
				element.edad_titular && arr.push(`${element.edad_titular}`);
				element.edad_conyuge && arr.push(`${element.edad_conyuge}`);
				anos.push(arr);
				hijo_4 && arr.push(hijo_4++);
				hijo_3 && arr.push(hijo_3++);
				hijo_2 && arr.push(hijo_2++);
				hijo_1 && arr.push(hijo_1++);
			}
		});

	}

	const scrollToTitle = () => {
		setTimeout(() => {
			titleRef.current?.scrollIntoView({ behavior: "smooth" });
		}, 2000);
	};

	useEffect(() => {
		obtenerDatos(cliente_id, setDatos);
		obtenerUltimoAnoEducacionUniversitaria(cliente_id, setUltimoAno);
		getClientDependants(cliente_id, setDependientes);
		showClient(cliente_id, setCliente);
		scrollToTitle();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const options = {
		plugins: {
			legend: {
				position: "bottom",
			},
			title: {
				display: false,
				text: "Stacked 3",
			},
			datalabels: {
				display: false,
			},
		},
		responsive: true,
		scales: {
			x: {
				stacked: true,
				grid: {
					display: false,
				},
				title: {
					display: true,
					text: texto,
				}
			},
			y: {
				stacked: true,
			},
		},
	};

	const labels = anos;

	const data = {
		labels,
		datasets: [
			{
				label: "Fondos cubiertos",
				data: educacion_universitaria_cubierta_seguro,
				backgroundColor: bgColor[0],
			},
			{
				label: "Fondos no cubiertos",
				data: educacion_universitaria_no_cubierta_seguro,
				backgroundColor: bgColor[3],
			},
		],
	};

	return (
		<div className="px-4 py-5 mx-4 bg-white shadow sm:rounded-lg sm:p-6">
			<div className="overflow-hidden">
				<div className="flex flex-col">
					<div className="div2PDF">
						<div className="mb-8 text-sm font-bold text-center sm:text-2xl">
							Fondos de ahorro universitario
						</div>
						<Bar data={data} options={options} />
					</div>
				</div>
				<div ref={titleRef} />
			</div>
		</div>
	);
}
