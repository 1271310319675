import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getUserPermissions } from "../functions/AuthQueries";

import DatosGenerales from "./cards/DatosGenerales";
import EstiloVida from "./cards/EstiloVida";
import ApoyoPadres from "./cards/ApoyoPadres";
import Educacion from "./cards/Educacion";
import Proyectos10Mas from "./cards/Proyectos10Mas";
import Retiro from "./cards/Retiro";

const InfoFinanciera = () => {
	let params = useParams();

	const cliente_id = params.cliente_id;
	const [userPermissions, setUserPermissions] = useState([])

	useEffect(() => {
		const user_id = JSON.parse(localStorage.getItem("user_id"));
		getUserPermissions(user_id, setUserPermissions)
	}, [])

	const [seccionActiva, setSeccionActiva] = useState(1);
	const buttonSeccion = (num) => {
		if (seccionActiva === num) {
			setSeccionActiva("");
		} else {
			setSeccionActiva(num);
		}
	};

	return (
		<div className="p-3 bg-gray-100 ">
			<h3 className="mb-6 text-3xl text-center">Necesidades del Cliente</h3>

			<div className="flex flex-row justify-around mt-6">
				<Link to={`/client/${cliente_id}`}>
					<button className="px-3 py-1 text-gray-100 bg-gray-700 shadow-lg hover:duration-700 hover:bg-blue-700 rounded-xl text-light">
						Regresar
					</button>
				</Link>
			</div>

			<div className="bg-gray-100">
				<div className="flex flex-col justify-around mt-6 md:flex-row">

					{userPermissions.includes(21) ? (
						<button
							className={
								seccionActiva === 1
									? "px-3 py-1 mb-2 text-gray-100 bg-blue-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-gray-700 rounded-xl text-light"
									: "px-3 py-1 mb-2 text-gray-100 bg-gray-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
							}
							onClick={() => buttonSeccion(1)}
						>
							Datos generales
						</button>
					) : null}

					{userPermissions.includes(22) ? (
						<button
							className={
								seccionActiva === 2
									? "px-3 py-1 mb-2 text-gray-100 bg-blue-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-gray-700 rounded-xl text-light"
									: "px-3 py-1 mb-2 text-gray-100 bg-gray-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
							}
							onClick={() => buttonSeccion(2)}
						>
							Gasto fijo
						</button>
					) : null}

					{userPermissions.includes(23) ? (
						<button
							className={
								seccionActiva === 3
									? "px-3 py-1 mb-2 text-gray-100 bg-blue-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-gray-700 rounded-xl text-light"
									: "px-3 py-1 mb-2 text-gray-100 bg-gray-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
							}
							onClick={() => buttonSeccion(3)}
						>
							Apoyo a padres
						</button>
					) : null}

					{userPermissions.includes(24) ? (
						<button
							className={
								seccionActiva === 4
									? "px-3 py-1 mb-2 text-gray-100 bg-blue-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-gray-700 rounded-xl text-light"
									: "px-3 py-1 mb-2 text-gray-100 bg-gray-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
							}
							onClick={() => buttonSeccion(4)}
						>
							Educación
						</button>
					) : null}

					{userPermissions.includes(25) ? (
						<button
							className={
								seccionActiva === 5
									? "px-3 py-1 mb-2 text-gray-100 bg-blue-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-gray-700 rounded-xl text-light"
									: "px-3 py-1 mb-2 text-gray-100 bg-gray-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
							}
							onClick={() => buttonSeccion(5)}
						>
							Proyectos 10+
						</button>
					) : null}

					{userPermissions.includes(26) ? (
						<button
							className={
								seccionActiva === 6
									? "px-3 py-1 mb-2 text-gray-100 bg-blue-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-gray-700 rounded-xl text-light"
									: "px-3 py-1 mb-2 text-gray-100 bg-gray-700 shadow-lg md:mb-0 hover:duration-700 hover:bg-blue-700 rounded-xl text-light"
							}
							onClick={() => buttonSeccion(6)}
						>
							Retiro
						</button>
					) : null}

				</div>
				<br />
				{seccionActiva === 1 ? (
					<DatosGenerales cliente_id={cliente_id} />
				) : null}
				{seccionActiva === 2 ? <EstiloVida cliente_id={cliente_id} /> : null}
				{seccionActiva === 3 ? <ApoyoPadres cliente_id={cliente_id} /> : null}
				{seccionActiva === 4 ? <Educacion cliente_id={cliente_id} /> : null}
				{seccionActiva === 5 ? (
					<Proyectos10Mas cliente_id={cliente_id} />
				) : null}
				{seccionActiva === 6 ? <Retiro cliente_id={cliente_id} /> : null}
			</div>
		</div>
	);
};

export default InfoFinanciera;
