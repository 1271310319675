import React, { useState, useEffect } from "react";
import _ from "lodash";
import CurrencyInput from "react-currency-input-field";

import { showClient } from "../../functions/ClientsQueries";
import {
	retiroFindOrCreate,
	obtenerRetiro,
	actualizarDatosRetiro,
	encontrarIdPreguntaAnos,
} from "../../functions/NecesidadesQueries";
import {
	calculate_age,
	// obtenerConyuge,
} from "../../functions/DependantsQueries";

const Retiro = ({ cliente_id }) => {
	const [datosRetiro, setDatosRetiro] = useState("");
	const [cliente, setCliente] = useState({});
	const [idPregunta, setIdPregunta] = useState(0);
	// const [conyuge, setConyuge] = useState("");

	let i = 0;

	const actualizarState = (e) => {
		let arr = e.target.name.split(" || ");
		if (arr[2] === "moneda") {
			let val = e.target.value.replace(/\D+/g, "");
			actualizarDatosRetiro(arr[0], arr[1], val);
		} else {
			actualizarDatosRetiro(arr[0], arr[1], e.target.value);
		}
		if (arr[2] === "edad") {
			setTimeout(() => {
				let anos_faltantes =
					e.target.value - calculate_age(cliente.fecha_nacimiento);
				actualizarDatosRetiro(idPregunta, arr[1], anos_faltantes);
				obtenerRetiro(cliente_id, setDatosRetiro);
			}, 500);
			setTimeout(() => {
				obtenerRetiro(cliente_id, setDatosRetiro);
			}, 1000);
		}
	};

	useEffect(() => {
		retiroFindOrCreate(cliente_id, console.log);
		showClient(cliente_id, setCliente);
		// obtenerConyuge(cliente_id, setConyuge);
		setTimeout(() => {
			obtenerRetiro(cliente_id, setDatosRetiro);
			encontrarIdPreguntaAnos(cliente_id, setIdPregunta);
		}, 500);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<div className="flex flex-row justify-around mb-10">
				<div className="space-y-2 sm:space-y-4">
					<div className="text-sm font-bold text-center sm:text-2xl">
						Retiro
					</div>

					<div className="flex flex-col">
						<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
								<div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
									<form>
										<table className="min-w-full divide-y divide-gray-200">
											<thead className="bg-gray-50">
												<tr
													key={i}
													className={i++ % 2 === 0 ? "bg-white" : "bg-gray-100"}
												>
													<th
														scope="col"
														className="px-6 py-3 text-sm font-medium tracking-wider text-center text-gray-700 uppercase"
													>
														Pregunta
													</th>
													<th
														scope="col"
														className="px-6 py-3 text-sm font-medium tracking-wider text-center text-gray-700 uppercase"
													>
														Titular
													</th>
													<th
														scope="col"
														className="px-6 py-3 text-sm font-medium tracking-wider text-center text-gray-700 uppercase"
													>
														Cónyuge
													</th>
												</tr>
											</thead>
											<tbody className="bg-white">
												{typeof datosRetiro === "undefined" ||
												datosRetiro === null ||
												datosRetiro.length === 0
													? null
													: datosRetiro.map((item) => {
															return (
																<tr
																	key={i}
																	className={
																		i++ % 2 === 0 ? "bg-white" : "bg-gray-100"
																	}
																>
																	<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																		{item.pregunta}
																	</td>
																	<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																		{item.opciones === "moneda" ? (
																			<CurrencyInput
																				id="item.subtipo"
																				name={`${item.id} || titular || moneda`}
																				defaultValue={item.titular}
																				className="block w-full mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																				onChange={actualizarState}
																				decimalsLimit={2}
																				required
																			/>
																		) : item.opciones === "edad" ? (
																			<>
																				<select
																					name={`${item.id} || titular || edad`}
																					className="block w-full mx-1 mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																					onChange={actualizarState}
																					required
																				>
																					{_.range(55, 80, 5).map(
																						(arr, index) => {
																							return item.titular * 1 ===
																								arr * 1 ? (
																								<option
																									key={index}
																									value={arr}
																									selected
																								>
																									{arr}
																								</option>
																							) : (
																								<option key={index} value={arr}>
																									{arr}
																								</option>
																							);
																						}
																					)}
																				</select>
																			</>
																		) : item.opciones === "sino" ? (
																			<select
																				name={`${item.id} || titular`}
																				className="block w-full mx-1 mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																				onChange={actualizarState}
																				required
																			>
																				{["Si", "No"].map((arr, index) => {
																					return item.titular === arr ? (
																						<option
																							key={index}
																							value={arr}
																							selected
																						>
																							{arr}
																						</option>
																					) : (
																						<option key={index} value={arr}>
																							{arr}
																						</option>
																					);
																				})}
																			</select>
																		) : item.opciones === "leyimss" ? (
																			<select
																				name={`${item.id} || titular`}
																				className="block w-full mx-1 mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																				onChange={actualizarState}
																				required
																			>
																				{["73", "97", "NA"].map(
																					(arr, index) => {
																						return item.titular === arr ? (
																							<option
																								key={index}
																								value={arr}
																								selected
																							>
																								{arr}
																							</option>
																						) : (
																							<option key={index} value={arr}>
																								{arr}
																							</option>
																						);
																					}
																				)}
																			</select>
																		) : (
																			<>{item.titular}</>
																		)}
																	</td>
																	<td className="px-6 py-2 text-sm font-medium text-center text-gray-900 whitespace-nowrap">
																		{item.opciones === "moneda" ? (
																			<CurrencyInput
																				id="item.subtipo"
																				name={`${item.id} || conyuge || moneda`}
																				defaultValue={item.conyuge}
																				className="block w-full mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																				onChange={actualizarState}
																				decimalsLimit={2}
																				required
																			/>
																		) : item.opciones === "edad" ? (
																			<select
																				name={`${item.id} || conyuge || edad`}
																				className="block w-full mx-1 mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																				onChange={actualizarState}
																				required
																			>
																				{_.range(55, 80, 5).map(
																					(arr, index) => {
																						return item.conyuge * 1 ===
																							arr * 1 ? (
																							<option
																								key={index}
																								value={arr}
																								selected
																							>
																								{arr}
																							</option>
																						) : (
																							<option key={index} value={arr}>
																								{arr}
																							</option>
																						);
																					}
																				)}
																			</select>
																		) : item.opciones === "sino" ? (
																			<select
																				name={`${item.id} || conyuge`}
																				className="block w-full mx-1 mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																				onChange={actualizarState}
																				required
																			>
																				{["Si", "No"].map((arr, index) => {
																					return item.conyuge === arr ? (
																						<option
																							key={index}
																							value={arr}
																							selected
																						>
																							{arr}
																						</option>
																					) : (
																						<option key={index} value={arr}>
																							{arr}
																						</option>
																					);
																				})}
																			</select>
																		) : item.opciones === "leyimss" ? (
																			<select
																				name={`${item.id} || conyuge`}
																				className="block w-full mx-1 mt-1 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
																				onChange={actualizarState}
																				required
																			>
																				{["73", "97", "NA"].map(
																					(arr, index) => {
																						return item.conyuge === arr ? (
																							<option
																								key={index}
																								value={arr}
																								selected
																							>
																								{arr}
																							</option>
																						) : (
																							<option key={index} value={arr}>
																								{arr}
																							</option>
																						);
																					}
																				)}
																			</select>
																		) : (
																			<>{item.conyuge}</>
																		)}
																	</td>
																</tr>
															);
													  })}
											</tbody>
										</table>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<br />
			<br />
			<br />
			<br />
		</div>
	);
};

export default Retiro;
