import { baseurl } from "./constants";

export const reporteRegistros = async (startDate, endDate, entrada, callback, setLoading) => {
	const {
		unidades,
		promotorias,
		ciudades,
		generaciones,
		tiposOrigenes,
	} = entrada;
	console.log('startDate', startDate);
	console.log('endDate', endDate);
	console.log('unidades', unidades);
	console.log('promotorias', promotorias);
	console.log('ciudades', ciudades);
	console.log('generaciones', generaciones);
	console.log('tiposOrigenes', tiposOrigenes);
	let raw = JSON.stringify({
		startDate: `${startDate}`,
		endDate: `${endDate}`,
		unidades: `${unidades}`,
		promotorias: `${promotorias}`,
		ciudades: `${ciudades}`,
		generaciones: `${generaciones}`,
		tiposOrigenes: `${tiposOrigenes}`,
	});
	console.log({ raw })
	let myHeaders = new Headers();
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	let requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/reportes/reporteRegistros`;
	console.log("url", url);
	await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			setLoading(false);
			callback(responseJSON.data);
		})
		.catch((error) => {
			console.error(error);
		});
};


export const reporteDirectivo = async (
	startDate,
	endDate,
	callback,
	setLoading
) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/reportes/reporteDirectivo/${startDate}/${endDate}`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
			setLoading(false);
		})
		.catch((error) => console.error(error));
};

export const obtenerUnidades = async (callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/reportes/obtenerUnidades`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
		})
		.catch((error) => console.error(error));
};

export const obtenerPromotorias = async (callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/reportes/obtenerPromotorias`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
		})
		.catch((error) => console.error(error));
};

export const obtenerCiudades = async (callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/reportes/obtenerCiudades`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
		})
		.catch((error) => console.error(error));
};

export const obtenerGeneraciones = async (callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/reportes/obtenerGeneraciones`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
		})
		.catch((error) => console.error(error));
};

export const obtenerTiposOrigenes = async (callback) => {
	const token = JSON.parse(localStorage.getItem("jwt"));
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "application/json");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Access-Control-Allow-Origin", "*");
	myHeaders.append("Access-Control-Allow-Credentials", "true");
	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		cors: "no-cors",
	};
	const url = `${baseurl}/api/auth/reportes/obtenerTiposOrigenes`;
	return await fetch(url, requestOptions)
		.then((response) => response.json())
		.then((responseJSON) => {
			callback(responseJSON.data);
		})
		.catch((error) => console.error(error));
};

